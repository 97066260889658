
import { Component, OnInit, Injector, HostListener } from '@angular/core';
import { UserService } from '@wtw/platform/services';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-showTranslation',
    template: ''
})

export class ShowTranslationComponent{

    show: boolean;
    _extendedTranslateService: any;
    localisationEnabled = true;
    currentTranslation: any;
    lastUpdated: any;
    _translation: { [key: string]: any; };
    items: string[];
    showDetails1 = false;
    showDetails2 = false;
    keyToBeMoved: { key: any; };
    movedKeys: string[];

    constructor(inj: Injector, private _userService: UserService) {
        setTimeout(() => {
            this._extendedTranslateService = inj.get(TranslateService);
        });
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.canActivate()) {
            // combo: shift+`
            if (event.shiftKey && event.charCode === 126) {
                this.disableEnableLocalisation();
            }
        }
    }


    public get translation() {
        this.setTranslation();
        return this._translation;
    }


    public disableEnableLocalisation() {
        this.localisationEnabled = !this.localisationEnabled;
        this._extendedTranslateService.disableEnableLocalisation(this.localisationEnabled);
        this.currentTranslation = this._extendedTranslateService.getTranslation(this._extendedTranslateService.currentLang);
    }

    public download() {
        const date = new Date();
        const contentType = 'application/octet-stream';
        const blob = new Blob([JSON.stringify(this.translation, null, 3)], { 'type': contentType });
        let title = 'localisation-local-';
        title += date.getFullYear() + '_' + (date.getMonth() + 1) + '_'
            + (date.getDate()) + '_' + date.getHours() + '_' + date.getMinutes();
        title += '.txt';
        const _w = window.navigator as any;
        if (_w && _w.msSaveOrOpenBlob) { // for IE
            _w.msSaveOrOpenBlob(blob, title);
        } else { // for Non-IE (chrome, firefox etc.)
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = title;
            a.click();
            a.remove();
        }
    }

    public languageChanged(data: string[]) {
        this.movedKeys = data;
    }

    public showMissingKeys() {
        this.items = JSON.parse(JSON.stringify(this._extendedTranslateService.missingTranslationKeys));

        this.showDetails2 = false;
        if (this.items.length > 0) {
            this.showDetails1 = !this.showDetails1;
        } else {
            this.showDetails1 = false;
        }
    }

    public showMissingTranslation() {
        this.items = JSON.parse(JSON.stringify(this._extendedTranslateService.missingTranslation));
        this.movedKeys.forEach(c => {
            this.items.splice(this.items.indexOf(c), 1);
        });
        this.showDetails1 = false;
        if (this.items.length > 0) {
            this.showDetails2 = !this.showDetails2;
        } else {
            this.showDetails2 = false;
        }
    }

    public dropDownItemClicked(item) {
        if (this.showDetails1) {
            this.copyMessage(item);
        } else {
            if (this.showDetails2) {
                this.moveKeyToLocalisation(item);
            }
        }
    }

    private moveKeyToLocalisation(item) {
        this.items.splice(this.items.indexOf(item), 1);
        this.keyToBeMoved = { key: item };
    }

    private copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    private setTranslation() {
        if (this._extendedTranslateService && this.lastUpdated !== this._extendedTranslateService.lastUpdated) {
            this._translation = JSON.parse(JSON.stringify(this._extendedTranslateService.translation));
            this.lastUpdated = this._extendedTranslateService.lastUpdated;
        }
    }

    private canActivate() {
        return this._userService.canAccess([]);
    }
}
