import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ShortNumberFormatPipe } from 'app/pipes/short-number-formatter.pipe';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnnualAverageLossSplitLevel } from '../../../../api/dtos';
import { RiskSummaryService } from '../../risk-summary.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dtcor-risk-gross-losses',
  templateUrl: './risk-gross-losses.component.html',
  styleUrls: ['./risk-gross-losses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskGrossLossesComponent implements OnInit {

  translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.GROSS_LOSSES';

  viewModel;

  private _expandedIds = [];
  private isExpanded = new BehaviorSubject<boolean>(false);
  private level1Changed = new BehaviorSubject<AnnualAverageLossSplitLevel>(null);
  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService) {
    // empty block
  }

  ngOnInit(): void {
    this.viewModel = combineLatest([this.riskSummaryService.selectedRisk,
    this.level1Changed,
    this.isExpanded])
      .pipe(map(([selectedRisk, level1, isExpanded]) => {        
        let selectedLevel1 = level1 ? level1 : selectedRisk.grossLoss.level1[0];
        // when level1 is changed for a risk and if we changed the risk then level1 remains same as previous
        // to avoid this we are checking if selectedLevel1 is present in selectedRisk.grossLoss.level1
        // if not then we are taking first element of selectedRisk.grossLoss.level1
        const correctSelectedLevel1 = this.findLevel(selectedRisk.grossLoss.level1, selectedLevel1?.id.toString());
        if (!correctSelectedLevel1) {
          selectedLevel1 = selectedRisk.grossLoss.level1[0];
        }
        const level2 = this.getLevel2(selectedRisk.grossLoss.level2, selectedLevel1?.id);
        const canExpand = this.getCanExpand(level2, selectedRisk.grossLoss.level3);
        const statement = this.translateService.instant(`${this.translationKey}.CONTENT`, {
          N: new ShortNumberFormatPipe().transform(selectedRisk.grossLoss.averageAnnualLoss)
        });
        return {
          selected: selectedRisk,
          level1: selectedRisk.grossLoss.level1,
          level1Selected: selectedLevel1,
          level2,
          level3: selectedRisk.grossLoss.level3,
          isExpanded,
          canExpand,
          statement
        };
      }));
  }

  level1Selected(level1: AnnualAverageLossSplitLevel[], selectedId: string) {
    const selected = this.findLevel(level1, selectedId);
    this.level1Changed.next(selected);
  }

  findLevel(level1: AnnualAverageLossSplitLevel[], selectedId: string) {
    const selected = level1.find(x => x.id.toString() === selectedId);
    return selected;
  }

  toggleDetailView(id: number) {
    const found = this._expandedIds.find(x => x === id);
    if (!found) {
      this._expandedIds.push(id);
    } else {
      this._expandedIds = this._expandedIds.filter(x => x !== id);
    }
    this.isExpanded.next(this._expandedIds.length > 0);
  }

  isExpended(id: number) {
    return this._expandedIds.find(x => x === id);
  }

  getSubLevel(levelId: number, level3: AnnualAverageLossSplitLevel[]): AnnualAverageLossSplitLevel[] {
    return level3.filter(x => x.parentId === levelId);
  }

  suffix(value) {
    return value + 'th';
  }

  private getLevel2(level: AnnualAverageLossSplitLevel[], parentId: number) {
    const level2 = level.filter(x => x.parentId === parentId);
    const total = level2.reduce((pre, curr) => pre + curr.averageAnnualLoss, 0);
    return level2.map(x => ({ ...x, percentage: (x.averageAnnualLoss / total) * 100 }));
  }

  private getCanExpand(level2: AnnualAverageLossSplitLevel[], level3: AnnualAverageLossSplitLevel[]) {
    return level2.map(x => this.getSubLevel(x.id, level3).length > 0).filter(x => x).length > 0;
  }

}
