<ng-container e2eGroup="risk-summary"  *ngIf="viewModel | async as vm">
    <div class="titlebar--wrap">
        <div class="row tilebar--container">
            <div class="col col-sm-12">
                <h3 class="text-left" e2eItem="es-heading">
                    <span>
                        <a href="javascript:void(0);" (click)="onViewExecSummary()">
                           <span [innerHTML]="'CURRENT_MODEL.EXECUTIVE_SUMMARY.HEADING' | translate"></span>
                        </a>
                    </span>
                    <span class="back-slash">/</span>
                </h3>
                <h1 class="display-1 text-left" e2eItem="page-heading">
                    {{ translationKey + ".HEADING" | translate }} - {{ vm.topRiskSelected }}

                    <dtcor-download-report-button [translationKey]="translationKey"></dtcor-download-report-button>
                </h1>
            </div>
        </div>
    </div>
    <ng-container *ngIf="vm.riskSummaryViewModel">
        <div class="rs-content col-sm-12 mt-3">
            <div class="risk-summary-desc" [innerHTML]="vm.riskDescription | translate">
            </div>
            <div class="col-sm-2 mt-4 no-left-padding">
                <select e2eItem="risks-ddl" class="select-wrapper" #riskType (change)="changeRiskType(vm.riskTypeOptions,riskType.value)">
                    <option *ngFor="let risk of vm.riskTypeOptions" [value]="risk.id" [selected]="risk.id === vm.selectedRiskType.id">{{risk.name}}</option>
                </select>
            </div>
        </div>
    </ng-container>

    <!-- tabs -->
    <div class="rs-content mt-4">
        <div class="row">
            <div class="col-md-12">
                <div>
                    <tabset>
                        <tab [active]="true">
                            <ng-template tabHeading>
                                <span e2eItem="tab-historical-losses">
                                    {{ translationKey + '.TABS.HISTORICAL_LOSSES.TITLE' | translate}} 
                                    <img alt="lock" *ngIf="!vm.canAccessHistoricalLosses" class="ml-1" [src]="'/assets/images/lock.svg'" />
                                </span>
                            </ng-template>
                            <dtcor-risk-historical-losses  *ngIf="vm.canAccessHistoricalLosses" ></dtcor-risk-historical-losses>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span e2eItem="tab-gross-losses">
                                    {{ translationKey + '.TABS.GROSS_LOSSES.TITLE' | translate }} 
                                </span>
                            </ng-template>
                            <dtcor-risk-gross-losses></dtcor-risk-gross-losses>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span e2eItem="tab-current-structure">
                                    {{ translationKey + '.TABS.CURRENT_STRUCTURE.TITLE' | translate }} 
                                </span>
                            </ng-template>
                            <dtcor-risk-current-structure></dtcor-risk-current-structure>
                        </tab>
                        <tab>
                            <ng-template tabHeading>
                                <span e2eItem="tab-alt-structure">
                                    {{ translationKey + '.TABS.ALTERNATIVE_STRUCTURE.TITLE' | translate }} 
                                </span>
                            </ng-template>
                            <dtcor-risk-alternative-structure></dtcor-risk-alternative-structure>
                        </tab>
                        <tab (selectTab)="onPricingTabSelect()">
                            <ng-template tabHeading>
                                <span e2eItem="tab-pricing">{{ translationKey + '.TABS.PRICING.TITLE' | translate }}</span>
                                <img alt="lock" class="ml-1" [src]="'/assets/images/lock.svg'" *ngIf="!vm.userHasAccess" />
                            </ng-template>
                            <dtcor-risk-pricing></dtcor-risk-pricing>
                        </tab>
                        <tab (deselect)="onTabAction('ccor', false)">
                            <ng-template tabHeading>
                                <span e2eItem="tab-ccor">
                                    {{ translationKey + '.TABS.CCOR.TITLE' | translate }} 
                                </span>
                            </ng-template>
                            <dtcor-risk-ccor></dtcor-risk-ccor>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
    <!-- /tabs -->
</ng-container>