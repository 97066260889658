import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RiskTypeCCORItem } from 'app/api/dtos';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { RiskSummaryService } from '../../risk-summary.service';
import { TranslateService } from '@ngx-translate/core';
import { maxBy } from 'lodash';
import { CCORLegendItem } from 'app/shared/models/CCORLegendItem';

@Component({
  selector: 'dtcor-risk-ccor',
  templateUrl: './risk-ccor.component.html',
  styleUrls: ['./risk-ccor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskCcorComponent implements OnInit {

  public translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.CCOR';
  viewModel;
  private tabs = {
    chart: 'chart',
    table: 'table'
  };

  private tabChanged = new BehaviorSubject(this.tabs.chart);
  private seriesChanged = new BehaviorSubject<CCORLegendItem>({ name: '', colour: '', enabled: false });
  private colours = {
    retained: '#5C66CC',
    premium: '#B12F9B',
    costOfVolatility: '#F5BA47',
    valueFromInsurance: '#57C08D'
  };
  private legends: CCORLegendItem[] = [
    { name: this.translateService.instant(this.translationKey + '.VALUE_FROM_INSURANCE'), colour: this.colours.valueFromInsurance, enabled: true },
    { name: this.translateService.instant(this.translationKey + '.COST_OF_VOLATILITY'), colour: this.colours.costOfVolatility, enabled: true },
    { name: this.translateService.instant(this.translationKey + '.PREMIUM'), colour: this.colours.premium, enabled: true },
    { name: this.translateService.instant(this.translationKey + '.RETAINED_MEAN'), colour: this.colours.retained, enabled: true }
  ];

  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService) {

  }

  ngOnInit(): void {
    this.viewModel = combineLatest([this.riskSummaryService.selectedRisk,
      this.tabChanged,
      this.seriesChanged,
      this.riskSummaryService.selectedTab?.pipe(tap((tab) => {
        if (!tab.isSelected) {
          this.toggleSeries({ name: '', colour: '', enabled: false });
          this.legends.forEach(legend => legend.enabled = true);
        }        
      }))
    ])
      .pipe(map(([selectedRisk, selectedTab, series]) => {
        
        if (selectedTab === this.tabs.table) {
          this.legends.forEach(legend => legend.enabled = true);
        }

        return {
          selected: selectedRisk,
          isChartSelected: selectedTab === this.tabs.chart,
          colours: this.colours,
          maxCCOR: maxBy(selectedRisk.ccor.table, 'ccor')?.ccor ?? 0,
          toggleSeries:  {...series},
          legends: this.legends
        };
      }));
  }

  selectChartTab() {
    this.tabChanged.next(this.tabs.chart);
  }

  selectTableTab() {
    this.tabChanged.next(this.tabs.table);
  }

  toggleSeries(legend: CCORLegendItem) {
    legend.enabled = !legend.enabled;
    this.seriesChanged.next(legend);
  }

  getName(structure: RiskTypeCCORItem) {
    return structure.isUnhedged ?
      this.translateService.instant(this.translationKey + '.UNHEDGED')
      : structure.isCurrent ? this.translateService.instant(this.translationKey + '.CURRENT_STRUCTURE')
        : structure.name;
  }
}
