import { Component, Inject, Input, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportModalComponent } from 'app/shared/components/report-modal/report-modal.component';
import { ModalService } from '@wtw/platform/services';
import * as moment from 'moment';
import { UrlHelperService } from '@wtw/platform/services/url-helper.service';
import { HttpClient } from '@angular/common/http';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { RunService } from '@wtw/platform/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'dtcor-download-report-button',
    templateUrl: './download-report-button.component.html',
    styleUrls: ['./download-report-button.component.scss'],
})
export class DownloadReportButtonComponent {
    @Input() translationKey: string;

    showSpinner = false;    
    translation: any = {};
    downloadReport$ = new Subject<boolean>();   

    vm = this.downloadReport$
    .pipe(switchMap(_ => this.runService.activeRun))
    .pipe(map(run => {
        this.showSpinner = true;
        const reportType = 'FullAnalysisReport';
        const resource = `powerpoint?runId=${run.runId}&reportType=${reportType}`;
        this._http.get<Blob>(`${UrlHelperService.getPlatformUrl()}/proxy/binary/${resource}`, {
            withCredentials: true,
            responseType: 'blob' as 'json'
        })
        .pipe(first())
        .pipe(finalize(() => {
           this.showSpinner = false;
        }))
        .subscribe({
            next: blob => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `TCOR_FullReport_${run.runId}_${moment(Date.now()).format('YYYY-MM-DD')}.pptx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });

        return run;
    }))
    .pipe(map(_ => ''));
    
    constructor(private _translateService: TranslateService,
        private _modalService: ModalService,
        private _http: HttpClient,
        @Inject(RunService) private runService: RunService) {

    }

    downloadReport() {
        this._modalService.confirmWithComponent(ReportModalComponent, null, {
          class: 'modal-dialog modal-lg'
        }).then((response) => {
            if (response.result) {
                this.downloadReport$.next(true);
            }
        });
    }
    
}