<div e2eGroup="risk-summary-historical-losses" class="row" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4 pb-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate"></div>
                    <ng-container *ngIf="vm.isRiskLossDataPopulated; else dataEmpty">
                        <div class="row mt-4">
                            <div class="col-12 mt-4">
                                <div e2eItem="tab-chart" class="tab" [ngClass]="{'selected': isChartSelected | async}"
                                    (click)="selectChartTab()" [innerHTML]="translationKey + '.TABS.CHART' | translate">
                                </div>
                                <div e2eItem="tab-table" class="tab" [ngClass]="{'selected': (isChartSelected | async) === false}"
                                    (click)="selectTableTab()" [innerHTML]="translationKey + '.TABS.TABLE' | translate">
                                </div>
                            </div>
                            <div e2eItem="chart" class="col-12 mt-2" *ngIf="isChartSelected | async">
                                <dtcor-historical-losses-chart [losses]="vm.losses" [translationKey]="translationKey">
                                </dtcor-historical-losses-chart>
                                <div class="list-wrapper">
                                    <ul class="list">
                                        <li>{{vm.actual}}</li>
                                        <li>{{vm.adjusted}}</li>
                                        <li>{{vm.gross}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div e2eItem="table" class="col-12 mt-4" *ngIf="(isChartSelected | async) === false">
                                <table summary="historical losses table">
                                    <tr>
                                        <th id="header1" [innerHTML]="translationKey + '.TABLE.POLICY_YEAR' | translate"
                                            class="left"></th>
                                        <th id="header2" [innerHTML]="translationKey + '.TABLE.LOSSES' | translate"></th>
                                        <th id="header3" [innerHTML]="translationKey + '.TABLE.ADJUSTED_LOSSES' | translate"></th>
                                        <th scope="col" class="text-center" colspan="2"></th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th scope="col" id="header-retained" [innerHTML]="translationKey + '.TABLE.RETAINED' | translate" ></th>
                                        <th scope="col" id="header-transfered" [innerHTML]="translationKey + '.TABLE.TRANSFERED' | translate" ></th>            
                                    </tr>
                                    <tr *ngFor="let item of vm.losses.table[0].orderedLosses">
                                        <td>{{item.year}}</td>
                                        <td>{{item.incurredTotal | shortNumberFormat}}</td>
                                        <td>{{item.netIncurredTotal |shortNumberFormat}}</td>
                                        <td>{{item.outstandingTotal | shortNumberFormat}}</td>
                                        <td>{{item.paidTotal | shortNumberFormat}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #dataEmpty>
                        <div e2eItem="no-data" class="mt-4">
                            {{ translationKey + ".NO_DATA" | translate }}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="pt-4">
        </div>
    </div>