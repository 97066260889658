import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lossesFilter'
})

@Injectable()
export class LossesFilterPipe implements PipeTransform {
  transform(items: any[], value: any): any[] {
    if (!items) { return []; }
    if (!value) { return items; }
    if (isNaN(value)) { return items; }

    return items.filter(_ => _.year.toString().startsWith(value));
  }
}
