import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';

import { ShortNumberFormatPipe } from '../../../pipes/short-number-formatter.pipe';
import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';
import { PercentageFormatterPipe } from 'app/pipes/percentage.pipe';


@Component({
    selector: 'dtcor-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {

    @Input() table: Table;
    @Input() sortColumnIndex: number;
    @Output() riskSelected = new EventEmitter<string>();

    @Output() sort = new EventEmitter<{ columnIndex: number, direction: number }>();
    align = {
        Left: APP_CONSTANTS.align.LEFT,
        Right: APP_CONSTANTS.align.RIGHT
    };
    private sortDirection = APP_CONSTANTS.sortDirection.DESC;

    constructor(@Inject(LOCALE_ID) private locale: string) {
    }

    isLeftAlign(column: Column) {
        return column.align === this.align.Left;
    }

    format(column: Column) {
        return this.isText(column.value) ? column.value
            : column.isPercentage ? new PercentageFormatterPipe(this.locale).transform(column.value)
                : new ShortNumberFormatPipe().transform(column.value);
    }

    clickAction(header, columnIndex: number) {
        if (header.action) {
            header.action();
        } else {
            this.sortTable(columnIndex);
        }
    }

    sortTable(columnIndex: number) {
        this.sortDirection = this.sortColumnIndex === columnIndex ? this.getSortDirection() : APP_CONSTANTS.sortDirection.ASC;
        this.sort.emit({ columnIndex, direction: this.sortDirection });
    }

    hasValue(value: string) {
        return value !== '' && value;
    }

    isAscending(index: number) {
        return this.sortColumnIndex === index && this.sortDirection === APP_CONSTANTS.sortDirection.ASC;
    }

    isDescending(index: number) {
        return this.sortColumnIndex === index && this.sortDirection === APP_CONSTANTS.sortDirection.DESC;
    }

    hasCustomClass(header) {
        return header.customClass?.length;
    }

    riskClick(risk: string) {
        this.riskSelected.emit(risk);
    }

    private getSortDirection() {
        return this.sortDirection === APP_CONSTANTS.sortDirection.ASC ? APP_CONSTANTS.sortDirection.DESC : APP_CONSTANTS.sortDirection.ASC;
    }

    private isText(value) {
        return typeof value === 'string';
    }

}

export interface Table {
    headers: Array<{ text: string, align: number }>;
    rows: Array<{ isBold: boolean, data: Column[] }>;
}

export interface Column {
    value: number;
    isPercentage: boolean;
    isBold: boolean;
    align: number;
}
