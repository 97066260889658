import { DecimalPipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'percentageFormatter'
})
export class PercentageFormatterPipe implements PipeTransform {
 
    constructor(@Inject(LOCALE_ID) private locale: string) {
    }

    transform(value: any) {
        return new DecimalPipe(this.locale).transform(value, '.1-1') + '%';
    }

}