import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import { last, maxBy } from 'lodash';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { APP_CONSTANTS, parliamentChartColorsLosses } from 'app/shared/helpers/ApplicationConstants';
import { ExecutiveSummaryService } from '../../executive-summary.service';
import { GrossLossByRiskVM, PercentileVM, RetainedRiskDriversVM } from 'app/api/generated/ExecutiveSummaryViewModel';
import { PercentageFormatterPipe } from 'app/pipes/percentage.pipe';

@Component({
    selector: 'dtcor-gross-losses',
    templateUrl: './gross-losses.component.html',
    styleUrls: ['./gross-losses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GrossLossesComponent implements OnInit {

    translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.GROSS_LOSSES';
    Highcharts: typeof Highcharts = Highcharts;
    seeMore: any = {
        isActive: false,
        moreText: '',
        lessText: ''
    };

    viewModel: Observable<any>;

    switch: any = {
        financial: APP_CONSTANTS.displayTypes.financial,
        percentage: APP_CONSTANTS.displayTypes.percentage
    };

    private selectedDisplayType = new BehaviorSubject(APP_CONSTANTS.displayTypes.financial);

    constructor(private translateService: TranslateService,
        private executiveSummaryService: ExecutiveSummaryService,
        @Inject(LOCALE_ID) private locale: string) {
    }

    ngOnInit(): void {
        this.seeMore.moreText = this.translateService.instant(this.translationKey + '.SEE_MORE');
        this.seeMore.lessText = this.translateService.instant(this.translationKey + '.SEE_LESS');
        this.viewModel = this.getViewModel();
    }

    onSwitchChange(value: number) {
        this.selectedDisplayType.next(value);
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    formatPercentages(value: number) {
        return new PercentageFormatterPipe(this.locale).transform(value);
    }

    getSum(row, otherRisks) {
        let value = 0;
        otherRisks.forEach((_) => {
            value += parseFloat(row[_.name].value);
        });
        return this.formatNumber(value);
    }

    getMeanSum(otherRisks) {
        let value = 0;
        otherRisks.forEach((_) => {
            value += parseFloat(_.meanValue);
        });
        return this.formatNumber(value);
    }

    riskClicked(risk: string) {
        this.executiveSummaryService.selectRisk(risk);
    }

    private getViewModel() {
        return combineLatest([this.selectedDisplayType,
        this.executiveSummaryService.isSevereYear,
        this.executiveSummaryService.severePercentileChanged,
        this.executiveSummaryService.typicalPercentileChanged,
        this.executiveSummaryService.executiveSummaryViewModel
        ])
            .pipe(map(([displayType, isSevereYear, severePercentile, typicalPercentile, summaryViewModel]) => {

                const typicalYearTotal = typicalPercentile.grossLosses.totalCost;
                const severeYearTotal = severePercentile.grossLosses.totalCost;
                const typicalYearValues = this.getChartComponentValues(displayType,
                    typicalPercentile.grossLosses.retained,
                    typicalPercentile.grossLosses.transfred);
                const severeYearValues = this.getChartComponentValues(displayType,
                    severePercentile.grossLosses.retained,
                    severePercentile.grossLosses.transfred);
                const right = {
                    color: '#9599e8',
                    name: this.translateService.instant(this.translationKey + '.CHART.TRANSFERED')
                };
                const left = {
                    color: APP_CONSTANTS.colors.totalRetained,
                    name: this.translateService.instant(this.translationKey + '.CHART.RETAINED')
                };

                const severePercentileViewModel = this.getMappedRisks(summaryViewModel.grossLossesByRisk,
                    severePercentile.id);

                const typicalPercentileViewModel = this.getMappedRisks(summaryViewModel.grossLossesByRisk,
                    typicalPercentile.id);

                const risks = this.getMappedRisks(summaryViewModel.grossLossesByRisk,
                    isSevereYear ? severePercentile.id : typicalPercentile.id);

                const widthPercentage = Math.round((typicalYearTotal / severeYearTotal) * 100);

                const typicalSeries = this.getRelativePointsSeries(typicalPercentile);
                const severeSeries = this.getRelativePointsSeries(severePercentile);

                const isPercentage = displayType !== APP_CONSTANTS.displayTypes.financial;

                return {
                    selectedDisplayType: displayType,
                    typicalYear: {
                        right: {
                            ...right,
                            value: isPercentage ? typicalPercentile.transferredPercentage : typicalYearValues.transfered,
                            show: true,
                            chartWidth: this.getWidth(typicalYearValues.transfered, typicalYearValues.retained),
                        },
                        left: {
                            ...left,
                            value: isPercentage ? typicalPercentile.retainedPercentage : typicalYearValues.retained,
                            chartWidth: this.getWidth(typicalYearValues.retained, typicalYearValues.transfered),
                        },
                        total: typicalYearTotal,
                        content: this.translateService.instant(this.translationKey + '.TYPICAL_YEAR.CONTENT', {
                            [`retained`]: `${this.formatPercentages(typicalPercentile.retainedPercentage)}`,
                            [`transfered`]: `${this.formatPercentages(typicalPercentile.transferredPercentage)}`
                        }),
                        widthPercentage: isNaN(widthPercentage) ? 0 : widthPercentage
                    },
                    severeYear: {
                        right: {
                            ...right,
                            value: isPercentage ? severePercentile.transferredPercentage : severeYearValues.transfered,
                            show: true,
                            chartWidth: this.getWidth(severeYearValues.transfered, severeYearValues.retained),
                        },
                        left: {
                            ...left,
                            value: isPercentage ? severePercentile.retainedPercentage : severeYearValues.retained,
                            chartWidth: this.getWidth(severeYearValues.retained, severeYearValues.transfered),
                        },
                        total: severeYearTotal,
                        content: this.translateService.instant(this.translationKey + '.SEVERE_YEAR.CONTENT', {
                            [`retained`]: `${this.formatPercentages(severePercentile.retainedPercentage)}`,
                            [`transfered`]: `${this.formatPercentages(severePercentile.transferredPercentage)}`
                        })
                    },
                    headerContent: this.translateService.instant(this.translationKey + '.CONTENT', {
                        [`typicalPercentile`]: this.executiveSummaryService.getTypicalPercetileDisplayLabel(typicalPercentile.id),
                        [`severePercentile`]: this.executiveSummaryService.getSeverePercetileDisplayLabel(severePercentile.id),
                        [`typicalValue`]: this.formatNumber(typicalPercentile.grossLosses.totalCost),
                        [`severeValue`]: this.formatNumber(severePercentile.grossLosses.totalCost)
                    }),
                    severeYearHeading: this.translateService.instant(this.translationKey + '.SEVERE_YEAR.HEADING',
                        { [`severePercentile`]: this.executiveSummaryService.getSeverePercetileDisplayLabel(severePercentile.id) }),
                    typicalYearHeading: this.translateService.instant(this.translationKey + '.TYPICAL_YEAR.HEADING',
                        { [`typicalPercentile`]: this.executiveSummaryService.getTypicalPercetileDisplayLabel(typicalPercentile.id) }),
                    risks: risks,
                    seeMoreStatement: {
                        p1: this.translateService.instant(this.translationKey + '.GROSS_LOSS_BY_RISK_STATEMENT_P1', {
                            [`typicalPercentile`]: this.formatPercentages(typicalPercentileViewModel.max.value)
                        }),
                        p2: this.translateService.instant(this.translationKey + '.GROSS_LOSS_BY_RISK_STATEMENT_P2', {
                            [`severePercentile`]: this.formatPercentages(severePercentileViewModel.max.value)
                        }),
                        p3: this.translateService.instant(this.translationKey + '.GROSS_LOSS_BY_RISK_STATEMENT_P3'),
                        typicalRisk: typicalPercentileViewModel.max.name,
                        severeRisk: severePercentileViewModel.max.name
                    },
                    typicalYearRiskDrivers: this.getParlimentChart(typicalSeries),
                    severYearRiskDrivers: this.getParlimentChart(severeSeries)
                };
            }));
    }

    private getRelativePointsSeries(percentile: PercentileVM) {
        const total = percentile.grossLosses.riskDrivers.reduce((pre, curr) => pre + curr.retained, 0);
        const percentage = percentile.grossLosses.riskDrivers.map(x => ({ ...x, percentage: Math.round(x.retained / total * 100) }));
        const allocated = percentage.map(x => ({ ...x, points: Math.round(x.percentage * APP_CONSTANTS.parliamentChartMaxPoints / 100) }));
        const series = this.getChartSeries(allocated);
        return series;
    }

    private getWidth(value: number, otherValue: number) {
        const total = value + otherValue;
        const percent = (value / total) * 100;
        return isNaN(percent) ? 0 : percent;
    }

    private getMappedRisks(risks: GrossLossByRiskVM, percentileId: number) {
        let totalValue = 0;
        risks.rows.forEach((row) => {
            totalValue = 0;
            risks.columns.forEach((col) => {
                totalValue += parseFloat(row[col.id].value);
            });
            risks.columns.forEach((col) => {
                row[col.id].percentage = (parseFloat(row[col.id].value) / totalValue) * 100;
                if (isNaN(row[col.id].percentage)) {
                    row[col.id].percentage = 0;
                }
            });
        });

        const rows = [];
        let cols = [];
        let obj = {};

        risks.rows.forEach((row) => {
            obj = {};
            cols = [];
            risks.columns.forEach((col) => {
                if (row[col.id].statisticId === percentileId) {
                    obj[col.id] = row[col.id].percentage;
                    cols.push(obj);
                }
            });
            rows.push(cols);
        });

        let maxItems = [];
        risks.columns.forEach((col) => {
            const max = maxBy(rows.find(_ => _.length > 0), (_) => {
                return _[col.id];
            });
            maxItems.push({
                id: col.id,
                name: col.name,
                value: max[col.id]
            });
        });

        maxItems = maxItems.sort((a, b) => a.value - b.value);

        return {
            data: risks,
            max: last(maxItems)
        };
    }

    private getChartComponentValues(displayType: number, retained: number, transfered: number) {
        const total = retained + transfered;
        return (displayType === APP_CONSTANTS.displayTypes.financial) ? this.getDisplayAmounts(retained, transfered)
            : this.getPercentages(total, retained, transfered);
    }

    private getPercentages(total: number, retained: number, transfered: number) {
        const _retained = (retained / total) * 100;
        const _transfered = (transfered / total) * 100;
        return {
            retained: isNaN(_retained) ? 0 : _retained,
            transfered: isNaN(_transfered) ? 0 : _transfered
        };
    }

    private getDisplayAmounts(retained: number, transfered: number) {
        return {
            retained,
            transfered
        };
    }

    private getDisplayValue(displayType: number, retained: number, transfered: number) {
        const total = retained + transfered;
        const value = (displayType === APP_CONSTANTS.displayTypes.financial) ? this.getPercentages(total, retained, transfered)
            : this.getDisplayAmounts(retained, transfered);
        const suffix = displayType === APP_CONSTANTS.displayTypes.financial ? '%' : '';
        return {
            retained: `${this.formatNumber(isNaN(value.retained) ? 0 : value.retained)}${suffix}`,
            transfered: `${this.formatNumber(isNaN(value.transfered) ? 0 : value.transfered)}${suffix}`
        };
    }

    private getTypicalYearRiskDriversOptions(drivers: RetainedRiskDriversVM[], severCount: number) {
        const series = this.getChartSeries(drivers);
        const total = series.reduce((pre, curr) => pre + curr.retained, 0);
        const sever = severCount - total;

        series.push({ risk: '', retained: sever < 0 ? sever * -1 : sever, premium: 0 });
        return series;
    }

    private getSeverYearRiskDrivers(drivers: RetainedRiskDriversVM[]) {
        const series = this.getChartSeries(drivers);
        return {
            top5: series,
            total: series.reduce((pre, curr) => pre + curr.retained, 0)
        };
    }

    private getChartSeries(risks: RetainedRiskDriversVM[]) {
        const colors = [...parliamentChartColorsLosses, ...Highcharts.getOptions().colors];
        risks = risks.map((item, index) => {
            return {
                ...item,
                retained: parseInt(`${item.retained}`, 0),
                colour: colors[index]
            };
        });
        return risks;
    }

    private getParlimentChart(data: any[]) {
        const series = data.map(item => ([item.risk, parseInt(item.points, 0), item.colour ? item.colour : '#faf7f7']));

        return {
            chart: {
                type: 'item',
                backgroundColor: 'transparent'
            },
            credits: {
                text: ''
            },
            title: {
                text: ''
            },
            tooltip: {
                shared: false,
                followPointer: false,
                hideDelay: 250,
                formatter: function () {
                    if (this.key) {
                        const currentRetainedRisk = data.find(x => x.risk === this.key);
                        return new ShortNumberFormatPipe().transform(currentRetainedRisk.retained);
                    }
                    return false;
                }
            },
            legend: {
                reversed: false,
                enabled: false,
                style: {
                    fontSize: '16px'
                }
            },
            series: [{
                name: 'risk',
                keys: ['name', 'y', 'color'],
                data: [...series],
                dataLabels: {
                    enabled: false
                },
                center: ['50%', '88%'],
                size: '140%',
                startAngle: -100,
                endAngle: 100
            }]
        };
    }

}
