<ng-container e2eGroup="executive-summary-historical-losses">
<div class="row">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="viewModel | async as vm">
    <ng-container *ngIf="vm.isDataPopulated; else dataEmpty">
        <div class="row mt-4">
            <div class="col-12 mt-4">
                <div e2eItem="tab-chart" class="tab" [ngClass]="{'selected': isChartSelected | async}" (click)="selectChartTab()"
                    [innerHTML]="translationKey + '.TABS.CHART' | translate">
                </div>
                <div id="table-{{translationKey + '.TABS.TABLE' | translate}}" e2eItem="tab-table" class="tab" [ngClass]="{'selected': (isChartSelected | async) === false}" (click)="selectTableTab()"
                    [innerHTML]="translationKey + '.TABS.TABLE' | translate"></div>
            </div>
            <div class="col-md-12 mt-4" *ngIf="isChartSelected | async">
                <div e2eItem="chart">
                    <dtcor-historical-losses-chart [losses]="vm.chartData"
                    [translationKey]="translationKey"></dtcor-historical-losses-chart>
                </div>                
                <div e2eItem="risks" class="list-wrapper">
                    <ul class="list">
                        <li>{{vm.grossloss}}</li>
                        <li>{{vm.adjusted}}</li>
                        <li *ngFor="let risk of vm.statements"><a class="text-wtw-primary" (click)="riskClicked(risk.risk)">{{risk.risk}}</a>:
                            {{risk.statement}} </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 mt-4" *ngIf="(isChartSelected | async) === false">
                <div class="row mb-3" id="searchBar">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="inner-addon right-addon">
                                    <input type="text" [(ngModel)]="search" (ngModelChange)="onSearch()" placeholder="{{ translationKey + '.TABLE.SEARCH_PLACEHOLDER' | translate }}" class="form-control" [ngClass]="{'input-valid': search,'input-invalid': !search}" e2eItem="searchField">
                                    <span class="icon-search"></span>
                                </div>
                            </div>
                            <div class="col-md-4 text-left pl-0">
                                <button type="button" (click)="reset()" class="btn btn-primary" e2eItem="resetBtn">{{ translationKey + '.TABLE.BTN_RESET' | translate }}</button>
                            </div>
                        </div>
                    </div>                    
                </div>
                <table e2eItem="table" summary="structure">
                    <tr>
                        <th scope="col" id="header-risk" [innerHTML]="translationKey + '.TABLE.RISK' | translate" class="left"></th>
                        <th scope="col" id="header-policy-year" [innerHTML]="translationKey + '.TABLE.POLICY_YEAR' | translate" class="left"></th>
                        <th scope="col" id="header-losses1" [innerHTML]="translationKey + '.TABLE.LOSSES' | translate"></th>
                        <th scope="col" id="header-losses2" [innerHTML]="translationKey + '.TABLE.ADJUSTED_LOSSES' | translate"></th>
                        <th scope="col" class="text-center" colspan="2"></th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th scope="col" id="header-retained" [innerHTML]="translationKey + '.TABLE.RETAINED' | translate" ></th>
                        <th scope="col" id="header-transfered" [innerHTML]="translationKey + '.TABLE.TRANSFERED' | translate" ></th>            
                    </tr>
                    <ng-container *ngFor="let item of vm.tableData.table">
                        <tr *ngFor="let loss of item.orderedLosses | lossesFilter:search">
                            <td class="left">{{item.riskType}}</td>
                            <td>{{loss.year}}</td>
                            <td>{{loss.incurredTotal | shortNumberFormat}}</td>
                            <td>{{loss.netIncurredTotal |shortNumberFormat}}</td>
                            <td>{{loss.outstandingTotal | shortNumberFormat}}</td>
                            <td>{{loss.paidTotal | shortNumberFormat}}</td>
                        </tr>
                    </ng-container>                   
                </table>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #dataEmpty>
    <div class="mt-4" [innerHTML]="(translationKey + '.NO_DATA' | translate)"></div>
</ng-template>
</ng-container>