import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { RiskSummaryService } from '../../risk-summary.service';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';

@Component({
  selector: 'dtcor-risk-current-structure',
  templateUrl: './risk-current-structure.component.html',
  styleUrls: ['./risk-current-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskCurrentStructureComponent implements OnInit {

  public translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.CURRENT_STRUCTURE';

  viewModel;
  expandRetained: BehaviorSubject<boolean>;
  expandTransferred: BehaviorSubject<boolean>;
  @ViewChild('retained') retaindedElement;
  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService,
    private el: ElementRef) {
    this.expandRetained = new BehaviorSubject(false);
    this.expandTransferred = new BehaviorSubject(false);
  }

  ngOnInit(): void {
    this.viewModel = combineLatest([this.riskSummaryService.selectedRisk,
    this.expandRetained,
    this.expandTransferred])
      .pipe(map(([selected, expandRetained, expandTransferred]) => {
        expandRetained = selected.currentStructure.table.length > 0
          && selected.currentStructure.table[0].retainedBreakDown.length > 0
          && expandRetained;
        expandTransferred = selected.currentStructure.table.length > 0
          && selected.currentStructure.table[0].transferredBreakDown.length > 0
          && expandTransferred;
        return {
          currentStructure: selected.currentStructure,
          expandRetained,
          expandTransferred,
          descriptionText: this.translateService.instant(this.translationKey + '.CONTENT2',
            {
              ['retained']: this.formatNumber(selected.currentStructure.retained),
              ['transferred']: this.formatNumber(selected.currentStructure.cededToInsures)
            })
        };
      }));
  }

  formatNumber(value: number) {
    return new ShortNumberFormatPipe().transform(value);
  }

  toggleRetained(isExpanded: boolean) {
    this.expandRetained.next(!isExpanded);
  }

  toggleTransferred(isExpanded: boolean) {
    this.expandTransferred.next(!isExpanded);
  }

  getWidth(index: number, id: string) {
    return this.el.nativeElement.querySelector(`#${id}`).children[index]
      && this.el.nativeElement.querySelector(`#${id}`).children[index].offsetWidth + 'px';
  }

  suffix(value) {
    return value + 'th';
  }

}
