import { Inject, Injectable } from '@angular/core';
import { ApplicationInsightsService, NavigationService } from '@wtw/platform/services';
import { ApplicationConstants } from 'app/shared/helpers/ApplicationConstants';
import { APP_CONSTANTS_INJECTION_TOKEN } from 'app/shared/helpers/Injection.token';

@Injectable()
export class DtcorAnalyticsService {

  constructor(
    @Inject(ApplicationInsightsService) private _analyticsService: ApplicationInsightsService,
    @Inject(NavigationService) private _navigationService: NavigationService,
    @Inject(APP_CONSTANTS_INJECTION_TOKEN) private appConstant: ApplicationConstants) {
   }

  public sendRunCompleted(): void {
    this.sendRunTrigger(this.appConstant.analyticEvent.runTrigger.event.runCompleted);
  }

  private sendRunTrigger(triggerName: string): void {
    const model = this._navigationService.getCurrentRun;
    this._analyticsService.send({
      eventCategory: this.appConstant.analyticEvent.runTrigger.category,
      eventAction: triggerName,
      runId: model.runId
    });
  }
}
