import { RemoveSpacesPipe } from './pipes/removeSpacesPipe';
import { NpnSliderModule } from 'npn-slider';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PlatformRootModule } from '@wtw/platform';
import { PLATFORM_URL, RUN_LIST_NAVIGATION, RUN_PAGES } from '@wtw/platform/bootstrap/extension-points';
import { HighchartsChartModule } from 'highcharts-angular';
import { ClickOutsideModule } from 'ng-click-outside';
import { FileUploadModule } from 'ng2-file-upload';
import { storeFreeze } from 'ngrx-store-freeze';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { runviewmodelReducer } from './store/runviewmodel';
import * as Config from './config';
import { MainComponents } from './pages';
import * as Routing from './routes';
import { SharedComponents } from './shared';
import { environment } from './../environments/environment';
import { UrlHelperService } from './shared/helpers/url-helper.service';
import { MapService } from './services/index';
import { SearchFilterPipe } from './pipes/search-filter';
import { NumberFormatterPipe } from './pipes/number-formatter.pipe';
import { SharedPipes } from './pipes';
import { APP_CONSTANTS_INJECTION_TOKEN } from './shared/helpers/Injection.token';
import { APP_CONSTANTS } from './shared/helpers/ApplicationConstants';
import { SIGNAL_BLOCK_PROVIDER } from '@wtw/toolkit/src/directives/loading-indicator.component';
import { DtcorAnalyticsService } from './services/dtcor-analytics.service';
import { PermissionService } from './services/permissions.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SharedDirectives } from './directives';
import { DtcorDataService } from './services/dtcor-data.service';
import { ReferenceDataService } from './services/reference-data.service';
import { RunsProxy } from './api/generated/RunsController';
import { TranslateService } from '@ngx-translate/core';
import { ExtendedTranslateService } from './services/extended-translate.service';
import { SubRoleGuard } from './services/guards/sub-role.guard';
import { UrlRedirectGuard } from './services/guards/url-redirect.guard';
import { ShortNumberFormatPipe } from './pipes/short-number-formatter.pipe';
import { ReportsProxy } from './api/generated/ReportsController';
import { ModalWrapperService } from './services/modal-wrapper.service';


// eslint-disable-next-line max-len
export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

@NgModule({
  declarations: [
    ...MainComponents,
    ...SharedComponents,
    ...SharedPipes,
    ...SharedDirectives
  ],
  imports: [
    PlatformRootModule.forRoot([
      { provide: RUN_PAGES, useValue: { routes: Routing.RUN_PAGES } },
      { provide: RUN_LIST_NAVIGATION, useValue: { runLinks: Config.RUN_LIST_NAVIGATION } },
      { provide: PLATFORM_URL, useValue: { platformConfig: { platformUrl: UrlHelperService.getPlatformUrl() } } }
    ]),
    ReactiveFormsModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    StoreModule.forRoot({ runviewmodel: runviewmodelReducer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    HighchartsChartModule,
    ClickOutsideModule,
    NpnSliderModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot()
  ],
  providers: [
    MapService,
    SearchFilterPipe,
    RemoveSpacesPipe,
    NumberFormatterPipe,
    ShortNumberFormatPipe,
    DtcorAnalyticsService,
    PermissionService,
    DtcorDataService,
    ReferenceDataService,
    RunsProxy,
    SubRoleGuard,
    UrlRedirectGuard,
    ExtendedTranslateService,
    ModalWrapperService,
    ReportsProxy,
    {
      provide: TranslateService,
      useExisting: ExtendedTranslateService
    },
    { provide: APP_CONSTANTS_INJECTION_TOKEN, useValue: APP_CONSTANTS },
    { provide: SIGNAL_BLOCK_PROVIDER, useValue: { isBlocking: true } }
  ],
})
export class CommonModule { }
