import { Injectable } from "@angular/core";
import { IButtonConfig, IModalConfirmResult, IStandardModalConfig } from "@wtw/platform/interfaces";
import { ModalService } from "@wtw/platform/services";

@Injectable()
export class ModalWrapperService {

    constructor(private modalService: ModalService) {

    }

    openWithComponent(component: any, config?: any): Promise<any> {
        return this.modalService.openWithComponent(component, config);
    }

    confirmWithComponent(component: any, config?: any): Promise<IModalConfirmResult> {
        return this.modalService.confirmWithComponent(component, config);
    }

    confirm(confirmConfig: IStandardModalConfig): Promise<boolean> {
        return this.modalService.confirm(confirmConfig);
    }

    alert(title: string, withHeader: boolean = false): Promise<void> {

        return this.modalService.alert(title, withHeader);
    }

    buildConfig(title: string,
        message: string,
        yesButton?: IButtonConfig,
        noButton?: IButtonConfig,
        cancelButton?: IButtonConfig): IStandardModalConfig {

        return this.modalService.buildConfig(title, message, yesButton, noButton, cancelButton);
    }
}