import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'dtcor-portfolio-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioContextMenuComponent {
    @Input() isSelectVisible: boolean;
    @Input() isEditVisible: boolean;
    @Input() isRemoveVisible: boolean;
    @Input() isViewVisible: boolean;
    @Input() name: string;
    @Input() translationKey: string;
    @Output() eventSelect = new EventEmitter<string>();
    @Output() eventEdit = new EventEmitter<string>();
    @Output() eventDelete = new EventEmitter<string>();

    select() {
        this.eventSelect.emit(this.name);
    }

    edit() {
        this.eventEdit.emit(this.name);
    }

    delete() {
        this.eventDelete.emit(this.name);
    }

}