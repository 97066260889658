import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[digitsOnly]',
})
export class DigitsOnlyDirective {
  @Input()
  maxLength!: number;

  @Input()
  language!: string;

  @Input() isAllowFirstZero = 'false';

  @Input() isDirectiveDisabled = false;

  constructor(private element: ElementRef, public model: NgControl) {}

  @HostListener('input', ['$event'])
  onEvent() {
    if (this.isDirectiveDisabled) {
      return;
    }
    let value: string = this.element.nativeElement.value.replace(/[^0-9]/g, '');
    let newVal: any;

    if (!value) {
      newVal = undefined;
    } else if (value.length > this.maxLength) {
      value = value.slice(0, this.maxLength - value.length);
    }

    newVal = value;

    if (newVal && newVal[0] === '0' && this.isAllowFirstZero === 'false') {
      this.model?.control?.setValue('');
    } else {
      this.model?.control?.setValue(newVal);
    }

    if (
      this.isAllowFirstZero === 'true' &&
      value.length >= this.maxLength &&
      /^0*$/.test(newVal.toString())
    ) {
      this.model?.control?.setErrors({ invalid: true });
    }
  }
}
