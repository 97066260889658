<div class="modal-content" *ngIf="active">
    <div class="modal-header">
        <h4 class="modal-title">{{isClientRole? (translation?.CLIENT_TITLE || (translationKey+'.CLIENT_TITLE' | translate)): (translation?.WTW_TITLE || (translationKey+'.WTW_TITLE' | translate))}}</h4>
        <button type="button" class="close"> <span (click)="close()">&times;</span> </button>
    </div>
    <div>
        <div class="modal-body">
            <div class="header-wrapper">
                <div class="header-text" *ngFor="let item of headerText">{{item}}</div>
            </div>
            <div class="body-text" *ngFor="let item of bodyText">{{item}}</div>
            <div class="button-wrapper">
                <button type="button" class="btn btn-secondary mr-3" (click)="cancel()">{{'GLOBAL.BTN.CANCEL'|translate}}</button>
                <button class="btn btn-primary mr-3" (click)="navigateTo(navigateUrls.contact)">{{translation?.CONTACT || (translationKey+'.CONTACT' | translate)}}</button>
                <button class="btn btn-primary" [disabled]="true" (click)="navigateTo(navigateUrls.moreInfo)">{{translation?.MOREINFO || (translationKey+'.MOREINFO' | translate)}}</button>
            </div>
            <div class="readonly">
                <span class="icon icon-lock"></span>
            </div>
        </div>
    </div>
  </div>
  