<ng-container e2eGroup="executive-summary-top-drivers" *ngIf="selectedTab | async as selected">
    <div class="top-drivers-wraper">
        <div class="font-weight-bold mb-2 top-drivers-heading">{{header}}</div>
        <div class="mb-2">
            <span class="sub-header">{{subHeader1}}</span>
            <span>&nbsp;</span>
            <a e2eItem="tcor-risk" (click)="selectRisk.emit(topRisk)" class="top-risk">{{topRisk}}</a>
            <span>&nbsp;</span>
            <span [innerHTML]="subHeader2" class="sub-header"></span>
        </div>
        <div class="radio-container">
            <div class="radio-title">{{ translationKey + '.OPTIONS_TITLE' | translate }}</div>
            <div>
                <input type="radio" id="tcor" class="radio" value="{{topDriversSwitch.tcor}}"
                    [checked]="selected === topDriversSwitch.tcor" (click)="selectedTab.next(topDriversSwitch.tcor)"
                    e2eItem="premium-expenses" />
                <label for="tcor" [ngClass]="{'bold':selected === topDriversSwitch.tcor}">
                    {{ translationKey +'.TCOR' | translate }}</label>
            </div>
            <div>
                <input type="radio" id="expenses" class="radio" value="{{topDriversSwitch.premiumExpense}}"
                    [checked]="selected === topDriversSwitch.premiumExpense"
                    (click)="selectedTab.next(topDriversSwitch.premiumExpense)" e2eItem="total-retained" />
                <label for="expenses" [ngClass]="{'bold':selected === topDriversSwitch.premiumExpense}">
                    {{ translationKey +(hasExpenseKPI ? '.PREMIUM_EXPENSE' : '.PREMIUM') |
                    translate }}</label>
            </div>
            <div>
                <input type="radio" id="retained" class="radio" value="{{topDriversSwitch.totalRetained}}"
                    [checked]="selected === topDriversSwitch.totalRetained"
                    (click)="selectedTab.next(topDriversSwitch.totalRetained)" e2eItem="tcor" />
                <label for="retained" [ngClass]="{'bold':selected === topDriversSwitch.totalRetained}">
                    {{ translationKey +'.TOTAL_RETAINED' | translate }}</label>
            </div>
        </div>
    </div>
    <highcharts-chart (chartInstance)="topDriversChartInstance($event)" [Highcharts]="Highcharts"
        [options]="options | async" style="width: 80%; height: 220px; display: block;padding-right: 25px; margin-top: 0px;"></highcharts-chart>
</ng-container>