import { Component } from '@angular/core';

@Component({
    selector: 'dtcor-risk-tolerence',
    templateUrl: './risk-tolerence.component.html'
})
export class RiskTolerenceComponent {

    public translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.RISK_TOLERANCE';
}
