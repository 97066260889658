import { Routes } from '@angular/router';
import { NavigationAction, NavigationType } from '@wtw/platform/interfaces';
import { ExecutiveSummaryComponent } from './pages/executive-summary/executive-summary.component';
import { RiskSummaryComponent } from './pages/risk-summary/risk-summary.component';
import { SubRoleGuard } from './services/guards/sub-role.guard';
import { UrlRedirectGuard } from './services/guards/url-redirect.guard';
import { APP_CONSTANTS } from './shared/helpers/ApplicationConstants';


export const RUN_PAGES: Routes = [
    {
        path: 'models',
        component: UrlRedirectGuard,
        canActivate: [UrlRedirectGuard],
        data: {
            showRunNavigation: false,
            menuIconClass: 'icon-test-tube',
            menuText: 'Models',
            customTitle: true,
            customNavigation: true,
            canActivate: (stage: string, activePages: { [key: string]: any }) => {
                return true;
            },
            isVisible: () => {
                return true;
            }
        }
    },
    {
        path: 'executivesummary',
        component: ExecutiveSummaryComponent,
        canActivate: [SubRoleGuard],
        data: {
            onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
            menuIconImg: 'icons8-brief.svg',
            runProgress: false,
            displayTitle: 'Results',
            useAsLatestPage: true,
            canActivate: (stage: string, activePages: { [key: string]: any }) => {
                return true;
            },
            isVisible: () => {
                return true;
            },
            featureGuid: APP_CONSTANTS.subRolePermissions.tcorSummarySection.featureGuid
        }
    },
    {
        path: 'risksummary',
        component: RiskSummaryComponent,
        data: {
            onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
            menuIconImg: 'icons8-high-risk.svg',
            runProgress: false,
            displayTitle: 'RiskSummary',
            canActivate: (stage: string, activePages: { [key: string]: any }) => {
                return true;
            },
            isVisible: () => {
                return true;
            }
        }
    }
];
