import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ConfigService, UserService } from '@wtw/platform/services';

@Component({
    selector: 'app-read-only-access-modal',
    templateUrl: './read-only-access-modal.component.html',
    styleUrls: ['./read-only-access-modal.component.scss']
})
export class ReadOnlyAccessModalComponent implements OnInit, OnDestroy {
    public onClose: Subject<boolean>;
    public isClientRole = false;

    @Input()
    public translation = {};

    @Input()
    public translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.RESTRICTION_DIALOG';

    public navigateUrls = {
        contact: '',
        moreInfo: ''
    };

    data: any;

    model: any;
    active: boolean;
    headerText: string[];
    bodyText: string[];

    private _subscriptions: Subscription[] = [];

    constructor(private _userService: UserService,
        private _configService: ConfigService,
        private _translateService: TranslateService,
        private _bsModalRef: BsModalRef) {
    }

    ngOnInit() {
        this.onClose = new Subject();
        this._subscriptions.push(this._userService.userInfo.subscribe(x => {
            this.isClientRole = x.details.isClient;
        }));
        this._subscriptions.push(this._configService.settings.subscribe(set => {
            this.navigateUrls.contact = `mailto:${set.contactEmail}`;
        }));
    }

    ngOnDestroy() {
        this._subscriptions.forEach(c => c.unsubscribe());
    }

    public ok() {
        // ok
    }

    public cancel() {
        this.hide(false);
    }

    public close() {
        this.hide(null);
    }

    public show() {
        this.headerText = this._translateService.instant(this.translationKey + '.HEADER_TEXT_LIST');
        this.bodyText = this._translateService.instant(this.translationKey + '.BODY_TEXT_LIST');
        this.active = true;
    }

    public navigateTo(url) {
        window.location.href = url;
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
