import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { ShortNumberFormatPipe } from 'app/pipes/short-number-formatter.pipe';
import { PortfolioComparisonItem } from 'app/api/dtos';
import { CCORLegendItem } from 'app/shared/models/CCORLegendItem';

@Component({
    selector: 'dtcor-portfolio-chart',
    templateUrl: './portfolio-chart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioChartComponent implements OnChanges {
    @Input() item;
    @Input() translationKey: string;
    @Input() colours: { valueFromInsurance, premium, costOfVolatility, retained };
    @Input() maxCCOR: number;

    options;
    Highcharts: typeof Highcharts = Highcharts;
    chart: Highcharts.Chart;

    constructor(private translateService: TranslateService) {
    }

    chartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    @Input()
    set toggleSeries(legendItem: CCORLegendItem) {
        if (this.chart?.series) {
            this.chart.series.forEach((series, index) => {
                if(legendItem.name === '') {
                    series.setVisible(true);
                }
                else if (series.name === legendItem.name) {
                    series.setVisible(!series.visible);
                }
            });
            setTimeout(() => this.chart.redraw());
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const itemChanges = changes['item'];
        if (itemChanges && itemChanges.previousValue !== itemChanges.currentValue) {
            this.options = this.getChartOptions(itemChanges.currentValue);
        }
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    private getChartOptions(item: PortfolioComparisonItem): any {
        const numberFormatter = (value) => this.formatNumber(value);
        const data = this.getSeries(item);
        const translate = (value) => this.translateService.instant(this.translationKey + value);
        const categories = [];
        return {
            chart: {
                type: 'column',
                backgroundColor: 'transparent'
            },
            credits: {
                text: ''
            },
            title: {
                style: {
                    display: 'none'
                }
            },
            xAxis: {
                categories,
                lineWidth: 0,
                gridLineWidth: 0,
                min: 0,
                max: 1,
                labels: {
                    style: {
                        display: 'none'
                    }
                }
            },
            yAxis: {
                min: 0,
                max: (this.maxCCOR + 100), // add 100 to make sure the chart is not too small
                lineWidth: 0,
                gridLineWidth: 0,
                title: {
                    text: 'Values',
                    style: {
                        display: 'none'
                    }
                },
                labels: {
                    enabled: false
                }
            },
            legend: {
                reversed: false,
                enabled: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    pointWidth: 200
                },
                column: {
                    states: {
                        inactive: {
                            enabled: false
                        },
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            tooltip: {
                useHTML: true,
                backgroundColor: '#ECEDEE',
                borderWidth: 0,
                borderRadius: 0,
                className: 'tooltip-wrapper',
                followPointer: false,
                hideDelay: 0,
                formatter: function () {
                    return `<div style="background-color:#ECEDEE;border:none">
                    <table style="background-color:#ECEDEE">
                        <tr>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${translate('.RETAINED_MEAN')}</td>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${numberFormatter(item.retained)}</td>
                        </tr>
                        <tr>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${translate('.PREMIUM')}</td>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${numberFormatter(item.premium)}</td>
                        </tr>
                        <tr>
                            <td style="background-color:#ECEDEE;font-size:12px;border-bottom:1px solid #707070;color:#444444">${translate('.COST_OF_VOLATILITY')}</td>
                            <td style="background-color:#ECEDEE;font-size:12px;border-bottom:1px solid #707070;color:#444444">${numberFormatter(item.costOfVolatility)}</td>
                        </tr>
                        <tr>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${translate('.CCOR')}</td>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${numberFormatter(item.ccor)}</td>
                        </tr>
                        <tr>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${translate('.VALUE_FROM_INSURANCE')}</td>
                            <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${numberFormatter(item.valueFromInsurance)}</td>
                        </tr>
                    </table>
                </div>`;
                }
            },
            series: data
        };
    }

    private getSeries(item: PortfolioComparisonItem) {
        return [
            {
                name: this.translateService.instant(this.translationKey + '.VALUE_FROM_INSURANCE'),
                type: 'column',
                color: this.colours.valueFromInsurance,
                data: [item.valueFromInsurance > 0 ? item.valueFromInsurance : 0]
            },
            {
                name: this.translateService.instant(this.translationKey + '.COST_OF_VOLATILITY'),
                type: 'column',
                color: this.colours.costOfVolatility,
                data: [item.costOfVolatility]
            },
            {
                name: this.translateService.instant(this.translationKey + '.PREMIUM'),
                type: 'column',
                color: this.colours.premium,
                data: [item.premium]
            },
            {
                name: this.translateService.instant(this.translationKey + '.RETAINED_MEAN'),
                type: 'column',
                color: this.colours.retained,
                data: [item.retained]
            }
        ];
    }
}
