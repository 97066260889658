<div class="row">
    <div class="col-md-12">
        <div class="title" e2eItem="portfolio-add-edit-title">
            <span>{{ translationKey + '.TITLE' | translate }} </span>
        </div>
    </div>
    <div class="col-md-12">
        <div class="sub-title" e2eItem="portfolio-add-edit-sub-title">
            <span>{{ translationKey + '.DESCRIPTION' | translate }} </span>
        </div>
    </div>
</div>
<div class="pr-3 pl-3 pt-3 pb-3">
    <div class="row">
        <div class="col-md-3 text-right">
            <span>{{translationKey+'.PORTFOLIO_NAME' | translate}}</span>
        </div>
        <div class="col-md-5">
            <input type="text" class="form-control" #name="ngModel" required [(ngModel)]="portfolioName" [disabled]="portfolio.isCurrent"/>
            <span *ngIf="!isUnique()" class="error">
                {{translationKey+'.ERRORS.PORTFOLIO_NAME_UNIQUE' | translate}}
            </span>
            <ng-container *ngIf="name.invalid && (name.touched || name.dirty)">
                <span *ngIf="name.errors?.['required']" class="error">
                    {{translationKey+'.ERRORS.PORTFOLIO_NAME_REQUIRED' | translate}}
                </span>
            </ng-container>
            </div>
        </div>
        <ng-container *ngFor="let risk of riskList; index as i">
            <div class="row mt-2">
                <div class="col-md-3 text-right">
                    <span>{{risk.name}}</span>
                </div>
                <div class="col-md-5">
                    <select e2eItem="risk-{{risk.name}}-strategy-dropdown" class="select-wrapper" [ngClass]="{'select-error':risk.isError}" 
                    (change)="selected($event.target.value, risk.id)"
                    [disabled]="portfolio.isCurrent" (blur)="blur($event.target.value, risk.id)">
                        <option [selected] value="-1">Please select an option</option>
                        <option *ngFor="let group of risk.strategyGroups" value="{{group.hash}}"
                            [selected]="group.hash === risk.selected">{{group.name}}
                        </option>
                    </select>
                    <span *ngIf="risk.isError" class="error">
                        {{translationKey+'.ERRORS.STRATEGY_REQUIRED' | translate}}
                    </span>
                </div>
            </div>
        </ng-container>
        <div class="row">
            <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-seconday mr-3" (click)="cancel()"
                    e2eItem="cancel-portfolio-button">
                    <div class="button-text">{{translationKey+'.CANCEL' | translate}}</div>
                </button>
                <button type="button" class="btn btn-primary" (click)="save()" e2eItem="save-portfolio-button"
                    [disabled]="isSaveEnabled() === false">
                    <div class="button-text">{{translationKey+'.SAVE' | translate}}</div>
                </button>
            </div>
        </div>
    </div>