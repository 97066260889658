import { Injectable } from '@angular/core';
import { RiskType } from 'app/api/dtos';
import { DtcorDataService } from 'app/services/dtcor-data.service';
import { ReferenceDataService } from 'app/services/reference-data.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RiskSummaryService {

    selectedRisk: Observable<RiskType>;
    risks: Observable<RiskType[]>;
    selectedTab: BehaviorSubject<{ tab: string, isSelected: boolean }>;
    private selectedRiskTypeChanged: BehaviorSubject<RiskType>;
    private selectedRiskNameChanged: BehaviorSubject<string>;

    constructor(private dataService: DtcorDataService,
        referenceDataService: ReferenceDataService) {
        referenceDataService.startLoadingScreen('');
        this.selectedRiskTypeChanged = new BehaviorSubject<RiskType>(null);
        this.selectedRiskNameChanged = new BehaviorSubject<string>(null);
        this.selectedTab = new BehaviorSubject<{ tab: string, isSelected: boolean }>({ tab: '', isSelected: false });

        this.selectedRisk = combineLatest([
            dataService.runViewModel,
            this.selectedRiskTypeChanged,
            this.selectedRiskNameChanged
        ])
            .pipe(map(([runviewModel, selected, selectedName]) => {
                const riskViewModel = runviewModel.riskSummary;
                referenceDataService.stopLoadingMessage();
                return selectedName ? riskViewModel.risks.find(x => x.name === selectedName) :
                    selected ? selected : riskViewModel.risks.find(x => x.id === riskViewModel.selectedRiskId);
            }));
        this.risks = dataService.runViewModel.pipe(map(value => value.riskSummary.risks));
    }

    selectedRiskChanged(riskType: RiskType, name: string = null) {
        this.selectedRiskNameChanged.next(name);
        this.selectedRiskTypeChanged.next(riskType);
    }

    selectedTabChanged(tab: string, isSelected: boolean) {
        this.selectedTab.next({ tab, isSelected });
    }

    gotoSummarySection() {
        this.dataService.gotoSummarySection();
    }

}
