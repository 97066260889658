<div class="row" e2eGroup="risk-summary-alt-structure" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4">
            <div [ngStyle]="{ width: 'calc(100vw - 145px)' }">
                <div class="row">
                    <div class="col-md-12">
                        <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                        <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate">
                        </div>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col-md-12 scrollx" [style.width]="vm.wrapperWidth">
                        <div class="wrapper" [style.width]="vm.wrapperWidth">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="item first-item non-graph">
                                        <div class="pie-chart">
                                            <div class="legend-wrapper">
                                                <div class="mb-2 legend-item">
                                                    <div class="legend-st" [style.background]="colors[0]"></div>
                                                    <div class="fs-14"
                                                        [innerHTML]="translationKey + '.CHART_LEGEND.RETAINED_BU' | translate">
                                                    </div>
                                                </div>
                                                <div class="mb-2 legend-item">
                                                    <div class="legend-st" [style.background]="colors[1]"></div>
                                                    <div class="fs-14"
                                                        [innerHTML]="translationKey + '.CHART_LEGEND.RETAINED_CAPTIVE' | translate">
                                                    </div>
                                                </div>
                                                <div class="mb-2 legend-item">
                                                    <div class="legend-st" [style.background]="colors[2]"></div>
                                                    <div class="fs-14"
                                                        [innerHTML]="translationKey + '.CHART_LEGEND.TRANSFERRED' | translate">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item non-graph non-graph-loss"  style="border-left: none;">
                                        <div class="pie-chart">
                                            <div class="text-center font-weight-bold"></div>
                                            <div>
                                                <div style="width: 200px; height: 253px; margin: auto; display: block;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item"
                                        [style.width]="getWidth(vm.risk.alertnativeStructure.currentStructure)">
                                        <div class="pie-chart">
                                            <div e2eItem="current-structure-name" class="text-center font-weight-bold"
                                                [innerHTML]="translationKey + '.CURRENT_STRUCTURE' | translate"></div>
                                            <div e2eItem="current-structure-label" class="text-center fs-14 mt-2 break-word" [tooltip]="vm.risk.alertnativeStructure.currentStructure.label">
                                                   {{vm.risk.alertnativeStructure.currentStructure.label}}
                                            </div>
                                            <div e2eItem="current-structure-chart">
                                                <highcharts-chart id="chart_current" [Highcharts]="Highcharts"
                                                    [options]="vm.risk.alertnativeStructure.currentStructure.chart.options"
                                                    [oneToOne]="false"
                                                    style="width: 200px; height: 200px; margin: auto; display: block;">
                                                </highcharts-chart>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item" *ngFor="let structure of vm.risk.alertnativeStructure.structures"
                                        [style.width]="getWidth(structure)">
                                        <div class="pie-chart">
                                            <div e2eItem="structure-name" class="text-center font-weight-bold break-word" [tooltip]="structure.name">
                                                {{structure.name}}
                                            </div>
                                            <div e2eItem="structure-label" class="text-center fs-14 mt-2 break-word" [tooltip]="structure.label">
                                                {{structure.label}}
                                            </div>
                                            <div e2eItem="structure-chart">
                                                <highcharts-chart id="chart_{{i}}" [Highcharts]="Highcharts"
                                                    [options]="structure.chart.options" [oneToOne]="false"
                                                    style="width: 200px; height: 200px; margin: auto; display: block;">
                                                </highcharts-chart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row -mt-7">
                                <div class="col-md-12">
                                    <div class="item first-item non-graph">
                                        <div class="table-data">
                                            <table class="table" summary="structure">
                                                <tr>
                                                    <th id="header1">
                                                        <div class="placeholder"
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.RETURN_PERIOD' | translate">
                                                        </div>
                                                    </th>
                                                    <th id="header2">
                                                        <div class="placeholder"
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.PERCENTILE' | translate">
                                                        </div>
                                                    </th>
                                                </tr>
                                                <tr *ngIf="vm.isAnyExpanded">
                                                    <td>&nbsp;</td>
                                                    <td class="bl-0">&nbsp;</td>
                                                </tr>
                                                <tr *ngFor="let row of vm.risk.alertnativeStructure.rows">
                                                    <td>{{ row.returnPeriod }}</td>
                                                    <td>{{ row.percentage + 'th' }}</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td class="font-weight-bold text-right">
                                                        <span
                                                            [innerHTML]="translationKey + '.TABLE.MEAN' | translate"></span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="item non-graph non-graph-loss">
                                        <div class="table-data">
                                            <table class="table" summary="structure">
                                                <tr>
                                                    <th id="header1">
                                                        <div class="placeholder"
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.COUNT' | translate">
                                                        </div>
                                                    </th>
                                                    <th id="header2">
                                                        <div class="placeholder"
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.GROSS_LOSS' | translate">
                                                        </div>
                                                    </th>
                                                </tr>
                                                <tr *ngIf="vm.isAnyExpanded">
                                                    <td>&nbsp;</td>
                                                    <td class="bl-0">&nbsp;</td>
                                                </tr>
                                                <tr *ngFor="let row of vm.risk.alertnativeStructure.rows">
                                                    <td>{{ row.count | wtwNumber }}</td>
                                                    <td>{{ row.grossLoss | shortNumberFormat }}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-weight-bold">{{
                                                        vm.risk.alertnativeStructure.totalCount
                                                        | wtwNumber }}</td>
                                                    <td class="font-weight-bold">{{
                                                        vm.risk.alertnativeStructure.totalGrossLoss | shortNumberFormat
                                                        }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div e2eItem="current-structure-table" class="item"
                                        [style.width]="getWidth(vm.risk.alertnativeStructure.currentStructure)">
                                        <div class="table-data">
                                            <table class="table" summary="structure">
                                                <tr>
                                                    <th id="header1">
                                                        <div
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_RETAINED' | translate">
                                                        </div>
                                                        <div
                                                            *ngIf="vm.risk.alertnativeStructure.currentStructure.retainedColumns.length">
                                                            <a class="cursor-pointer detail-view"
                                                                (click)="toggleRetained(vm.risk.alertnativeStructure.currentStructure)">
                                                                <span
                                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.DETAIL_VIEW' | translate"></span>
                                                                <span class="arrow" [ngClass]="{ 
                                                                'down-arr': !isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure), 
                                                                'up-arr': isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure) 
                                                            }"></span>
                                                            </a>
                                                        </div>
                                                        <div *ngIf="!vm.risk.alertnativeStructure.currentStructure.retainedColumns.length"
                                                            class="detail-view-placeholder">&nbsp;</div>
                                                    </th>
                                                    <th id="header2">
                                                        <div
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_TRANSFERRED' | translate">
                                                        </div>
                                                        <div
                                                            *ngIf="vm.risk.alertnativeStructure.currentStructure.transferredColumns.length">
                                                            <a class="cursor-pointer detail-view"
                                                                (click)="toggleTransferred(vm.risk.alertnativeStructure.currentStructure)">
                                                                <span
                                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.DETAIL_VIEW' | translate"></span>
                                                                <span class="arrow" [ngClass]="{ 
                                                                'down-arr': !isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure), 
                                                                'up-arr': isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure) 
                                                            }"></span>
                                                            </a>
                                                        </div>
                                                        <div *ngIf="!vm.risk.alertnativeStructure.currentStructure.transferredColumns.length"
                                                            class="detail-view-placeholder">&nbsp;</div>
                                                    </th>
                                                </tr>
                                                <tr *ngIf="vm.isAnyExpanded">
                                                    <td class="p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>&nbsp;</li>
                                                            <li
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.retainedColumns">
                                                                {{ sub.name }}</li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                    <td class="p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>&nbsp;</li>
                                                            <li
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.transferredColumns">
                                                                {{ sub.name }}</li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let row of vm.risk.alertnativeStructure.rows">
                                                    <td class="p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.rows[row.id].retained
                                                                | shortNumberFormat }}
                                                            </li>
                                                            <li
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.rows[row.id].retainedBreakDown">
                                                                {{ sub.value | shortNumberFormat }}</li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.rows[row.id].retained
                                                                | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>{{
                                                                vm.risk.alertnativeStructure.currentStructure.rows[row.id].transferred
                                                                | shortNumberFormat }}</li>
                                                            <li
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.rows[row.id].transferredBreakDown">
                                                                {{ sub.value | shortNumberFormat }}</li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li>{{
                                                                vm.risk.alertnativeStructure.currentStructure.rows[row.id].transferred
                                                                | shortNumberFormat }}</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li class="font-weight-bold">
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.totalRetained
                                                                | shortNumberFormat }}
                                                            </li>
                                                            <li class="font-weight-bold"
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.retainedColumns">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isRetainedExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li class="font-weight-bold">
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.totalRetained
                                                                | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="font-weight-bold p-0">
                                                        <ul class="sub-grid"
                                                            *ngIf="isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li class="font-weight-bold">
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.totalTransferred
                                                                | shortNumberFormat }}
                                                            </li>
                                                            <li class="font-weight-bold"
                                                                *ngFor="let sub of vm.risk.alertnativeStructure.currentStructure.transferredColumns">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid"
                                                            *ngIf="!isTransferredExpanded(vm.risk.alertnativeStructure.currentStructure)">
                                                            <li class="font-weight-bold">
                                                                {{
                                                                vm.risk.alertnativeStructure.currentStructure.totalTransferred
                                                                | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="item" *ngFor="let structure of vm.risk.alertnativeStructure.structures"
                                        [style.width]="getWidth(structure)">
                                        <div class="table-data">
                                            <table class="table" summary="structure">
                                                <tr>
                                                    <th id="header1">
                                                        <div
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_RETAINED' | translate">
                                                        </div>
                                                        <div *ngIf="structure.retainedColumns.length">
                                                            <a class="cursor-pointer detail-view"
                                                                (click)="toggleRetained(structure)">
                                                                <span
                                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.DETAIL_VIEW' | translate"></span>
                                                                <span class="arrow" [ngClass]="{ 
                                                                'down-arr': !isRetainedExpanded(structure), 
                                                                'up-arr': isRetainedExpanded(structure) 
                                                            }"></span>
                                                            </a>
                                                        </div>
                                                        <div *ngIf="!structure.retainedColumns.length"
                                                            class="detail-view-placeholder">&nbsp;</div>
                                                    </th>
                                                    <th id="header2">
                                                        <div
                                                            [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_TRANSFERRED' | translate">
                                                        </div>
                                                        <div *ngIf="structure.transferredColumns.length">
                                                            <a class="cursor-pointer detail-view"
                                                                (click)="toggleTransferred(structure)">
                                                                <span
                                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.DETAIL_VIEW' | translate"></span>
                                                                <span class="arrow" [ngClass]="{ 
                                                                'down-arr': !isTransferredExpanded(structure), 
                                                                'up-arr': isTransferredExpanded(structure) 
                                                            }"></span>
                                                            </a>
                                                        </div>
                                                        <div *ngIf="!structure.transferredColumns.length"
                                                            class="detail-view-placeholder">&nbsp;</div>
                                                    </th>
                                                </tr>
                                                <tr *ngIf="vm.isAnyExpanded">
                                                    <td class="p-0">
                                                        <ul class="sub-grid" *ngIf="isRetainedExpanded(structure)">
                                                            <li>&nbsp;</li>
                                                            <li *ngFor="let sub of structure.retainedColumns">{{
                                                                sub.name }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isRetainedExpanded(structure)">
                                                            <li>&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                    <td class="p-0">
                                                        <ul class="sub-grid" *ngIf="isTransferredExpanded(structure)">
                                                            <li>&nbsp;</li>
                                                            <li *ngFor="let sub of structure.transferredColumns">{{
                                                                sub.name
                                                                }}</li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isTransferredExpanded(structure)">
                                                            <li>&nbsp;</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr *ngFor="let row of vm.risk.alertnativeStructure.rows">
                                                    <td class="p-0">
                                                        <ul class="sub-grid" *ngIf="isRetainedExpanded(structure)">
                                                            <li>
                                                                {{ structure.rows[row.id].retained | shortNumberFormat
                                                                }}
                                                            </li>
                                                            <li
                                                                *ngFor="let sub of structure.rows[row.id].retainedBreakDown">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isRetainedExpanded(structure)">
                                                            <li>
                                                                {{ structure.rows[row.id].retained | shortNumberFormat
                                                                }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="p-0">
                                                        <ul class="sub-grid" *ngIf="isTransferredExpanded(structure)">
                                                            <li>{{ structure.rows[row.id].transferred |
                                                                shortNumberFormat }}
                                                            </li>
                                                            <li
                                                                *ngFor="let sub of structure.rows[row.id].transferredBreakDown">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isTransferredExpanded(structure)">
                                                            <li>{{ structure.rows[row.id].transferred |
                                                                shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="p-0">
                                                        <ul class="sub-grid" *ngIf="isRetainedExpanded(structure)">
                                                            <li class="font-weight-bold">
                                                                {{ structure.totalRetained | shortNumberFormat }}
                                                            </li>
                                                            <li class="font-weight-bold"
                                                                *ngFor="let sub of structure.retainedColumns">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isRetainedExpanded(structure)">
                                                            <li class="font-weight-bold">
                                                                {{ structure.totalRetained | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="font-weight-bold p-0">
                                                        <ul class="sub-grid" *ngIf="isTransferredExpanded(structure)">
                                                            <li class="font-weight-bold">
                                                                {{ structure.totalTransferred | shortNumberFormat }}
                                                            </li>
                                                            <li class="font-weight-bold"
                                                                *ngFor="let sub of structure.transferredColumns">
                                                                {{ sub.value | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                        <ul class="sub-grid" *ngIf="!isTransferredExpanded(structure)">
                                                            <li class="font-weight-bold">
                                                                {{ structure.totalTransferred | shortNumberFormat }}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<br />
<br />