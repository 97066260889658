import { ChangeDetectionStrategy, Component, ElementRef, Inject, LOCALE_ID, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import itemSeries from 'highcharts/modules/item-series';
itemSeries(Highcharts);

import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { BaseTable } from '../../../../shared/models/BaseTable';
import { APP_CONSTANTS, parliamentChartColorsPremium } from 'app/shared/helpers/ApplicationConstants';
import { ExecutiveSummaryService } from '../../executive-summary.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    PercentileVM,
    TechnicalRange
} from 'app/api/generated/ExecutiveSummaryViewModel';
import { PermissionService } from 'app/services/permissions.service';
import { PercentageFormatterPipe } from 'app/pipes/percentage.pipe';

@Component({
    selector: 'dtcor-premium-expenses',
    templateUrl: './premium-expenses.component.html',
    styleUrls: ['./premium-expenses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PremiumExpensesComponent implements OnInit, OnDestroy  {

    translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PREMIUM_N_EXPENSES';
    Highcharts: typeof Highcharts = Highcharts;
    topPremiumRisksDriversOptions: Highcharts.Options;
    fixedCostPercentages = {
        premiumPercentage: 0,
        expensePercentage: 0
    };
    marketVm;
    captiveVm;
    seeMore: any = {
        isActive: false,
        moreText: '',
        lessText: '',
        hasAccess: true
    };

    viewModel: Observable<any>;
    marketChart: Highcharts.Chart;
    captiveChart: Highcharts.Chart;
    @ViewChild('marketChartContainer') marketChartContainer: ElementRef;
    @ViewChild('captiveChartContainer') captiveChartContainer: ElementRef;

    private marketTable: MarketTable;
    private captiveTable: CaptiveTable;
    private subscriptions = [];

    constructor(private translateService: TranslateService,
        private executiveSummaryService: ExecutiveSummaryService,
        @Inject(PermissionService) private _permissionService: PermissionService,
        @Inject(LOCALE_ID) private locale: string) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit(): void {

        this.subscriptions.push(this.executiveSummaryService.selectedTab?.subscribe((tab) => {
            if (tab.tab === "premium-expenses" && tab.isSelected) {
                if (this.captiveChartInstance) {
                    this.captiveChartResize();
                }

                if (this.marketChartInstance) {
                    this.marketChartResize();
                }
            }
        }));

        this.viewModel = this.getViewModel();
        this.seeMore.moreText = this.translateService.instant(this.translationKey + '.SEE_MORE');
        this.seeMore.lessText = this.translateService.instant(this.translationKey + '.SEE_LESS');
        this.seeMore.hasAccess = this._permissionService.hasAccessToPremiumExpensesSeeMore();
    }

    calculatePremiumExpensePercentages(data: PercentileVM) {
        return {
            premiumPercentage: (data.premiumExpenses.premium / data.premiumExpenses.totalCosts) * 100,
            expensePercentage: (data.premiumExpenses.expense / data.premiumExpenses.totalCosts) * 100
        };
    }

    getViewModel() {
        return combineLatest([
            this.executiveSummaryService.isSevereYear,
            this.executiveSummaryService.severePercentileChanged,
            this.executiveSummaryService.typicalPercentileChanged,
            this.executiveSummaryService.executiveSummaryViewModel
        ])
            .pipe(map(([isSevereYear, severePercentile, typicalPercentile, summaryViewModel]) => {
                const selectedPercentileViewModel = this.executiveSummaryService
                    .getSelectedPercentileViewModel(isSevereYear, severePercentile.id, typicalPercentile.id);
                this.marketTable = new MarketTable(this.translationKey,
                    this.translateService,
                    selectedPercentileViewModel.premiumExpenses.marketTable,
                    selectedPercentileViewModel.hasExpenseKPI);
                this.marketVm = this.marketTable.getViewModel();
                this.captiveTable = new CaptiveTable(this.translationKey,
                    this.translateService,
                    selectedPercentileViewModel.premiumExpenses.captiveTable,
                    selectedPercentileViewModel.hasExpenseKPI);
                this.captiveVm = this.captiveTable.getViewModel();

                let statement = selectedPercentileViewModel.hasExpenseKPI ? 'CONTENT' : 'CONTENT_PREMIUM';
                statement = this.translateService.instant(`${this.translationKey}.${statement}`, {
                    cost: this.formatNumber(selectedPercentileViewModel.premiumExpenses.totalCosts),
                    premium: this.formatNumber(selectedPercentileViewModel.premiumExpenses.premium),
                    expense: this.formatNumber(selectedPercentileViewModel.premiumExpenses.expense)
                });

                const right = {
                    name: "dummy",
                    value: 0,
                    color: APP_CONSTANTS.colors.expense,
                    show: false,
                    chartWidth: 0
                };
                const left = {
                    name: this.translateService.instant(this.translationKey + '.CHART.ROI'),
                    color: '#C112A0'
                };

                const severeYearRoi = (severePercentile.transferred / severePercentile.premium) * 100;
                const typicalYearRoi = (typicalPercentile.transferred / typicalPercentile.premium) * 100;
                const widthPercentage = (typicalYearRoi / severeYearRoi) * 100;

                return {
                    typicalYear: {
                        right: {
                            ...right
                        },
                        left: {
                            ...left,
                            value: typicalYearRoi,
                            chartWidth: typicalYearRoi
                        },
                        total: 0,
                        widthPercentage: isNaN(widthPercentage) ? 0 : widthPercentage,
                        showTotal: false
                    },
                    severeYear: {
                        right: {
                            ...right
                        },
                        left: {
                            ...left,
                            value: severeYearRoi
                        },
                        total: 0,
                        showTotal: false
                    },
                    severeYearHeading: this.translateService.instant(this.translationKey + '.CHART.SEVERE_YEAR',
                        { [`severePercentile`]: this.executiveSummaryService.getSeverePercetileDisplayLabel(severePercentile.id) }),
                    typicalYearHeading: this.translateService.instant(this.translationKey + '.CHART.TYPICAL_YEAR',
                        { [`typicalPercentile`]: this.executiveSummaryService.getTypicalPercetileDisplayLabel(typicalPercentile.id) }),
                    total: selectedPercentileViewModel.premiumExpenses.totalCosts,
                    selectedDisplayType: APP_CONSTANTS.displayTypes.financial,
                    statement: statement,
                    topPremiumRisksDriversOptions: this.getTopPremiumRisksDriversOptions(selectedPercentileViewModel),
                    captiveText: this.translateService.instant(`${this.translationKey}.TABS.CAPTIVE.TEXT`, {
                        ['XX']: this.formatNumber(selectedPercentileViewModel.premiumExpenses.captivePremiumProbability, true),
                    }),
                    marketText: this.translateService.instant(`${this.translationKey}.TABS.MARKET.TEXT`, {
                        ['XX']: this.formatNumber(selectedPercentileViewModel.premiumExpenses.roiPrcentage, true),
                    }),
                    technicalPricingMarketViewModel: {
                        visible: summaryViewModel?.technicalRange?.market.length > 0,
                        options: this.getTechnicalPricingOptions(summaryViewModel.technicalRange.market, 'MARKET_TITLE', 'MARKET_SUB_TITLE'),
                        statements: this.getTechnicalRangeStatements(summaryViewModel.technicalRange.market)
                    },
                    technicalPricingCaptiveViewModel: {
                        visible: summaryViewModel?.technicalRange?.captive.length > 0,
                        options: this.getTechnicalPricingOptions(summaryViewModel.technicalRange.captive, 'CAPTIVE_TITLE', 'CAPTIVE_SUB_TITLE'),
                        statements: this.getTechnicalRangeStatements(summaryViewModel.technicalRange.captive)
                    },
                    showCaptive: selectedPercentileViewModel.premiumExpenses.isCaptiveTableVisible,
                    hasExpenseKPI: selectedPercentileViewModel.hasExpenseKPI
                };
            }));
    }

    sortCaptiveTable(event: { columnIndex: number, direction: number }) {
        if (event.direction === APP_CONSTANTS.sortDirection.ASC) {
            this.captiveTable.sortAscending(event.columnIndex);
        } else {
            this.captiveTable.sortDescending(event.columnIndex);
        }
        this.captiveVm = this.captiveTable.getViewModel();
    }

    sortMarketTable(event: { columnIndex: number, direction: number }) {
        if (event.direction === APP_CONSTANTS.sortDirection.ASC) {
            this.marketTable.sortAscending(event.columnIndex);
        } else {
            this.marketTable.sortDescending(event.columnIndex);
        }
        this.marketVm = this.marketTable.getViewModel();
    }

    formatNumber(value: number, isPercentage: boolean = false) {
        return isPercentage ? new PercentageFormatterPipe(this.locale).transform(value) :
            new ShortNumberFormatPipe().transform(value);
    }

    showPermissionModal() {
        this._permissionService.showModal();
    }

    showSeeMore(hasAccess: boolean, isVisible: boolean) {
        return hasAccess && isVisible;
    }

    riskClicked(riskName: string) {
        this.executiveSummaryService.selectRisk(riskName);
    }

    marketChartInstance(chart) {
        this.marketChart = chart;
    }

    marketChartResize() {
        setTimeout(() => {
            this.marketChart.setSize(this.marketChartContainer.nativeElement.offsetWidth, this.marketChart.chartHeight);
        });
    }

    captiveChartInstance(chart) {
        this.captiveChart = chart;
    }

    captiveChartResize() {
        setTimeout(() => {
            this.captiveChart.setSize(this.captiveChartContainer.nativeElement.offsetWidth, this.captiveChart.chartHeight);
        });        
    }

    private getTechnicalRangeStatements(technicalRange: TechnicalRange[]) {
        const above = this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT_ABOVE');
        const below = this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT_BELOW');
        const within = this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT_WITHIN');
        return technicalRange.map(range => {
            const isBelow = range.expiringPremium < range.premiumMin;
            const isAbove = range.expiringPremium > range.premiumMax;
            let text = this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT', {
                ['NN']: this.formatNumber(range.expiringPremium),
                ['range']: isAbove ? above : isBelow ? below : within,
            });

            if (range.renewalPremium !== null && range.renewalPremium !== undefined) {
                text += this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT_RENEWAL_PREMIUM', {
                    ['NN']: this.formatNumber(range.renewalPremium),
                    ['range']: range.renewalPremium > range.premiumMax ? above
                        : range.renewalPremium < range.premiumMin ? below : within,
                });
            }

            return {
                text: text,
                riskType: range.risk
            };
        }
        );
    }

    private getTechnicalPricingOptions(technicalRange: TechnicalRange[], title: string, subtitle: string): any {
        const categories = technicalRange.map(risk => risk.risk);
        const series = technicalRange.reduce((pre, curr: TechnicalRange) => {
            return [
                [...pre[0], [curr.premiumMin, curr.premiumMax]],
                [...pre[1], [curr.renewalPremium]],
                [...pre[2], [curr.expiringPremium]]
            ];
        }, [[], [], []]);

        const numberFormatter = (value) => this.formatNumber(value);
        return {
            chart: {
                type: 'columnrange',
                backgroundColor: 'transparent'
            },
            credits: {
                text: ''
            },
            title: {
                text: this.translateService.instant(this.translationKey + '.TECH_CHART.' + title),
                style: {
                    fontSize: '24px',
                    color: '#707070'
                }
            },
            subtitle: {
                text: this.translateService.instant(this.translationKey + '.TECH_CHART.' + subtitle),
                style: {
                    fontSize: '13.5px',
                },
                align: 'left'
            },
            xAxis: {
                categories,
                labels: {
                    style: {
                        fontSize: '15px',
                        color: '#000'
                    }
                },
                title: {
                    text: this.translateService.instant(this.translationKey + '.TECH_CHART.XAXIS'),
                    style: {
                        fontWeight: 'bold',
                        fontSize: '12px'
                    }
                },
            },
            yAxis: {
                min: 0,
                lineWidth: 1,
                gridLineWidth: 0,
                title: {
                    text: this.translateService.instant(this.translationKey + '.TECH_CHART.YAXIS'),
                    style: {
                        fontWeight: 'bold',
                        fontSize: '12px'
                    }
                },
                labels: {
                    enabled: true,
                    formatter: function () {
                        return numberFormatter(this.value);
                    }
                }
            },
            tooltip: {
                shared: false,
                formatter: function () {
                    const value1 = numberFormatter(this.point.low);
                    const value2 = numberFormatter(this.point.high);
                    return ((!value1 && !value2) || (value1 === 'N/A' && value2 === 'N/A')) ?
                        `<span>${this.series.name}</span>: <b>${numberFormatter(this.point.y)}</b><br/>`
                        : `<span>${this.series.name}</span>: <b>${value1}</b> - <b>${value2}</b><br/>`;
                }
            },
            legend: {
                reversed: false,
                enabled: true,
                verticalAlign: 'top',
                symbolRadius: 0,
                symbolPadding: 20,
                style: {
                    fontSize: '16px',
                    color: '#707070'
                },
                itemHoverStyle: {
                    cursor: 'default'
                }
            },
            plotOptions: {
                columnrange: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
                scatter: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                }
            },
            series: [{
                name: this.translateService.instant(this.translationKey + '.TECH_CHART.TECHNICAL_RANGE'),
                color: '#9A0D80',
                data: series[0]
            }, {
                type: 'scatter',
                name: this.translateService.instant(this.translationKey + '.TECH_CHART.EXPIRING_PREMIUM',
                    { ['yyyy']: new Date().getFullYear() - 1 }),
                color: '#ECB7E2',
                data: series[2],
                marker: {
                    symbol: 'url(../../../../../../../assets/images/rectangle_expiring.svg)',
                    states: {
                        hover: {
                            enabled: false,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                }
            }, {
                type: 'scatter',
                name: this.translateService.instant(this.translationKey + '.TECH_CHART.RENEWAL_PREMIUM',
                    { ['yyyy']: new Date().getFullYear() }),
                color: '#D764C1',
                data: series[1],
                marker: {
                    symbol: 'url(../../../../../../../assets/images/rectangle_renewal.svg)',
                    states: {
                        hover: {
                            enabled: false,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }

                }
            }]
        };
    }

    private getTopPremiumRisksDriversOptions(percentile: PercentileVM): any {
        const data = percentile.premiumExpenses.premiumRiskDrivers;
        const colours = [...parliamentChartColorsPremium, ...Highcharts.getOptions().colors];
        const total = data.reduce((pre, curr) => pre + curr.originalPremium, 0);
        const percentage = data.map(x => ({ ...x, percentage: Math.round(x.originalPremium / total * 100) }));
        const allocated = percentage.map(x => ({ ...x, points: Math.round(x.percentage * APP_CONSTANTS.parliamentChartMaxPoints / 100) }));
        const series = allocated.map((item, index) => ([item.risk, parseInt(item.points.toString(), 0), colours[index]]));

        return {
            chart: {
                type: 'item',
                backgroundColor: 'transparent'
            },
            credits: {
                text: ''
            },
            title: {
                text: this.translateService.instant(this.translationKey + '.TOPPREMIUM_CHART.TITLE'),
                style: {
                    fontSize: '24px',
                    color: '#707070'
                }
            },
            tooltip: {
                shared: false,
                hideDelay: 250,
                formatter: function () {
                    if (this.key) {
                        const currentPremiumRisk = data.find(x => x.risk === this.key);
                        return new ShortNumberFormatPipe().transform(currentPremiumRisk.originalPremium);
                    }
                    return false;
                }
            },
            legend: {
                reversed: false,
                enabled: true,
                symbolRadius: 10,
                style: {
                    fontSize: '16px'
                }
            },
            series: [{
                name: 'risk',
                keys: ['name', 'y', 'color'],
                data: [...series],
                dataLabels: {
                    enabled: false
                },
                center: ['50%', '88%'],
                size: '170%',
                startAngle: -100,
                endAngle: 100
            }]
        };
    }
}

class MarketTable extends BaseTable {
    constructor(translationKey: string,
        translateService: TranslateService,
        risks: any[],
        hasExpenseKPI: boolean) {
        const columnKeys = {
            0: 'risk',
            1: 'grossLoss',
            2: 'transferred',
            3: 'premium',
            4: 'expense',
            5: 'roi'
        };
        const roi = (columnValues: number[]) =>
            columnValues[1] > 0 && columnValues[2] > 0 ? (columnValues[1] / columnValues[2]) * 100 : 0;
        super(translationKey, translateService, 'TABS.MARKET.TABLE', columnKeys, roi);
        this.createHeaders(hasExpenseKPI);
        this.setRisks(risks);
    }

    private createHeaders(hasExpenseKPI: boolean) {
        const columnKey = this.translationKey + '.TABS.MARKET.TABLE.COLUMN';
        this.headers.push({
            text: this.translateService.instant(columnKey + '.RISKS'),
            align: APP_CONSTANTS.align.LEFT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.GROSS_LOSS'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.TOTAL_TRANSFERRED'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.PREMIUM'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.EXPENSE'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: hasExpenseKPI
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.ROI'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true,
            width: '156px'
        });
    }
}
class CaptiveTable extends BaseTable {
    constructor(translationKey,
        translateService: TranslateService,
        risks: any[],
        hasExpenseKPI: boolean) {
        const columnKeys = {
            0: 'risk',
            1: 'loss',
            2: 'retained',
            3: 'premium',
            4: 'expense',
            5: 'premiumAdequacy'
        };
        const premiumAdequacy = (columnValues: number[]) =>
            columnValues[1] > 0 && columnValues[2] > 0 ? (columnValues[1] / columnValues[2]) * 100 : 0;
        super(translationKey, translateService, 'TABS.CAPTIVE.TABLE', columnKeys, premiumAdequacy);
        this.createHeaders(hasExpenseKPI);
        this.setRisks(risks);
    }

    private createHeaders(hasExpenseKPI: boolean) {
        const columnKey = this.translationKey + '.TABS.CAPTIVE.TABLE.COLUMN';
        this.headers.push({
            text: this.translateService.instant(columnKey + '.RISKS'),
            align: APP_CONSTANTS.align.LEFT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.TOTAL_LOSS'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.TOTAL_RETAINED'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.PREMIUM'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.EXPENSE'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: hasExpenseKPI
        });
        this.headers.push({
            text: this.translateService.instant(columnKey + '.PREMIUM_ADEQUACY'),
            align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
        });
    }
}
