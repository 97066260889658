import {
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShortNumberFormatPipe } from '../../../pipes/short-number-formatter.pipe';
import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';
import { PercentageFormatterPipe } from 'app/pipes/percentage.pipe';

@Component({
    selector: 'dtcor-single-stacked-bar-chart',
    templateUrl: './single-stacked-bar-chart.component.html',
    styleUrls: ['./single-stacked-bar-chart.component.scss']
})
export class SingleStackedBarChartComponent implements OnChanges, OnInit {

    @Input() left: { value: number, name: string, color: string, chartWidth?: number };
    @Input() right: { value: number, name: string, color: string, show: boolean, chartWidth?: number };
    @Input() translationKey: string;
    @Input() widthPercentage: number;
    @Input() displayType: number;
    @Input() total: number;
    @Input() showTotalKey = true;
    @Input() showTotal = true;
    @Input() showValuesAsPercentage = false;

    displayTypeChanged: BehaviorSubject<number>;
    viewModel: Observable<any>;

    constructor(@Inject(LOCALE_ID) private locale: string) {
        // empty block
    }

    ngOnInit(): void {
        this.displayTypeChanged = new BehaviorSubject<number>(this.displayType);
        this.viewModel = this.displayTypeChanged.pipe(map((type) => {
            const isPercentage = type !== APP_CONSTANTS.displayTypes.financial;
            return {
                right: {
                    value: this.right.value,
                    color: this.right.color,
                    name: this.right.name,
                    width: isPercentage ? this.right.chartWidth ?? this.right.value : this.getPercentage(this.right.value)
                },
                left: {
                    value: this.left.value,
                    color: this.left.color,
                    name: this.left.name,
                    width: isPercentage ? this.left.chartWidth ?? this.left.value : this.getPercentage(this.left.value)
                },
                containerWidth: !isPercentage && this.widthPercentage > 0 ? ((83.3 / 100) * this.widthPercentage) : 83.3,
                isPercentage: isPercentage,
                summary: this.getSummary(isPercentage)
            };
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        const displayTypeChanges = changes && changes['displayType'];
        if (this.displayTypeChanged && (displayTypeChanges || this.displayType)) {
            const displayTypeValue = displayTypeChanges ? displayTypeChanges.currentValue : this.displayType;
            this.displayTypeChanged.next(displayTypeValue);
        }
    }

    formatNumber(value: number): string {
        return new ShortNumberFormatPipe().transform(value);
    }

    private getSummary(isPercentage: boolean) {
        const percentageFormatter = (value: number) => new PercentageFormatterPipe(this.locale).transform(value);
        const formatter = isPercentage || this.showValuesAsPercentage ? percentageFormatter : this.formatNumber;
        let text = `${this.left.name} ${formatter(this.left.value)}`;
        if (this.right.show) {
            text += ` | ${this.right.name} ${formatter(this.right.value)}`;
        }
        return text;
    }

    private getPercentage(value: number) {
        const total = this.right.value + this.left.value;
        const percent = (value / total) * 100;
        return isNaN(percent) ? 0 : percent;
    }
}
