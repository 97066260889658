<table class="table border-left-0 border-right-0 text-right text-left" summary="structure">
    <tr class="font-weight-bold">
        <ng-container *ngFor="let header of table.headers;let i = index">
            <td  class="border-top-0"
                *ngIf="header.visible"
                [style.width]="header.width ? header.width : 'auto'"
                [ngClass]="{ 'text-right': align.Right === header.align, 'text-left': align.Left === header.align }">
                <span class="icon-combo-arrow" *ngIf="header.sortable && !isAscending(i) && !isDescending(i)" (click)="clickAction(header, i)"></span>
                <span [ngClass]="{ 'icon-down_arrow': isAscending(i), 'icon-up_arrow': isDescending(i) }" (click)="clickAction(header, i)"></span>
                <span [innerHTML]="header.text" class="table-header" [ngClass]="[(hasCustomClass(header) ? header.customClass : '')]"
                (click)="clickAction(header, i)"></span>
            </td>
        </ng-container>
    </tr>
    <tr *ngFor="let row of table.rows" [ngClass]="{ 'font-weight-bold': row.isBold }" class="data-row">
        <ng-container *ngFor="let column of row.data">
            <td *ngIf="column.visible"
                [ngClass]="{ 'font-weight-bold': row.isBold, 'text-left': isLeftAlign(column), 'text-right': !isLeftAlign(column) }">
                <span *ngIf="column.isRiskName"><a (click)="riskClick(column.value)">{{column.value}}</a></span>
                <span *ngIf="!column.isRiskName">{{format(column)}}</span>
            </td>
        </ng-container>
    </tr>
</table>