<div e2eGroup="risk-summary-gross-losses" class="row" [ngStyle]="{ width: 'calc(100vw - 100px)' }" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="vm.statement"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 pt-4">
        <div class="row pb-4">
            <div class="col-md-12">
                <div e2eItem="avg-annual-loss" class="annual-loss fs-24 float-left">{{vm.selected.grossLoss.averageAnnualLoss | shortNumberFormat }}</div>
                <div class="float-left pt-4 pl-4">
                    <div class="text-wtw-gray-8 tabs-text pt-1" style="display: inline-block;"
                        [innerHTML]="translationKey + '.ANNUAL_AVERAGE_LOSS' | translate">
                    </div>
                    <div class="pl-2" style="display: inline-block;">
                        <select e2eItem="split-ddl" class="select-wrapper split-ddl" #riskLevel [class.disabled]="vm.level1.length === 1" [disabled]="vm.level1.length === 1" (change)="level1Selected(vm.level1,riskLevel.value)">
                            <option *ngFor="let detail of vm.level1" [value]="detail.id"
                                [selected]="detail.id === vm.level1Selected.id">{{detail.name}}</option>
                        </select>
                    </div>                    
                </div>
            </div>
        </div>
        <hr class="my-1 mb-2" />
    </div>
    <div class="col-md-12 mt-2 mb-4">
        <div class="row pb-4">
            <div class="col-md-2" e2eItem="splits">
                <ng-container *ngIf="vm.level2.length > 1">
                    <div class="annual-loss-split p-2 mt-4" *ngFor="let detail of vm.level2">
                        <div class="split-by-group-name" title={{detail.name}}>{{detail.name}}</div>
                        <p class="split-by-group-value text-right mt-4">{{detail.averageAnnualLoss | shortNumberFormat}}
                        </p>
                        <p class="split-by-group-percent text-right">{{detail.percentage | percentageFormatter}}</p>
                    </div>
                </ng-container>
            </div>
            <div class="col-md-10 pt-4 pb-4 pr-4 scrollabel">
                <table e2eItem="gross-loss-table" *ngIf="vm.level2.length > 0" class="gross-loss-table" summary="structure">
                    <tr>
                        <th id="header1" class="remove-bottom-border min-width-120">{{translationKey + '.TABLE.RETURN_PERIOD' | translate}}</th>
                        <th id="header2" class="remove-bottom-border">{{translationKey + '.TABLE.PERCENTILE' | translate}}</th>
                        <th id="header3" colspan="2">{{translationKey + '.TABLE.ALL_LOSSES' | translate}}</th>                        
                        <ng-container *ngIf="vm.level2.length > 1">
                            <ng-container *ngFor="let detail of vm.level2">
                                <th colspan="2">{{detail.name}}</th>
                                <ng-container *ngIf="isExpended(detail.id)">
                                    <ng-container *ngIf="getSubLevel(detail.id,vm.level3) as sublevel">
                                        <ng-container *ngFor="let level3 of sublevel">
                                            <th id="header4" colspan="2" class="sub-header"> {{level3.name}}</th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th id="header1" class="no-border">&nbsp;</th>
                        <th id="header2" class="no-border">&nbsp;</th>
                        <th id="header3">{{translationKey + '.TABLE.COUNT' | translate}}</th>
                        <th id="header4" class="min-width-100">{{translationKey + '.TABLE.GROSS_LOSSES' | translate}}</th>                        
                        <ng-container *ngIf="vm.level2.length > 1">
                            <ng-container *ngFor="let detail of vm.level2">
                                <th id="header5" >{{translationKey + '.TABLE.COUNT' | translate}}</th>
                                <th id="header6" class="min-width-100">{{translationKey + '.TABLE.GROSS_LOSSES' | translate}}</th>
                                <ng-container *ngIf="isExpended(detail.id)">
                                    <ng-container *ngIf="getSubLevel(detail.id,vm.level3) as sublevel">
                                        <ng-container *ngFor="let level3 of sublevel">
                                            <th id="header7" class="sub-header">{{translationKey + '.TABLE.COUNT' | translate}}</th>
                                            <th id="header8" class="sub-header min-width-100">{{translationKey + '.TABLE.GROSS_LOSSES' | translate}}
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>          
                        </ng-container>              
                    </tr>
                    <tr *ngIf="!!vm.canExpand">
                        <th id="header1" class="no-border">&nbsp;</th>
                        <th id="header2" class="no-border">&nbsp;</th>
                        <th id="header3">&nbsp;</th>
                        <th id="header4">&nbsp;</th>
                        <ng-container *ngIf="vm.level2.length > 1">
                            <ng-container *ngFor="let detail of vm.level2">
                                <ng-container *ngIf="getSubLevel(detail.id,vm.level3) as sublevel">
                                    <ng-container *ngIf="sublevel.length > 0">
                                        <th id="header5" colspan="2">
                                            <div class="cursor-pointer detail-view" (click)="toggleDetailView(detail.id)"
                                                style="width: 100%;display: inline-block;">
                                                <span
                                                    [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                                <span class="arrow"
                                                    [ngClass]="{ 'down-arr': !isExpended(detail.id), 'up-arr': isExpended(detail.id) }"></span>
                                            </div>
                                        </th>
                                        <ng-container *ngIf="isExpended(detail.id)">
                                            <ng-container *ngIf="getSubLevel(detail.id,vm.level3) as sublevel">
                                                <ng-container *ngFor="let level3 of sublevel">
                                                    <th id="header6">&nbsp;</th>
                                                    <th id="header7">&nbsp;</th>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <ng-container *ngIf="vm.level2 && vm.level2.length > 0">
                        <tr *ngFor="let detail of vm.level2[0].details; let i = index;let last=last" class="gross-loss-table-body"
                            [ngClass]="{ 'font-weight-bold': last }">
                            <td>{{detail.returnPeriod}}</td>
                            <td *ngIf="!!detail.percentile">{{suffix(detail.percentile)}}</td>
                            <td *ngIf="!detail.percentile">{{detail.percentileLabel | titlecase}}</td>
                            <td>
                                {{detail.totalCount | wtwNumber}}
                            </td>
                            <td>
                                {{detail.totalGrossLoss | shortNumberFormat}}
                            </td>
                            <ng-container *ngIf="vm.level2.length > 1">
                                <ng-container *ngFor="let level2Details of vm.level2; let last=last">
                                    <td>
                                        {{level2Details.details[i].count | wtwNumber}}
                                    </td>
                                    <td>
                                        {{level2Details.details[i].grossLoss | shortNumberFormat}}
                                    </td>
                                    <ng-container *ngIf="isExpended(level2Details.id)">
                                        <ng-container *ngFor="let level3 of getSubLevel(level2Details.id,vm.level3) as level;let j=index">
                                            <td>
                                                {{level3.details[i].count | wtwNumber}}
                                            </td>
                                            <td>
                                                {{level3.details[i].grossLoss | shortNumberFormat}}
                                            </td>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </table>
                <table *ngIf="vm.level2.length === 0" class="gross-loss-table" summary="structure">
                    <tr>
                        <th id="header1" class="remove-bottom-border min-width-120">{{translationKey + '.TABLE.RETURN_PERIOD' | translate}}</th>
                        <th id="header2" class="remove-bottom-border">{{translationKey + '.TABLE.PERCENTILE' | translate}}</th>
                        <th id="header3" colspan="2">{{translationKey + '.TABLE.ALL_LOSSES' | translate}}</th>
                        <ng-container *ngIf="vm.level1.length > 1">
                            <ng-container *ngFor="let detail of vm.level1">
                                <th id="header4" colspan="2">{{detail.name}}</th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th id="header1" class="no-border">&nbsp;</th>
                        <th id="header2" class="no-border">&nbsp;</th>
                        <th id="header3">{{translationKey + '.TABLE.COUNT' | translate}}</th>
                        <th id="header4" class="min-width-100">{{translationKey + '.TABLE.GROSS_LOSSES' | translate}}</th>
                        <ng-container *ngIf="vm.level1.length > 1">
                            <ng-container *ngFor="let detail of vm.level1">
                                <th id="header5">{{translationKey + '.TABLE.COUNT' | translate}}</th>
                                <th id="header6" class="min-width-100">{{translationKey + '.TABLE.GROSS_LOSSES' | translate}}</th>                         
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr *ngIf="!!vm.canExpand">
                        <th id="header1" class="no-border">&nbsp;</th>
                        <th id="header2" class="no-border">&nbsp;</th>
                        <th id="header3">&nbsp;</th>
                        <th id="header4">&nbsp;</th>
                        <ng-container *ngFor="let detail of vm.level2">
                            <ng-container *ngIf="getSubLevel(detail.id,vm.level2) as sublevel">
                                <ng-container *ngIf="sublevel.length > 0">
                                    <th id="header5" colspan="2">
                                        <div class="cursor-pointer detail-view" (click)="toggleDetailView(detail.id)"
                                            style="width: 100%;display: inline-block;">
                                            <span
                                                [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                            <span class="arrow"
                                                [ngClass]="{ 'down-arr': !isExpended(detail.id), 'up-arr': isExpended(detail.id) }"></span>
                                        </div>
                                    </th>                            
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </tr>
                    
                    <ng-container *ngIf="vm.level1 && vm.level1.length > 0">
                        <tr *ngFor="let detail of vm.level1[0].details; let i = index;let last=last" class="gross-loss-table-body"
                            [ngClass]="{ 'font-weight-bold': last }">
                            <td>{{detail.returnPeriod}}</td>
                            <td *ngIf="!!detail.percentile">{{suffix(detail.percentile)}}</td>
                            <td *ngIf="!detail.percentile">{{detail.percentileLabel | titlecase}}</td>
                            <td>
                                {{detail.totalCount | wtwNumber}}
                            </td>
                            <td>
                                {{detail.totalGrossLoss | shortNumberFormat}}
                            </td>
                            <ng-container *ngIf="vm.level2.length > 1">
                                <ng-container *ngFor="let level2Details of vm.level1; let last=last">
                                    <td>
                                        {{level2Details.details[i].count | wtwNumber}}
                                    </td>
                                    <td>
                                        {{level2Details.details[i].grossLoss | shortNumberFormat}}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>