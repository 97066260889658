<ng-container e2eGroup="executive-summary" #container>
    <div class="titlebar--wrap">
        <div class="row tilebar--container">
            <div class="col col-sm-12">
                <h1 class="display-1 text-left pl-2" e2eItem="page-heading">
                    {{ translationKey + ".HEADING" | translate }}

                    <dtcor-download-report-button [translationKey]="translationKey"></dtcor-download-report-button>                    
                </h1>
            </div>
        </div>
    </div>
    <ng-container *ngIf="viewModel | async as vm">
        <div class="mt-3 mb-3 pl-5 pr-4" [context-menu]>
            <div class="mb-3">
                <div class="fs-24 mb-2" [innerHTML]="vm.tcorStatement"></div>
                <div class="mb-2 font-weight-bold fs-16" [innerHTML]="translationKey + '.TCOR_TYPE_STATEMENT' | translate"></div>
                <div>
                    <ul class="switch-tabs"> 
                        <li e2eItem="tabTypicalYear" (click)="onToggleChange(false)" [ngClass]="{ 'active': !vm.isSevereYear }">{{ translationKey + '.TYPICAL_YEAR' | translate }}</li>
                        <li e2eItem="tabSevereYear" (click)="onToggleChange(true)" [ngClass]="{ 'active': vm.isSevereYear }">{{ translationKey + '.SEVERE_YEAR' | translate }}</li>
                    </ul>
                </div>
            </div>
            <div class="es-content p-4" #contentEl>
                <div class="row mb-3">
                    <div class="col-md-12 es-statements">
                        <div class="mb-1">

                            <div class="fs-24 font-weight-bold mb-2">
                                <span [innerHTML]="vm.selectedYearLabel"></span>
                                <span class="pl-2">({{ (vm.isSevereYear ? vm.serverPercentileValue : vm.typicalPercentileValue) }})</span>
                            </div>

                            <div class="fs-16 mb-2">
                                <span *ngIf="!vm.isSevereYear" [innerHTML]="translationKey + '.TYPICAL_YEAR_DESCRIPTION' | translate"></span>
                                <span *ngIf="vm.isSevereYear" [innerHTML]="translationKey + '.SEVERE_YEAR_DESCRIPTION' | translate"></span>
                            </div>

                            <div class="fs-16" [ngClass]="{ visibile: vm.isSevereYear, nvisibile: !vm.isSevereYear }">
                                <span [innerHTML]="translationKey + '.SEVERE_YEAR_SELECTION' | translate"></span>
                                <span class="pl-2">
                                    <select (change)="onSevereChange($event)" id="ddlSeverePercentile" e2eItem="severe-percentile-dropdown" class="select-wrapper">
                                        <option *ngFor="let d of vm.severePercentiles" [value]="d.percentileId ? d.percentileId : d" [selected]="vm.selectedSevereYearPercentileId === d.percentileId">
                                            {{d.percentileRadioLabel ? d.percentileRadioLabel : d}}
                                        </option>
                                    </select>
                                </span>
                            </div>                            
                        </div>
                    </div>
                </div>

                <!-- charts -->
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="donut-legend-wrapper">
                                    <div class="legend-item mb-1" *ngFor="let item of summaryChartOptions.series[0].data">
                                        <div class="legend-st" [style.background]="item.color"></div>
                                        <div class="fs-14">{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="font-weight-bold fs-16 mb-2" [innerHTML]="vm.donut.heading"></div>
                                <div class="fs-16" [innerHTML]="vm.donut.description"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <highcharts-chart (chartInstance)="summaryChartInstance($event)" [Highcharts]="Highcharts"
                                    [options]="summaryChartOptions" style="width: 100%; height: 300px; display: block;"
                                    *ngIf="summaryChartOptions">
                                </highcharts-chart>
                            </div>
                        </div>                        
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-11">
                                <div *ngIf="vm.isSevereYear" class="fs-16 text-center" [innerHTML]="vm.donut.summaryChartDetails"></div>
                            </div>
                        </div>                        
                    </div>
                    <div class="col-md-6">

                        <dtcor-top-drivers [risks]="vm.risks" [isSevere]="vm.isSevereYear"
                            [hasExpenseKPI]="vm.hasExpenseKPI" [topRisk]="vm.topRisk" (selectRisk)="selectRisk($event)">
                        </dtcor-top-drivers>

                        <div class="top-drivers-wraper">
                            <p>{{vm.topDriverStatement}}
                                <a e2eItem="top-drivers-seemore"
                                    class="cursor-pointer text-wtw-primary text-underline fs-14"
                                    (click)="seeMore.isActive = !seeMore.isActive;">
                                    {{ (seeMore.isActive ? seeMore.lessText : seeMore.moreText) }}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- /charts -->

                <!-- total tcor table -->
                <div class="row mt-4" *ngIf="seeMore.isActive">
                    <div class="col-md-12">
                        <p class="font-weight-bold fs-16">{{ translationKey + '.TOTAL_TCOR' | translate }}</p>
                        <p class="pt-2 pb-2 fs-14"
                            [innerHTML]="(translationKey + '.TOTAL_TCOR_DESCRIPTION' | translate)"></p>
                        <div class="border-top">
                            <div class="tcor-breakdown-table">
                                <dtcor-table class="tdtable" e2eItem="top-drivers-table" [table]="totalTcorTableVm.table"
                                    [sortColumnIndex]="totalTcorTableVm.sortColumnIndex" (sort)="sortTotalTcorTable($event)"
                                    (riskSelected)="selectRisk($event)">
                                </dtcor-table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /total tcor table -->

                <!-- TCOR Value Graph -->
                <ng-container *ngIf="tcoreValueGraphViewModel | async as tcoreValueGraphVM">
                    <div e2eItem="insurance-value-container" class="tcor-value-graph" *ngIf="showTcorValueGraph">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="modal-header">
                                    <span class="font-weight-bold"
                                        [innerHTML]="translationKey + '.TCOR_GRAPH.TITLE' | translate"></span>
                                    <a e2eItem="insurance-value-container-close" class="close"
                                        (click)="toggleTcorValueGraph()">X</a>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 px-4 py-3">
                            <div class="text-center mb-4">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-9">
                                        <div class="row">
                                            <div class="col-md-8 text-right">
                                                <span class="mr-3">{{ translationKey +
                                                    '.TCOR_GRAPH.DISPLAY_RISK_THRESHOLD'
                                                    |
                                                    translate }}</span>
                                                <span class="mr-2"><input type="radio" (change)="updateRiskThreshold()"
                                                        [(ngModel)]="tcorValueGraph.threshold.enabled"
                                                        name="rdoDisplayThreshold" class="circle-check"
                                                        [value]="true" /> {{
                                                    translationKey + '.TCOR_GRAPH.YES' | translate }} </span>
                                                <span class="mr-3"><input type="radio" (change)="updateRiskThreshold()"
                                                        [(ngModel)]="tcorValueGraph.threshold.enabled"
                                                        name="rdoDisplayThreshold" checked class="circle-check"
                                                        [value]="false" /> {{ translationKey +
                                                    '.TCOR_GRAPH.NO' | translate
                                                    }}</span>
                                                <span class="ml-2"><input type="text" (keyup)="updateRiskThreshold()"
                                                        [(ngModel)]="tcorValueGraph.threshold.value"
                                                        [disabled]="!tcorValueGraph.threshold.enabled"
                                                        class="text-right form-control graph-textbox"
                                                        numberFormat="number:.0-2" [min]="0" [max]="99999999999999"
                                                        digitsOnly /></span>
                                            </div>
                                            <div class="col-md-4 text-left">
                                                <select e2eItem="insurance-value-dropdown" class="select-wrapper"
                                                    (change)="onTcorValueGraphPercentileChange($event.target.value)">
                                                    <option *ngFor="let option of tcoreValueGraphVM.options"
                                                        [value]="option.value" [selected]="option.selected === true">
                                                        {{option.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-12" #insuranceValueGraphEl>
                                        <div class="legend-wrapper text-center">                                            
                                            <div><span class="legend" [style.background]="colors[1]"></span>
                                                {{ translationKey + '.TCOR_GRAPH.PREMIUM' | translate }}
                                            </div>
                                            <div *ngIf="vm.hasExpenseKPI"><span class="legend"
                                                    [style.background]="colors[2]"></span>
                                                {{ translationKey + '.TCOR_GRAPH.EXPENSES' | translate }}
                                            </div>    
                                            <div><span class="legend" [style.background]="colors[0]"></span>
                                                {{ translationKey + '.TCOR_GRAPH.TOTAL_RETAINED' | translate }}
                                            </div>                                        
                                            <div><span class="legend-line legend"
                                                    style="background-color: #00A0D2;"></span> {{
                                                translationKey + '.TCOR_GRAPH.GROSS_LOSS' | translate }}</div>
                                            <div *ngIf="tcorValueGraph.threshold.enabled"><span
                                                    class="legend-line legend"
                                                    style="background-color: #FF0000;"></span> {{
                                                translationKey +
                                                '.TCOR_GRAPH.RISK_TOLERANCE' | translate }}</div>
                                        </div>

                                        <highcharts-chart (chartInstance)="tcorChartInstance($event)"
                                            [Highcharts]="Highcharts" [options]="tcoreValueGraphVM.chartOptions"
                                            (window:resize)="onResize($event)" style="width: 100%;display: block;">
                                        </highcharts-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- /TCOR Value Graph -->
            </div>

            <!-- stats boxes -->
            <div class="es-content">
                <div class="row">
                    <div class="col-md-6">
                        <div class="stat-box">
                            <div class="row">
                                <div class="col-md-3" *ngIf="vm.hasAccessToPrecisionRating">
                                    <div class="rating text-center py-3">
                                        <span e2eItem="precision-rating"
                                            class="precision green">{{vm.precisionRating}}</span><span
                                            class="denominator">/10</span>
                                    </div>
                                </div>
                                <div e2eItem="precision-statements" class="py-4 stat-box-text"
                                    [ngClass]="{ 'col-md-9': vm.hasAccessToPrecisionRating, 'col-md-12 px-5': !vm.hasAccessToPrecisionRating }">
                                    <div [innerHTML]="vm.precisionRatingText.text"></div>
                                    <div>
                                        <span [innerHTML]="translationKey + '.PRECISION_RATING_SEE' | translate"></span>
                                        <a (click)="scroll(nextSteps)"
                                            [innerHTML]="translationKey + '.PRECISION_RATING_NEXT_STEPS' | translate"
                                            class="pl-1 text-wtw-primary"></a>
                                        <span *ngIf="!vm.precisionRatingText.hasAccessToPrecisionRating">.</span>
                                        <span *ngIf="vm.precisionRatingText.hasAccessToPrecisionRating"
                                            [innerHTML]="vm.precisionRatingText.improveText" class="pl-1"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="stat-box">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="rating font-weight-bold text-center py-3">
                                        <span e2eItem="tcor-percentage" class="percentage">{{vm.percentage |
                                            percentageFormatter}}</span>
                                    </div>
                                </div>
                                <div e2eItem="tcor-precision-percentage" class="col-md-6 py-4 stat-box-text"
                                    [innerHTML]="translations.precisionPercentage">
                                </div>
                                <div class="col-md-3">
                                    <div e2eItem="toggle-insurance-value-graph"
                                        class="graph-box w-100 h-100 align-middle text-center p-3"
                                        *ngIf="!showTcorValueGraph">
                                        <a class="cursor-pointer" (click)="toggleTcorValueGraph(contentEl)">
                                            <img alt="tcor value graph" [src]="'/assets/images/graph.svg'"
                                                class="graph-img mt-1" [ngClass]="{ 'active': showTcorValueGraph }" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /stats boxes -->

            <!-- tabs -->
            <div class="my-4">
                <div class="row">
                    <div class="col-md-12">
                        <div>
                            <tabset>
                                <tab>
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-risk-tolerance">
                                            {{ translationKey + '.TABS.RISK_TOLERANCE.TITLE' | translate }}
                                        </span>
                                    </ng-template>
                                    <dtcor-risk-tolerence></dtcor-risk-tolerence>
                                </tab>
                                 <tab [active]="true" (selectTab)="onHistoricalLossesTabSelect()">
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-historical-losses">
                                            {{ translationKey + '.TABS.HISTORICAL_LOSSES.TITLE' | translate }}
                                            <img alt="lock" *ngIf="!vm.canAccessHistoricalLosses" class="ml-1"
                                                [src]="'/assets/images/lock.svg'" />
                                        </span>
                                    </ng-template>
                                    <dtcor-historical-losses *ngIf="vm.canAccessHistoricalLosses">
                                    </dtcor-historical-losses>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-gross-losses">
                                            {{ translationKey + '.TABS.GROSS_LOSSES.TITLE' | translate }}
                                        </span>
                                    </ng-template>
                                    <dtcor-gross-losses></dtcor-gross-losses>
                                </tab>
                                <tab>
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-program-structures">
                                            {{ translationKey + '.TABS.PROGRAM_STRUCTURES.TITLE' | translate }}
                                        </span>
                                    </ng-template>
                                    <dtcor-program-structure></dtcor-program-structure>
                                </tab>
                                <tab (selectTab)="onTabAction('premium-expenses', true)">
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-premium-expenses">
                                            {{ translationKey + ('.TABS.PREMIUM_N_EXPENSES.' + (vm.hasExpenseKPI ?
                                            'TITLE' : 'PREMIUM_TITLE')) | translate }}
                                        </span>
                                    </ng-template>
                                    <dtcor-premium-expenses></dtcor-premium-expenses>
                                </tab>
                                <tab (deselect)="onTabAction('portfolio-comparison', false)">
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-portfolio-comparison">
                                            {{ translationKey + '.TABS.PORTFOLIO_COMPARISON.TITLE' | translate }}
                                        </span>
                                    </ng-template>
                                    <dtcor-portfolio-comparison></dtcor-portfolio-comparison>
                                </tab>
                            </tabset>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /tabs -->

            <hr class="" />

            <!-- next-steps -->
            <div class="p-4 mb-4" id="next-steps" e2eItem="next-steps" #nextSteps>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="font-weight-bold">{{ translationKey + '.NEXT_STEPS.HEADING' | translate }}</h3>
                        <ul class="mt-4">
                            <li class="mb-4" *ngFor="let nextStep of translations.nextSteps">
                                <h5 class="border-bottom font-weight-bold" [innerHTML]="nextStep.HEADING"></h5>
                                <hr class="my-1 mb-2" />
                                <p [innerHTML]="nextStep.CONTENT"></p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- /next-steps -->

            <br />
        </div>
    </ng-container>
</ng-container>