<div class="row" e2eGroup="executive-summary-portfolio-comparison" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4">
            <div e2eItem="portfolio-popup" *ngIf="vm.showPopup" class="portfolio-popup-container">
                <dtcor-portfolio-add-edit 
                    (closeEvent)="closePopup($event)" 
                    [portfolio]="vm.selectedPortfolio"
                    [risks]="vm.risks" 
                    [portfolioNames]="vm.portfolioNames" 
                    (addEvent)="addPortfolio($event)"
                    (editEvent)="editPortfolio($event)">
                </dtcor-portfolio-add-edit>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="sub-header-text"
                        [innerHTML]="translationKey + '.CONTENT' | translate"></div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 mt-4">
                    <div e2eItem="tab-chart" class="tab" [ngClass]="{'selected': vm.isChartSelected}"
                        (click)="selectChartTab()" [innerHTML]="translationKey + '.PORTFOLIO.TABS.CHART' | translate">
                    </div>
                    <div e2eItem="tab-table" class="tab" [ngClass]="{'selected': !vm.isChartSelected}"
                        (click)="selectTableTab()" [innerHTML]="translationKey + '.PORTFOLIO.TABS.TABLE' | translate">
                    </div>
                </div>
                <div class="col-12 mt-2  text-right">
                    <div class="footer-button-container">
                        <div class="willis-icon icon-plus-circle" (click)="add(vm.newPortfolio)"
                            e2eItem="add-portfolio-button"></div>
                        <div class="add-another-model-button pl-2" (click)="add(vm.newPortfolio)">
                            <span>{{translationKey + '.ADD_PORTFOLIO' | translate}}</span>
                        </div>
                    </div>
                </div>
                <div e2eItem="tab-chart-content" class="col-12 mt-2" *ngIf="vm.isChartSelected">
                    <div class="row">
                        <div class="col-md-12 scroll-x">
                            <table e2eItem="table-data" class="main-table" summary="chart table">
                                <tr>
                                    <th id="header1" class="no-border"></th>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <th class="p-0" [ngClass]="{'selected-th-bl':structure.isSelected, 'no-border':!structure.isSelected}">
                                            <div [ngClass]="{'selected-portfolio selected-text-container-b':structure.isSelected}"
                                                class="selected-text-container">
                                                <span *ngIf="structure.isSelected">Selected</span>
                                            </div>
                                        </th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <th id="header1"></th>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <th [ngClass]="{'selected-portfolio selected-th-bt':structure.isSelected}">
                                            <dtcor-portfolio-context-menu [isSelectVisible]="showSelect(structure)"
                                                [isEditVisible]="showEdit(structure)" [isRemoveVisible]="showRemove(structure)"
                                                [isViewVisible]="structure.isCurrent" [translationKey]="translationKey"
                                                [name]="getLabel(structure)" (eventSelect)="select(structure)" (eventEdit)="view(structure)"
                                                (eventDelete)="delete(structure)">
                                            </dtcor-portfolio-context-menu>
                                        </th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        <div class="legend-wrapper">
                                            <div class="mb-2 legend-item" [class.legendDisabled]="!legend.enabled" *ngFor="let legend of vm.legends" (click)="toggleSeries(legend)">
                                                <div class="legend-st" [style.background]="legend.colour"></div>
                                                <div class="fs-14" [innerHTML]="legend.name"></div>
                                            </div>
                                        </div>
                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            <dtcor-portfolio-chart [item]="structure" [translationKey]="translationKey" [colours]="vm.colours"
                                                [maxCCOR]="vm.maxCCOR" [toggleSeries]="vm.toggleSeries"></dtcor-portfolio-chart>
                                        </td>
                                    </ng-container>
                                </tr>
                            </table>
                            <dtcor-portfolio-common-table [portfolios]="vm.items" 
                            [translationKey]="translationKey" 
                            [losses]="vm.losses"
                            [severe]="vm.severe"
                            [typical]="vm.typical">
                            </dtcor-portfolio-common-table>
                        </div>
                    </div>
                </div>
                <div e2eItem="tab-table-content" class="col-12 mt-2" *ngIf="!vm.isChartSelected">
                    <div class="row">
                        <div class="col-md-12 scroll-x">
                            <table e2eItem="table-data" class="main-table" summary="table">
                                <tr>
                                    <th id="header1" class="no-border"></th>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <th class="p-0" [ngClass]="{'selected-th-bl':structure.isSelected, 'no-border':!structure.isSelected}">
                                            <div [ngClass]="{'selected-portfolio selected-text-container-b':structure.isSelected}"
                                                class="selected-text-container">
                                                <span *ngIf="structure.isSelected">Selected</span>
                                            </div>
                                        </th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <th id="header1"></th>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <th [ngClass]="{'selected-portfolio selected-th-bt':structure.isSelected}">
                                            <dtcor-portfolio-context-menu [isSelectVisible]="showSelect(structure)"
                                                [isEditVisible]="showEdit(structure)" [isRemoveVisible]="showRemove(structure)"
                                                [isViewVisible]="structure.isCurrent" [translationKey]="translationKey"
                                                [name]="getLabel(structure)" (eventSelect)="select(structure)"
                                                (eventEdit)="view(structure)" (eventDelete)="delete(structure)">
                                            </dtcor-portfolio-context-menu>
                                        </th>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        {{translationKey + '.RETAINED_MEAN' | translate}}
                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            {{structure.retained | shortNumberFormat}}
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        {{translationKey + '.PREMIUM' | translate}}
                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            {{structure.premium | shortNumberFormat}}
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        {{translationKey + '.COST_OF_VOLATILITY' | translate}}
                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            {{structure.costOfVolatility | shortNumberFormat}}
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        {{translationKey + '.CCOR' | translate}}

                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            {{structure.ccor | shortNumberFormat}}
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <td class="row-header">
                                        {{translationKey + '.VALUE_FROM_INSURANCE' | translate}}
                                    </td>
                                    <ng-container *ngFor="let structure of vm.items">
                                        <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                                            {{structure.valueFromInsurance | shortNumberFormat}}
                                        </td>
                                    </ng-container>
                                </tr>
                            </table>
                            <dtcor-portfolio-common-table [portfolios]="vm.items" 
                            [translationKey]="translationKey"
                            [losses]="vm.losses"
                            [severe]="vm.severe"
                            [typical]="vm.typical">
                            </dtcor-portfolio-common-table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row mt-4">
                <div class="col-12 mt-2">
                    <h3 class="fs-16" [innerHTML]="translationKey + '.METRIC_ANALYSIS.TITLE' | translate"></h3>
                    <div class="sub-header-text" [innerHTML]="translationKey + '.METRIC_ANALYSIS.CONTENT' | translate">
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <div class="tab selected" [innerHTML]="translationKey + '.METRIC_ANALYSIS.TABS.LOSS' | translate">
                    </div>
                    <div class="tab" [innerHTML]="translationKey + '.METRIC_ANALYSIS.TABS.CHANGE' | translate"></div>
                    <div class="tab" [innerHTML]="translationKey + '.METRIC_ANALYSIS.TABS.ABSOLUTE_VALUE' | translate">
                    </div>
                </div>
                <div class="col-8 m-4">
                    <dtcor-table [table]="vm.metricAnalysisVm.table" [sortColumnIndex]="vm.metricAnalysisVm.sortColumnIndex"
                        (sort)="sortMatricAnalysisTable($event)"></dtcor-table>
                </div>
            </div> -->
        </div>
    </div>
</div>