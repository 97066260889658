import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExecutiveSummaryService } from '../../executive-summary.service';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { ProgramStructuresColumnBreakDown, ProgramStructuresTableRow } from 'app/api/generated/ExecutiveSummaryViewModel';

@Component({
    selector: 'dtcor-program-structure',
    templateUrl: './program-structure.component.html',
    styleUrls: ['./program-structure.component.scss']
})
export class ProgramStructureComponent implements OnInit {

    public translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PROGRAM_STRUCTURES';

    subColumns: string[] = ['Work Retention', 'Captive Retention', 'Uninusured excess'];

    viewModel;

    totalRetainedDetailViewActive: boolean = false;
    totalTransferredDetailViewActive: boolean = false;

    tableExpandedColsWidths: any = {
        single: '1290px',
        double: '1800px'
    };

    constructor(private executiveSummaryService: ExecutiveSummaryService,
        private translation: TranslateService) {
        // empty block
    }

    ngOnInit(): void {
        this.viewModel = combineLatest([
            this.executiveSummaryService.severePercentileChanged,
            this.executiveSummaryService.typicalPercentileChanged,
            this.executiveSummaryService.executiveSummaryViewModel
        ]).pipe(map(([serverPercentile, typicalPercentile, executiveSummary]) => {
            return {
                rows: executiveSummary.programStructures.table,
                wrapperWidth: this.getWrapperWidth(executiveSummary.programStructures.table),
                content: this.translation.instant(this.translationKey + '.CONTENT', {
                    ['selectedTypicalYearPercentile']: typicalPercentile.percentage,
                    ['typicalYearPercentage']: this.formatNumber(typicalPercentile.retainedPercentage),
                    ['severYearPercentage']: this.formatNumber(serverPercentile.retainedPercentage),
                    // eslint-disable-next-line max-len
                    ['selectedSevereYearPercentile']: (serverPercentile.percentage ? `${serverPercentile.percentage}th` : serverPercentile.label),
                    ['changeVariation']: serverPercentile.retainedPercentage > typicalPercentile.retainedPercentage ?
                        this.translation.instant(this.translationKey + '.CHANGE_INCREASES') : this.translation.instant(this.translationKey + '.CHANGE_DECREASES')
                })
            };
        }));
    }


    getWrapperWidth(records: ProgramStructuresTableRow[]) {
        const record = records[0];
        let width = 0;
        if (this.totalRetainedDetailViewActive) {
            width += parseInt(this.getWidth(record.retainedBreakDown), 0);
        }

        if (this.totalTransferredDetailViewActive) {
            width += parseInt(this.getWidth(record.transferredBreakDown), 0);
        }

        return `${width + 800}px`;
    }

    getWidth(breakdown: ProgramStructuresColumnBreakDown[]) {
        const subColWidth = 115;
        return `${breakdown.length * subColWidth}px`;
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    toggleTotalRetainedDetailView() {
        this.totalRetainedDetailViewActive = !this.totalRetainedDetailViewActive;
    }

    toggleTotalTransferredDetailView() {
        this.totalTransferredDetailViewActive = !this.totalTransferredDetailViewActive;
    }

    get expanded(): boolean {
        return this.totalRetainedDetailViewActive || this.totalTransferredDetailViewActive;
    }

    get bothExpanded(): boolean {
        return this.totalRetainedDetailViewActive && this.totalTransferredDetailViewActive;
    }

    enableScrollbar(): string {
        if (this.expanded) {
            if (this.bothExpanded) {
                return (parseInt(this.tableExpandedColsWidths.double, 10) < window.innerWidth) ? 'hidden' : 'auto';
            }
            return (parseInt(this.tableExpandedColsWidths.single, 10) < window.innerWidth) ? 'hidden' : 'auto';
        }
        return 'hidden';
    }

    showExpandTotalRetainedBreakdown(rows: ProgramStructuresTableRow[]) {
        return rows.length > 0 && rows[0].retainedBreakDown.length > 0;
    }

    showExpandTotalTrasferredBreakdown(rows: ProgramStructuresTableRow[]) {
        return rows.length > 0 && rows[0].transferredBreakDown.length > 0;
    }

    suffix(value) {
        return value + 'th';
    }
}
