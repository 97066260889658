import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { isNil }from 'lodash';

@Pipe({ name: 'wtwNumber' })
export class WtwNumberPipe implements PipeTransform {
    private _decimal: DecimalPipe;
    constructor(@Inject(LOCALE_ID) private locale: string) { this._decimal = new DecimalPipe(this.locale); }

    transform(value: number): any {

        if (!isNil(value)) {

            if (value >= 10) {
                return  this._decimal.transform(value, `1.0-0`);
            }

            return  this._decimal.transform(value, `1.0-2`);
        }

        return value;
    }
}