<div class="row" e2eGroup="executive-summary-gross-losses">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <ng-container *ngIf="viewModel | async as vm">
                    <div class="col-md-8">
                        <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                        <div e2eItem="page-text" class="tabs-text" [innerHTML]="vm.headerContent"></div>
                        <div class="text-right my-2">
                            <ul class="switch-tabs">
                                <li e2eItem="tab-financial" (click)="onSwitchChange(switch.financial)"
                                    [ngClass]="{ 'active': vm.selectedDisplayType === switch.financial }">{{
                                    translationKey + '.SWITCH.FINANCIAL' | translate }}</li>
                                <li e2eItem="tab-percentage" (click)="onSwitchChange(switch.percentage)"
                                    [ngClass]="{ 'active': vm.selectedDisplayType === switch.percentage }">{{
                                    translationKey + '.SWITCH.PERCENTAGE' | translate }}</li>
                            </ul>
                        </div>
                        <div e2eItem="ty-heading" class="font-weight-bold" [innerHTML]="vm.typicalYearHeading"></div>
                        <div e2eItem="ty-text" class="tabs-text" [innerHTML]="vm.typicalYear.content"></div>
                        <div>
                            <dtcor-single-stacked-bar-chart [left]="vm.typicalYear.left" [right]="vm.typicalYear.right"
                                [translationKey]="translationKey" [widthPercentage]="vm.typicalYear.widthPercentage"
                                [total]="vm.typicalYear.total" [displayType]="vm.selectedDisplayType">
                            </dtcor-single-stacked-bar-chart>
                        </div>
                        <br />
                        <div e2eItem="sy-heading" class="font-weight-bold" [innerHTML]="vm.severeYearHeading"></div>
                        <div e2eItem="sy-text" class="tabs-text" [innerHTML]="vm.severeYear.content"></div>
                        <div>
                            <dtcor-single-stacked-bar-chart [left]="vm.severeYear.left" [right]="vm.severeYear.right"
                                [displayType]="vm.selectedDisplayType" [total]="vm.severeYear.total"
                                [translationKey]="translationKey"></dtcor-single-stacked-bar-chart>
                        </div>
                        <div class="mt-3">
                            <a e2eItem="btn-see-more" class="cursor-pointer text-wtw-primary text-underline fs-14"
                                (click)="seeMore.isActive = !seeMore.isActive;">
                                {{ (seeMore.isActive ? seeMore.lessText : seeMore.moreText) }}
                            </a>
                            <div *ngIf="seeMore.isActive" class="table-wrapper">
                                <p class="font-weight-bold fs-16 mt-4 mb-1" [innerHTML]="(translationKey + '.GROSS_LOSS_BY_RISK' | translate)"></p>
                                <p class="tabs-text">
                                    <span [innerHTML]="vm.seeMoreStatement.p1"></span>
                                    <span class="px-1"><a (click)="riskClicked(vm.seeMoreStatement.typicalRisk)" class="risk">{{ vm.seeMoreStatement.typicalRisk }}</a></span>
                                    <span [innerHTML]="vm.seeMoreStatement.p2"></span>
                                    <span class="px-1"><a (click)="riskClicked(vm.seeMoreStatement.severeRisk)" class="risk">{{ vm.seeMoreStatement.severeRisk }}</a></span>
                                    <span [innerHTML]="vm.seeMoreStatement.p3"></span>
                                </p>
                                <table e2eItem="table-see-more" class="table table-auto border-left-0 border-right-0 text-right" summary="structure">                                                                    
                                        <th class="font-weight-bold px-0" id="header-return-period" [innerHTML]="translationKey + '.TABLE.COLUMNS.RETURN_PERIOD' | translate"></th>
                                        <th class="font-weight-bold" id="header-percentile" [innerHTML]="translationKey + '.TABLE.COLUMNS.PERCENTILE' | translate"></th>
                                        <th class="font-weight-bold" id="header-percentile" [innerHTML]="translationKey + '.TABLE.COLUMNS.ALL_RISKS' | translate"></th>
                                        <th class="font-weight-bold" *ngFor="let column of vm.risks.data.columns" id="header-{{column.name}}">{{ column.name }}</th>                                   
                                    <tr *ngFor="let row of vm.risks.data.rows">
                                        <td class="px-0" *ngIf="!!row.returnPeriod.value">{{ row.returnPeriod.value }}</td>
                                        <td *ngIf="!row.returnPeriod.value"></td>
                                        <td *ngIf="!!row.percentile.value">{{ row.percentile.value }}th</td>
                                        <td *ngIf="!row.percentile.value" class="font-weight-bold">{{row.percentile.label | titlecase}}</td>
                                        <td [ngClass]="{ 'font-weight-bold': !row.percentile.value }">{{ row.allRisks?.value | shortNumberFormat }}</td>
                                        <td *ngFor="let column of vm.risks.data.columns" [ngClass]="{'font-weight-bold':!row.percentile.value}">{{ row[column.id].value | shortNumberFormat }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div>
                            <h3 class="fs-24" [innerHTML]="translationKey + '.TOP_RISKS_DRIVERS.TITLE' | translate"></h3>
                            <div class="typical-year">
                                <p class="font-weight-bold fs-16 mt-4 mb-1" [innerHTML]="(translationKey + '.TOP_RISKS_DRIVERS.TYPICAL_YEAR.TITLE' | translate)"></p>
                                <div class="chart">
                                    <highcharts-chart [Highcharts]="Highcharts" [options]="vm.typicalYearRiskDrivers"
                                        style="width: 100%; height: 300px; display: block;">
                                    </highcharts-chart>
                                </div>
                            </div>
                            <div class="sever-year">
                                <p class="font-weight-bold fs-16 mt-4 mb-1" [innerHTML]="(translationKey + '.TOP_RISKS_DRIVERS.SEVERE_YEAR.TITLE' | translate)"></p>
                                <div class="chart">
                                    <highcharts-chart [Highcharts]="Highcharts" [options]="vm.severYearRiskDrivers"
                                        style="width: 100%; height: 300px; display: block;">
                                    </highcharts-chart>
                                </div>
                            </div>
                            <div>
                                <ul class="parliament-chart-legend">
                                    <li *ngFor="let data of vm.severYearRiskDrivers.series[0].data">
                                        <span class="color" [style.background]="data[2]"></span>
                                        <span>{{data[0]}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>