export class MapDefinitions {

    static MarkerPointColour: number[] = [51, 51, 51, 0.65];
    static MarkerTextColour: Array<number> = [255, 255, 255, 1];
    static MapLegendContainer: 'mapLayerLegendContainer';
    static WTWLegendContainer: 'hazardLayerLegendContainer';
    static SupplyChainHomeWidgetContainer = 'homeWidgetContainer';
    static SupplyChainZoomWidgetContainer = 'zoomWidgetContainer';
    static SupplyChainSearchWidgetContainer = 'searchWidgetContainer';
    static SupplyChainBasemapContainer = 'basemapContainer';
    static SupplyChainHomeWidgetId = 'supplychain-home';
    static SupplyChainZoomWidgetId = 'supplychain-zoom';
    static SupplyChainNextToggleBasemap = 'hybrid';
    static SupplyChainClutserToggleButtonId = 'toggle-cluster-supplychain';
    static SupplyChainWidgetsAnimation = '150ms ease 0s normal forwards 1 fadein';
    static SupplyChainWidgetsMarginRight = '358px';

    static GpdHomeWidgetContainer = 'gpdHomeWidgetContainer';
    static GpdZoomWidgetContainer = 'gpdZoomWidgetContainer';
    static GpdHomeWidgetId = 'gpd-home';
    static GpdZoomWidgetId = 'gpd-zoom';
    static GpdClutserToggleButtonId = 'toggle-cluster-gpd';

    static GpdSizeVariable = {
        type: 'size',
        field: 'TIV',
        minSize: 30,
        maxSize: 30,
        minDataValue: 0,
        maxDataValue: 0
    };

    static SupplyChainSizeVariable = {
        type: 'size',
        field: 'TIV',
        // minSize: 6,
        // maxSize: 40,
        minDataValue: 0,
        maxDataValue: 0
    };

    static locSizeVar = {
        type: 'size',
        field: 'TIV',
        minSize: 6,
        maxSize: 30
    };

    static TIVOpacityVar = {
        type: 'opacity',
        field: 'TIV',
        legendOptions: {
            showLegend: true
        }
    };

    static ClusterOpacityVar = {
        type: 'opacity',
        field: 'TIV',
        legendOptions: {
            showLegend: false
        }
    };

    static FiresizeVar = {
        type: 'size',
        valueExpression: '$view.scale',
        stops: [
            {
                size: 35,
                value: 1000000
            }, {
                size: 25,
                value: 5000000
            }, {
                size: 15,
                value: 10000000
            }, {
                size: 10,
                value: 50000000
            }]
    };

    static GpdClusterLayerFields = [
        { name: 'ObjectID', type: 'oid' },
        { name: 'country', type: 'string' },
        { name: 'countryName', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'geoRes', type: 'string' },
        { name: 'hazard_COASTALFLOOD', type: 'integer' },
        { name: 'hazard_EARTHQUAKE', type: 'integer' },
        { name: 'hazard_EXTRATROPICALSTORM', type: 'integer' },
        { name: 'hazard_FLASHFLOOD', type: 'integer' },
        { name: 'hazard_HAILSTORM', type: 'integer' },
        { name: 'hazard_LIGHTNING', type: 'integer' },
        { name: 'hazard_RIVERFLOOD', type: 'integer' },
        { name: 'hazard_TORNADO', type: 'integer' },
        { name: 'hazard_TROPICALCYCLONE', type: 'integer' },
        { name: 'hazard_TSUNAMI', type: 'integer' },
        { name: 'hazard_VOLCANO', type: 'integer' },
        { name: 'hazard_WILDFIRE', type: 'integer' },
        { name: 'latitude', type: 'double' },
        { name: 'longitude', type: 'double' },
        { name: 'mpr', type: 'double' },
        { name: 'tmpr', type: 'double' },
        { name: 'region', type: 'string' },
        { name: 'hazard_TERRORISM', type: 'integer' },
        { name: 'tiv', type: 'double' },
        { name: 'address', type: 'string' },
        { name: 'city', type: 'string' },
        { name: 'postcode', type: 'string' },
        { name: 'hazard_COASTALFLOOD_Label', type: 'string' },
        { name: 'hazard_EARTHQUAKE_Label', type: 'string' },
        { name: 'hazard_EXTRATROPICALSTORM_Label', type: 'string' },
        { name: 'hazard_FLASHFLOOD_Label', type: 'string' },
        { name: 'hazard_HAILSTORM_Label', type: 'string' },
        { name: 'hazard_LIGHTNING_Label', type: 'string' },
        { name: 'hazard_RIVERFLOOD_Label', type: 'string' },
        { name: 'hazard_TORNADO_Label', type: 'string' },
        { name: 'hazard_TROPICALCYCLONE_Label', type: 'string' },
        { name: 'hazard_TSUNAMI_Label', type: 'string' },
        { name: 'hazard_VOLCANO_Label', type: 'string' },
        { name: 'hazard_WILDFIRE_Label', type: 'string' },
        { name: 'hazard_TERRORISM_Label', type: 'string' },
        { name: 'page1Header', type: 'string' },
        { name: 'addressLabel', type: 'string' },
        { name: 'tivLabel', type: 'string' },
        { name: 'latitudeLabel', type: 'string' },
        { name: 'longitudeLabel', type: 'string' },
        { name: 'natcatScoreLabel', type: 'string' },
        { name: 'terrorismScoreLabel', type: 'string' },
        { name: 'resolutionLabel', type: 'string' }
    ];

    static GpdClusterRenderer = {
        type: 'simple',
        symbol: {
            type: 'simple-marker',
            size: 30,
            color: [112, 32, 130, 1],
            outline: {
                style: 'dash',
                color: [112, 32, 130, 0.5],
                width: 10
            }
        },
        visualVariables: [MapDefinitions.GpdSizeVariable]
    };

    static NodeLayerFields = [{
        name: 'LocationName',
        type: 'string'
    }, {
        name: 'NodeID',
        type: 'oid'
    }, {
        name: 'Address',
        type: 'string'
    }, {
        name: 'City',
        type: 'string'
    }, {
        name: 'State',
        type: 'string'
    }, {
        name: 'Country',
        type: 'string'
    }, {
        name: 'InEx',
        type: 'string'
    }, {
        name: 'TIV',
        type: 'double'
    }, {
        name: 'OC',
        type: 'string'
    }, {
        name: 'BV',
        type: 'double'
    }, {
        name: 'CV',
        type: 'double'
    }, {
        name: 'BI',
        type: 'double'
    }];

    static RelationLayerFields = [{
        name: 'Relationship',
        type: 'string'
    }, {
        name: 'LineID',
        type: 'oid'
    }, {
        name: 'FromNodeID',
        type: 'integer'
    }, {
        name: 'ToNodeID',
        type: 'integer'
    }, {
        name: 'SourceTiv',
        type: 'double'
    }, {
        name: 'DestinationTiv',
        type: 'double'
    }, {
        name: 'SourceType',
        type: 'string'
    }, {
        name: 'DestinationType',
        type: 'string'
    }];

    static IntExtRenderer = {
        type: 'unique-value',
        field: 'InEx',
        defaultSymbol: {
            type: 'simple-marker',
            size: 30,
            color: [186, 193, 184, 1],
            outline: {
                style: 'dash',
                color: [186, 193, 184, 0.5],
                width: 10
            }
        },
        defaultLabel: 'Not Specified',
        uniqueValueInfos: [{
            value: 'Internal',
            symbol: {
                type: 'simple-marker',
                size: 30,
                color: [214, 81, 8, 1],
                outline: {
                    style: 'dash',
                    color: [214, 81, 8, 0.5],
                    width: 10
                }
            }
        }, {
            value: 'External',
            symbol: {
                type: 'simple-marker',
                size: 30,
                color: [240, 182, 127, 1],
                outline: {
                    style: 'dash',
                    color: [240, 182, 127, 0.5],
                    width: 10
                }
            }
        }],
        visualVariables: [MapDefinitions.SupplyChainSizeVariable]
    };

    static fireflySymbolIntExt = {
        type: 'unique-value',
        field: 'InEx',
        defaultSymbol: {
            type: 'picture-marker',
            url: 'https://static.arcgis.com/images/Symbols/Firefly/FireflyA19.png',
            width: '24px',
            height: '24px'
        },
        defaultLabel: 'Not Specified',
        uniqueValueInfos: [{
            value: 'Internal',
            symbol: {
                type: 'picture-marker',
                url: 'https://static.arcgis.com/images/Symbols/Firefly/FireflyC19.png',
                width: '24px',
                height: '24px'

            }
        }, {
            value: 'External',
            symbol: {
                type: 'picture-marker',
                url: 'https://static.arcgis.com/images/Symbols/Firefly/FireflyB19.png',
                width: '24px',
                height: '24px'

            }
        }]
    };

    static fireflySymbol = {
        type: 'simple',
        symbol: {
            type: 'picture-marker',
            url: 'https://static.arcgis.com/images/Symbols/Firefly/FireflyC7.png',
            width: '24px',
            height: '24px'
        }
    };

    static exposureDiagnosticFireflySymbol = {
        type: 'simple',
        symbol: {
            type: 'picture-marker',
            url: 'https://static.arcgis.com/images/Symbols/Firefly/FireflyE4.png',
            width: '24px',
            height: '24px'
        }
    };

    static ClusterNodeRenderer = {
        type: 'simple',
        field: 'TIV',
        symbol: {
            type: 'simple-marker',
            size: 30,
            color: [0, 158, 206, 1],
            outline: {
                color: [0, 158, 206, 0.5],
                width: 10
            }
        },
        visualVariables: [MapDefinitions.SupplyChainSizeVariable]
    };

    static RelationshipLineSymbol = {
        type: 'simple-line',
        color: [210, 148, 235, 1],
        width: 1.5,
        style: 'short-dash-dot'
    };

    static RelationshipLineSymbolTop5 = {
        type: 'simple-line',
        color: [255, 25, 71, 1],
        width: 1.5,
        style: 'short-dash-dot'
    };

    static nodeLabelInfo = {
        symbol: {
            type: 'text',
            color: 'white',
            font: {
                family: 'Arial',
                size: 10,
                weight: 'bold'
            }
        },
        deconflictionStrategy: 'none',
        labelPlacement: 'center-center',
        labelExpressionInfo: {
            expression: `
                var tIV = Number($feature.TIV);

				if(tIV >=1000){
					if(tIV >= 1e12) {
						var abbr = 'T';
						var rounded = tIV / 1e12
					}
					else if(tIV >= 1e9) {
						var abbr = 'B';
						var rounded = tIV / 1e9
					}
					else if(tIV >= 1e6) {
						var abbr = 'M';
						var rounded = tIV / 1e6
					}
					else if(tIV >= 1e3) {
						var abbr = 'K';
						var rounded = tIV / 1e3
					};
					return Text(round(rounded,1))+abbr;
				}
				`
        }
    };

    static clusterLabelInfo = {
        symbol: {
            type: 'text',
            color: 'white',
            font: {
                family: 'Arial',
                size: 10,
                weight: 'bold'
            }
        },
        deconflictionStrategy: 'none',
        labelPlacement: 'center-center',
        labelExpressionInfo: {
            expression: `
				var avgTIV = $feature.cluster_avg_TIV;
				var cCount = $feature.cluster_count;
                var sTIV = Number(avgTIV*cCount);

				if(sTIV>= 1000){
					if(sTIV >= 1e12) {
						var abbr = 'T';
						var rounded = sTIV / 1e12
					}
					else if(sTIV >= 1e9) {
						var abbr = 'B';
						var rounded = sTIV / 1e9
					}
					else if(sTIV >= 1e6) {
						var abbr = 'M';
						var rounded = sTIV / 1e6
					}
					else if(sTIV >= 1e3) {
						var abbr = 'K';
						var rounded = sTIV / 1e3
					};
					return Text(round(rounded,1))+abbr;
				}
				`
        }
    };

    static BasicPopupHtml(): string {
        return '<div style="font-size:15px;text-align:center">{facility}</div></br/>';
    }

    static ContinentDefinitions(): Array<Continent> {

        return Array<Continent>(
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('AF', 'Africa', 9.78, 21.10),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('EU', 'Europe', 49.83, 8.44),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('AS', 'Asia', 22.91, 109.34),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('AU', 'Oceania', -24.68, 134.12),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('NA', 'North America', 37.70, -99.83),
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            new Continent('SA', 'South America', -17.98, -56.94)
        );
    }

    static GetColourFromMPR(mpr: number) {

        let result: number[];

        switch (Math.round(mpr)) {
            case 0: {
                result = [204, 204, 204, 0.9];
                break;
            }
            case 1: {
                result = [0, 147, 0, 0.9];
                break;
            }
            case 2: {
                result = [100, 199, 100, 0.9];
                break;
            }
            case 3: {
                result = [255, 255, 153, 0.9];
                break;
            }
            case 4: {
                result = [255, 163, 25, 0.9];
                break;
            }
            default: {
                result = [255, 0, 0, 0.9];
                break;
            }
        }
        return result;

    }

    static GetGpdSingleLocationPopup(feature): string {
        return '<div class="map-popup">' +
            '<div>' +
            '<div id="page1" class="mp-p1">' +
            '<div class="mp-title">' +
            feature.graphic.attributes.page1Header +
            '</div>' +
            '<table>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_LIGHTNING_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_LIGHTNING + '">' +
                feature.graphic.attributes.hazard_LIGHTNING + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_HAILSTORM_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_HAILSTORM + '">' +
                feature.graphic.attributes.hazard_HAILSTORM + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_FLASHFLOOD_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_FLASHFLOOD + '">' +
                feature.graphic.attributes.hazard_FLASHFLOOD + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_COASTALFLOOD_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_COASTALFLOOD + '">' +
                feature.graphic.attributes.hazard_COASTALFLOOD + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_EARTHQUAKE_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_EARTHQUAKE + '">' +
                feature.graphic.attributes.hazard_EARTHQUAKE + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_EXTRATROPICALSTORM_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_EXTRATROPICALSTORM + '">' +
                feature.graphic.attributes.hazard_EXTRATROPICALSTORM + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_TROPICALCYCLONE_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_TROPICALCYCLONE + '">' +
                feature.graphic.attributes.hazard_TROPICALCYCLONE + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_TSUNAMI_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_TSUNAMI + '">' +
                feature.graphic.attributes.hazard_TSUNAMI + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_WILDFIRE_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_WILDFIRE + '">' +
                feature.graphic.attributes.hazard_WILDFIRE + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_TORNADO_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_TORNADO + '">' +
                feature.graphic.attributes.hazard_TORNADO + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_RIVERFLOOD_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_RIVERFLOOD + '">' +
                feature.graphic.attributes.hazard_RIVERFLOOD + '</td>' +
            '</tr>' +
            '<tr>' +
            '<td>' + feature.graphic.attributes.hazard_TERRORISM_Label + '</td>' +
            '<td class="mp-c' + feature.graphic.attributes.hazard_TERRORISM + '">' +
                feature.graphic.attributes.hazard_TERRORISM + '</td>' +
            '</tr>' +
            '</table>' +
            '</div>' +
            '<div id="page2" class="mp-p2 d-none">' +
            '<div class="mp-title">' +
            feature.graphic.attributes.facility +
            '</div>' +
            '<div class="address-section">' +
            '<div class="mp-address">' +
            '<div class="mp-left-col">' + feature.graphic.attributes.addressLabel + '</div>' +
            '<div class="mp-right-col">' +
            '<div>' + feature.graphic.attributes.address + '</div>' +
            '<div></div>' +
            '<div>' + feature.graphic.attributes.city + '</div>' +
            '<div>' + feature.graphic.attributes.country + '</div>' +
            '<div>' + feature.graphic.attributes.postcode + '</div>' +
            '</div>' +
            '</div>' +
            '<div class="float-left address-total">' +
            feature.graphic.attributes.tivLabel + ' ' + feature.graphic.attributes.tiv +
            '</div>' +
            '<div class="mp-address mp-address-border">' +
            '<div>' +
            '<div class="mp-left-col">' +
            feature.graphic.attributes.latitudeLabel +
            '</div>' +
            '<div class="mp-right-col">' +
            feature.graphic.attributes.latitude +
            '</div>' +
            '</div>' +
            '<div>' +
            '<div class="mp-left-col">' +
            feature.graphic.attributes.longitudeLabel +
            '</div>' +
            '<div class="mp-right-col">' +
            feature.graphic.attributes.longitude +
            '</div>' +
            '</div>' +
            '<div class="w-100 float-left">' +
            '<div class="mp-left-col">' +
            feature.graphic.attributes.resolutionLabel +
            '</div>' +
            '<div class="mp-right-col">' +
            feature.graphic.attributes.geoRes +
            '</div>' +
            '</div>' +
            '<div>' +
            '<div class="mp-left-col">' +
            feature.graphic.attributes.natcatScoreLabel +
            '</div>' +
            '<div  class="mp-right-col">' +
            feature.graphic.attributes.mpr +
            '</div>' +
            '</div>' +
            '<div>' +
            '<div class="mp-left-col">' +
            feature.graphic.attributes.terrorismScoreLabel +
            '</div>' +
            '<div class="mp-right-col">' +
            feature.graphic.attributes.tmpr +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<div class="map-popup-pag align-content-center  float-left">' +
            '<div id="page1Pager" class="mpp-p1 float-left d-selected" ></div>' +
            '<div id="page2Pager" class="mpp-p2 float-left" ></div>' +
            '</div>' +
            '</div>' +
            '</div>';
    }

    static GetGpdClusterPopup(feature): string {
        return '<div class="map-popup">' +
            '<div>' +
            '<div id="page1" class="mp-p1">' +
            '<div class="mp-title">' +
            feature.graphic.attributes.header +
            '</div> <div class="cluster-text"><div>' +
            feature.graphic.attributes.countLabel +
            '</br></br></div> <div>' +
            feature.graphic.attributes.tivAverageLabel +
            '</div></div>';
    }

    static Covid19PopupHtml(labels: { location: string, activeCases: string, incidentRate: string }, locations: string[],
        activeCases: string, incidentRate: string): string {
        return `<div class="map-popup">
                    <div id="page1" class="mp-p1">
                        <div class="covid19-popup-text-container">
                            ${labels.location} ${locations.join(', ')}<br/>
                            ${labels.activeCases} ${activeCases ? activeCases : 'N/A'}<br/>
                            ${labels.incidentRate} ${incidentRate ? incidentRate : 'N/A'}
                        </div>
                    </div>
                </div>`;
    }
}

export class Continent {
    constructor(public id: string, public name: string, public y: number, public x: number) { }
}
