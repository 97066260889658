<div class="row" e2eGroup="executive-summary-premium-expenses">
    <div class="col-md-12">
        <ng-container *ngIf="viewModel | async as vm">
            <div class="pt-4">
                <div class="row">
                    <div class="col-md-8">
                        <h3 e2eItem="page-heading" class="fs-24"
                            [innerHTML]="translationKey + (vm.hasExpenseKPI ? '.HEADING' : '.PREMIUM_HEADING') | translate">
                        </h3>
                        <div e2eItem="page-text" class="tabs-text" [innerHTML]="vm.statement"></div>
                        <div class="row">
                            <div class="col-12">
                                <div e2eItem="ty-heading" class="font-weight-bold" [innerHTML]="vm.typicalYearHeading">
                                </div>
                                <dtcor-single-stacked-bar-chart [left]="vm.typicalYear.left"
                                    [right]="vm.typicalYear.right" [translationKey]="translationKey"
                                    [widthPercentage]="vm.typicalYear.widthPercentage" [total]="vm.typicalYear.total"
                                    [displayType]="vm.selectedDisplayType"
                                    [showTotalKey]="false" [showValuesAsPercentage]="true"
                                    [showTotal]="vm.typicalYear.showTotal">
                                </dtcor-single-stacked-bar-chart>
                            </div>
                            <div class="col-12">
                                <div e2eItem="sy-heading" class="font-weight-bold" [innerHTML]="vm.severeYearHeading">
                                </div>
                                <dtcor-single-stacked-bar-chart [left]="vm.severeYear.left"
                                    [right]="vm.severeYear.right" [displayType]="vm.selectedDisplayType"
                                    [total]="vm.severeYear.total" [translationKey]="translationKey"
                                    [showTotalKey]="false" [showValuesAsPercentage]="true"
                                    [showTotal]="vm.severeYear.showTotal">
                                </dtcor-single-stacked-bar-chart>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <highcharts-chart [Highcharts]="Highcharts" [options]="vm.topPremiumRisksDriversOptions"
                            style="width: 100%; height: 300px; display: block;">
                        </highcharts-chart>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3">
                            <tabset>
                                <tab [active]="true">
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-market">
                                            {{ translationKey + '.TABS.MARKET.TITLE' | translate | translate }}
                                        </span>
                                    </ng-template>
                                    <p class="tabs-text mt-2 mb-2" [innerHTML]="vm.marketText"></p>
                                    <div e2eItem="market-table">
                                        <dtcor-table [table]="marketVm.table"
                                            [sortColumnIndex]="marketVm.sortColumnIndex"
                                            (sort)="sortMarketTable($event)" (riskSelected)="riskClicked($event)">
                                        </dtcor-table>
                                    </div>
                                    <a e2eItem="see-more" class="cursor-pointer text-wtw-primary text-underline fs-14"
                                        (click)="seeMore.isActive = !seeMore.isActive;"
                                        *ngIf="showSeeMore(seeMore.hasAccess,vm.technicalPricingMarketViewModel.visible)">
                                        {{ (seeMore.isActive ? seeMore.lessText : seeMore.moreText) }}
                                    </a>
                                    <a e2eItem="see-more-no-access"
                                        class="cursor-pointer text-wtw-primary fs-14 see-more"
                                        *ngIf="!seeMore.hasAccess" (click)="showPermissionModal()">
                                        <img alt="lock" class="mr-1" [src]="'/assets/images/lock.svg'" />
                                        {{ seeMore.moreText }}
                                    </a>
                                    <div e2eItem="market-technical-pricing"
                                        *ngIf="vm.technicalPricingMarketViewModel && vm.technicalPricingMarketViewModel.visible && seeMore.isActive"
                                        class="mt-5">
                                        <div class="chartContainer" #marketChartContainer>
                                            <highcharts-chart [Highcharts]="Highcharts" (chartInstance)="marketChartInstance($event)"
                                                [options]="vm.technicalPricingMarketViewModel.options"
                                                (window:resize)="marketChartResize($event)"
                                                style="width: 100%; height: 600px; display: block;">
                                            </highcharts-chart>
                                        </div>
                                        <div class="mt-3">
                                            <ul class="list">
                                                <li
                                                    *ngFor="let statement of vm.technicalPricingMarketViewModel.statements">
                                                    <a (click)="riskClicked(statement.riskType)" class="risk">
                                                        {{statement.riskType}}
                                                    </a>
                                                    <span [innerHTML]="statement.text"></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </tab>
                                <tab *ngIf="!!vm.showCaptive">
                                    <ng-template tabHeading>
                                        <span e2eItem="tab-captive">
                                            {{ translationKey + '.TABS.CAPTIVE.TITLE' | translate | translate }}
                                        </span>
                                    </ng-template>
                                    <p class="tabs-text mt-2 mb-2" [innerHTML]="vm.captiveText"></p>
                                    <div e2eItem="captive-table">
                                        <dtcor-table [table]="captiveVm.table"
                                            [sortColumnIndex]="captiveVm.sortColumnIndex"
                                            (sort)="sortCaptiveTable($event)" (riskSelected)="riskClicked($event)">
                                        </dtcor-table>
                                    </div>
                                    <a e2eItem="see-more" class="cursor-pointer text-wtw-primary text-underline fs-14"
                                        (click)="seeMore.isActive = !seeMore.isActive;"
                                        *ngIf="showSeeMore(seeMore.hasAccess,vm.technicalPricingCaptiveViewModel.visible)">
                                        {{ (seeMore.isActive ? seeMore.lessText : seeMore.moreText) }}
                                    </a>
                                    <a e2eItem="see-more-no-access"
                                        class="cursor-pointer text-wtw-primary fs-14 see-more"
                                        *ngIf="!seeMore.hasAccess" (click)="showPermissionModal()">
                                        <img alt="lock" class="mr-1" [src]="'/assets/images/lock.svg'" />
                                        {{ seeMore.moreText }}
                                    </a>
                                    <div e2eItem="captive-technical-pricing"
                                        *ngIf="vm.technicalPricingCaptiveViewModel && vm.technicalPricingCaptiveViewModel.visible && seeMore.isActive"
                                        class="mt-5">
                                        <div class="chartContainer" #captiveChartContainer>
                                            <highcharts-chart [Highcharts]="Highcharts" (chartInstance)="captiveChartInstance($event)"
                                            (window:resize)="captiveChartResize($event)"
                                                [options]="vm.technicalPricingCaptiveViewModel.options"
                                                style="width: 100%; height: 600px; display: block;">
                                            </highcharts-chart>
                                        </div>
                                        <div class="mt-3">
                                            <ul class="list">
                                                <li
                                                    *ngFor="let statement of vm.technicalPricingCaptiveViewModel.statements">
                                                    <a (click)="riskClicked(statement.riskType)" class="risk">
                                                        {{statement.riskType}}
                                                    </a>
                                                    <span [innerHTML]="statement.text"></span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </tab>
                            </tabset>
                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>