import { Inject, Injectable } from '@angular/core';
import { RunService } from '@wtw/platform/services';
import { PortfolioComparisonItem, RunViewModel, SelectedPortfolioRiskStartegyGroup } from 'app/api/dtos';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class DtcorDataService {
  runViewModel: Observable<RunViewModel>;
  private run: any;

  constructor(@Inject(RunService) private runService: RunService,
    private _router: Router) {
    this.runViewModel = runService.activeRun
      .pipe(map(run => {
        this.run = run;
        return run.data;
      }));
  }

  selectPortfolio(portfolio: PortfolioComparisonItem, selectedPercentiles: { severe: number, typical: number }) {
    const { filePrefix, projectId, clientId } = this.getFilePrefixClientIdAndProjectId()
    const functionData = {
      runId: this.run.runId,
      projectId,
      modelManagerPayload: {
        portfolioName: portfolio.label,
        filePrefix,
        clientId,
        projectId
      }
    };
    return this.apiCall('select_portfolio_TCOR', functionData, selectedPercentiles);
  }

  deletePortfolio(portfolio: PortfolioComparisonItem, selectedPercentiles: { severe: number, typical: number }) {
    const payload = this.getAddEditPortfolioPayload(portfolio.label, [...portfolio.portfolioRiskStartegyGroups], 'delete');
    return this.apiCall('amend_portfolio_TCOR', payload, selectedPercentiles);
  }

  addEditPortfolio(portfolio: PortfolioComparisonItem, isAdd: boolean, selectedPercentiles: { severe: number, typical: number }) {
    const payload = this.getAddEditPortfolioPayload(portfolio.label, [...portfolio.portfolioRiskStartegyGroups], isAdd ? 'add' : 'edit')
    return this.apiCall('amend_portfolio_TCOR', payload, selectedPercentiles);
  }

  gotoSummarySection() {
    this._router.navigateByUrl(`run/${this.run.runId}/executivesummary`);
  }

  selectRisk(risk: string) {
    this._router.navigateByUrl(`run/${this.run.runId}/risksummary`, { state: { riskName: risk } });
  }

  private getFilePrefixClientIdAndProjectId() {
    const prefix = this.run.data.filePrefix[0] === '_' ?
      this.run.data.filePrefix.substr(1) : this.run.data.filePrefix;
    const filePrefixParts = prefix.split('_');
    return {
      filePrefix: prefix,
      clientId: filePrefixParts[1],
      projectId: filePrefixParts[2]
    };
  }

  private getAddEditPortfolioPayload(name: string, portfolioRiskStartegyGroups: SelectedPortfolioRiskStartegyGroup[], operation: string) {
    const { filePrefix, projectId, clientId } = this.getFilePrefixClientIdAndProjectId();
    const risks = portfolioRiskStartegyGroups.map(item => ({ id: item.riskId, selectedSolutionId: item.strategyGroupId }));
    return {
      projectId,
      modelManagerPayload: {
        portfolioName: name,
        filePrefix,
        clientId,
        operation,
        projectId,
        risks
      }
    };
  }

  private apiCall(functionName: string, functionData: any, selectedPercentiles: { severe: number, typical: number }) {
    return this.runService.executeAlgorithmAsync(this.run.runId,
      {
        ...this.run.data,
        executiveSummary: {
          ...this.run.data.executiveSummary,
          selectedSeverePercentileId: selectedPercentiles.severe,
          selectedTypicalPercentileId: selectedPercentiles.typical
        },
        portfolioInput:{ ...functionData, runId: this.run.runId }
      },
      functionName,
      this.run.currencyInfo, null );
  }
}
