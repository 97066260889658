import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { RiskSummaryService } from '../../risk-summary.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { HistoricalLossesVM } from 'app/api/dtos';

@Component({
  selector: 'dtcor-risk-historical-losses',
  templateUrl: './risk-historical-losses.component.html',
  styleUrls: ['./risk-historical-losses.component.scss']
})
export class RiskHistoricalLossesComponent implements OnInit {

  translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.HISTORICAL_LOSSES';
  viewModel;
  data: Observable<{ name: string, loss: any[] }[]>;
  isChartSelected: Observable<boolean> = of(true);

  private tabs = {
    chart: 'chart',
    table: 'table'
  };
  private tabChanged = new BehaviorSubject(this.tabs.chart);

  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.viewModel = this.riskSummaryService.selectedRisk
      .pipe(map(value => {
        let historicalLosses = [];
        let totalLoss, netTotalLoss, max, min = 0;
        let serverYear = {
          year: 0,
          incurredTotal: 0,
          outstandingTotal: 0,
          netIncurredTotal: 0,
          paidTotal: 0
        };

        if (value.historicalLosses && value.historicalLosses.orderedLosses) {
          historicalLosses = value.historicalLosses.orderedLosses;
          totalLoss = value.historicalLosses.totalLoss;
          netTotalLoss = value.historicalLosses.totalAdjustedLoss;
          min = value.historicalLosses.minYear;
          max = value.historicalLosses.maxYear;
          serverYear = value.historicalLosses.server;
        }
        const numberOfYears = historicalLosses.length;
        const averageAnnualLoss = totalLoss / numberOfYears;
        const adjustedAnnualLoss = netTotalLoss / numberOfYears;

        return {
          selectedRiskName: value.name,
          losses: { table: [value.historicalLosses], totalLoss: totalLoss, totalAdjustedLoss: netTotalLoss },
          isRiskLossDataPopulated: historicalLosses.length > 0,
          actual: this.getStatement('ACTUAL_AVERAGE_GROSS_LOSS_STATEMENT', value.name, averageAnnualLoss, min, max),
          adjusted: this.getStatement('ADJUSTED_AVERAGE_GROSS_LOSS_STATEMENT', value.name, adjustedAnnualLoss, min, max),
          gross: this.getGrossLossStatement('GROSS_LOSS_STATEMENT', serverYear.year, serverYear.incurredTotal),
        };
      }));
    this.isChartSelected = this.tabChanged.pipe(map(tab => this.tabs.chart === tab));
  }

  selectChartTab() {
    this.tabChanged.next(this.tabs.chart);
  }

  selectTableTab() {
    this.tabChanged.next(this.tabs.table);
  }

  formatNumber(value: number) {
    return new ShortNumberFormatPipe().transform(value);
  }

  private getStatement(key: string, risk: string, loss: number, min: number, max: number) {
    return this.translateService.instant(this.translationKey + '.' + key,
      {
        ['risk']: risk,
        ['loss_value']: this.formatNumber(loss),
        ['from']: min,
        ['to']: max,
      });
  }

  private getGrossLossStatement(key: string, year: number, loss: number) {
    return this.translateService.instant(this.translationKey + '.' + key,
      {
        ['year']: year,
        ['NN']: this.formatNumber(loss)
      });
  }

}
