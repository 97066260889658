import { TitleCasePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PercentileVM, PortfolioComparisonItem, PortfolioComparisonRetainedLoss } from "app/api/dtos";

@Component({
    selector: 'dtcor-portfolio-common-table',
    templateUrl: './common-table.component.html',
    styleUrls: ['./common-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortfolioCommonTableComponent implements OnChanges {

    @Input() portfolios: PortfolioComparisonItem[];
    @Input() translationKey: string;
    @Input() losses: PortfolioComparisonRetainedLoss[];
    @Input() severe: PercentileVM;
    @Input() typical: PercentileVM;
    rows = [];

    constructor(private translateService: TranslateService) { }

    ngOnChanges(changes: SimpleChanges): void {
        const translationKey = this.getChanges<string>(changes, 'translationKey');
        if (translationKey) {
            this.rows = this.getRows();
        }
        const severeValue = this.getChanges<PercentileVM>(changes, 'severe');
        if (severeValue) {
            this.setServerPercentileName();
            this.setRowValues(severeValue.id, 1);
        }
        const typicalValue = this.getChanges<PercentileVM>(changes, 'typical');
        if (typicalValue) {
            this.setRowValues(typicalValue.id, 0);
        }

        const portfolios = this.getChanges<PercentileVM>(changes, 'portfolios');
        if (portfolios) {
            this.setRowAdequacy();
        }

        const lossesChanges = changes['losses'];
        const lossesValue: PortfolioComparisonRetainedLoss[] = (lossesChanges?.currentValue) ?? [] as PortfolioComparisonRetainedLoss[];
        const previousLossesValue: PortfolioComparisonRetainedLoss[] = (lossesChanges?.previousValue) ?? [] as PortfolioComparisonRetainedLoss[];
        if (lossesValue && previousLossesValue !== lossesValue) {
            this.losses = [
                ...this.losses.filter(x => !x.percentage),
                ...this.losses.filter(x => x.percentage)
            ]
        }
    }

    getPercentageLabel(percentage: number) {
        const labelKey = `${this.translationKey}.STATISTIC_TABLE.${percentage}`;
        const label = this.translateService.instant(labelKey, { ['percentile']: `${percentage}th` });
        return label == labelKey ? `${percentage}th` : label;
    }

    private getChanges<T>(changes: SimpleChanges, key: string): T {
        const current: T = changes[key]?.currentValue;
        const previous: T = changes[key]?.previousValue;
        return current && current !== previous ? current : null;
    }

    private setServerPercentileName() {
        const severePercentile = this.severe.percentage ?
            `${this.severe.percentage}th` : new TitleCasePipe().transform(this.severe.label);
        this.rows[1]['3'].name = this.translateService.instant(`${this.translationKey}.RETAINED_LOSS_HEIGHEST`,
            { ["severePercentile"]: severePercentile });
    }

    private setRowValues(statisticId: number, rowIndex: number) {
        const losses = this.losses.find(x => x.statisticId === statisticId).portfolioLosses;
        this.rows[rowIndex]['1'].value = losses.map(x => ({ value: x.tcor, isSelected: x.isSelected }));
        this.rows[rowIndex]['2'].value = losses.map(x => ({ value: x.premium, isSelected: x.isSelected }));
        this.rows[rowIndex]['3'].value = losses.map(x => ({ value: x.retained, isSelected: x.isSelected }));
        this.rows[rowIndex]['4'].value = losses.map(x => ({ value: x.lossRatio, isSelected: x.isSelected }));
    }

    private setRowAdequacy() {
        const rowIndex = 2
        this.rows[rowIndex]['1'].value = this.portfolios.map(x => ({ value: 0, isSelected: x.isSelected }));
        this.rows[rowIndex]['2'].value = this.portfolios.map(x => ({ value: x.insuranceUtilized, isSelected: x.isSelected }));
        this.rows[rowIndex]['3'].value = this.portfolios.map(x => ({ value: x.limitsAdequate, isSelected: x.isSelected }));
    }

    private getRows() {
        const translate = (key, args = null) => this.translateService.instant(`${this.translationKey}.${key}`, args)
        return [
            {
                1: {
                    name: translate('BUDGETARY_AMOUNT'),
                    description: translate('BUDGETARY_AMOUNT_DESCRIPTION'),
                    value: [],
                    bold: true
                },
                2: {
                    name: translate('PREMIUM'),
                    value: []
                },
                3: {
                    name: translate('RETAINED_LOSS_MEDIAN'),
                    value: []
                },
                4: {
                    name: translate('LOSS_RATIO'),
                    value: [],
                    percentage: true

                }
            },
            {
                1: {
                    name: translate('EXTREME_AMOUNT'),
                    description: translate('EXTREME_AMOUNT_DESCRIPTION'),
                    value: [],
                    bold: true
                },
                2: {
                    name: translate('PREMIUM'),
                    value: []
                },
                3: {
                    name: '',
                    value: []
                },
                4: {
                    name: translate('LOSS_RATIO'),
                    value: [],
                    percentage: true
                }
            },
            {
                1: {
                    name: translate('ADEQUACY'),
                    description: translate('ADEQUACY_DESCRIPTION'),
                    value: [],
                    noValue: true
                },
                2: {
                    name: translate('INSURANCE'),
                    value: [],
                    percentage: true
                },
                3: {
                    name: translate('LIMITS'),
                    value: [],
                    percentage: true
                }
            }];
    }
}
