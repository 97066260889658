import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IPage } from '@wtw/platform/interfaces';
import { PageLinkService, UserService } from '@wtw/platform/services';
import { FeaturePermissionType } from 'app/shared/models/FeaturePermissionType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubRoleGuard implements CanActivate {
  pages: IPage[];

  constructor(private _userService: UserService, private _pageService: PageLinkService, private _router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let hasAccess = true;
    let hasSubRole = false;
    this._userService.userInfo.subscribe(c => {
        let permissions: any = c.details.accessSettings.toDictionary('featureGuid');
        hasSubRole = c.details.subrole !== null;
        if (hasSubRole) {
            permissions = c.details.accessSettings.groupBy('featureGuid');
            Object.keys(permissions).forEach(d => {
                permissions[d] = permissions[d].toDictionary('featurePermissionType');
            });
            hasAccess = !permissions[route.data.featureGuid][FeaturePermissionType.NoAccess]
                .hasPermission;
        }
    }).unsubscribe();

    if (!hasSubRole) {
        setTimeout(() => this._router.navigate(['/no-sub-role']), 1);
        return false;
    }

    if (!hasAccess) {
        this._pageService.pageStateChanged.subscribe(
            (pages: IPage[]) => {
                this.pages = pages;
            }
        ).unsubscribe();
        const redirectURL = this.findPage(route.data.redirectIdentifier, this.pages).url;
        setTimeout(() => this._router.navigateByUrl(redirectURL), 1);
        return false;
    }

    return true;
  }

  private findPage(identifier: string, pages: IPage[]): IPage {
    for (let i = 0; i < pages.length; i++) {
      const currentPage = pages[i];

      if (currentPage.identifier === identifier) {
        return currentPage;
      } else if (currentPage.children) {
        const currentChildPage = this.findPage(identifier, currentPage.children);

        if (currentChildPage) {
          return currentChildPage;
        }
      }
    }

    return null;
  }

}
