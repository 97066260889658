import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[context-menu]'
})
export class ContextMenuDirective {
    constructor(private el: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        this.closeAllDropdowns(event.target.nextElementSibling);
        if (event.target.className === 'menu-button') {
            event.target.nextElementSibling.classList.toggle('show');
        }
    }

    private closeAllDropdowns(except) {
        const dropdowns = document.getElementsByClassName(`dropdown-content`);
        for (let i = 0; i < dropdowns.length; i++) {
            const openDropdown = dropdowns[i];
            if ( except !== openDropdown && openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
}
