import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { PortfolioComparisonItem } from 'app/api/dtos';
import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';
import { maxBy } from 'lodash';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExecutiveSummaryService } from '../../executive-summary.service';
import { ModalWrapperService } from 'app/services/modal-wrapper.service';
import { CCORLegendItem } from 'app/shared/models/CCORLegendItem';

@Component({
    selector: 'dtcor-portfolio-comparison',
    templateUrl: './portfolio-comparison.component.html',
    styleUrls: ['./portfolio-comparison.component.scss']
})
export class PortfolioComparisonComponent implements OnInit, OnDestroy {
    translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PORTFOLIO_COMPARISON';
    metricAnalysisVm;
    viewModel;
    modal;
    private portfolioTabs = {
        chart: 'chart',
        table: 'table'
    };
    private seriesChanged = new BehaviorSubject<CCORLegendItem>({ name: '', colour: '', enabled: false });
    private colours = {
        retained: '#5C66CC',
        premium: '#B12F9B',
        costOfVolatility: '#F5BA47',
        valueFromInsurance: '#57C08D'
    };
    private legends: CCORLegendItem[] = [
        { name: this.translateService.instant(this.translationKey + '.VALUE_FROM_INSURANCE'), colour: this.colours.valueFromInsurance, enabled: true },
        { name: this.translateService.instant(this.translationKey + '.COST_OF_VOLATILITY'), colour: this.colours.costOfVolatility, enabled: true },
        { name: this.translateService.instant(this.translationKey + '.PREMIUM'), colour: this.colours.premium, enabled: true },
        { name: this.translateService.instant(this.translationKey + '.RETAINED_MEAN'), colour: this.colours.retained, enabled: true }
    ];
    private portfolioTabChanged = new BehaviorSubject(this.portfolioTabs.chart);
    private showPopup = new BehaviorSubject(false);
    private selectedPortfolio = new BehaviorSubject<PortfolioComparisonItem>(null);
    private deleteInProgress = new BehaviorSubject<boolean>(false);
    private subscriptions = [];

    constructor(private executiveSummaryService: ExecutiveSummaryService,
        private translateService: TranslateService,
        private modalService: ModalWrapperService) {
        // empty block
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    ngOnInit(): void {
        this.subscriptions.push(this.executiveSummaryService.selectedTab?.subscribe((tab) => {
            if (!tab.isSelected) {
                this.toggleSeries({ name: '', colour: '', enabled: false });
                this.legends.forEach(legend => legend.enabled = true);
            }
        }));
        this.viewModel = combineLatest([
            this.executiveSummaryService.executiveSummaryViewModel,
            this.executiveSummaryService.typicalPercentileChanged,
            this.executiveSummaryService.severePercentileChanged,
            this.portfolioTabChanged,
            this.showPopup,
            this.selectedPortfolio,
            this.deleteInProgress,
            this.seriesChanged
        ])
            .pipe(map(([value, typical, severe, selectedTab, showPopup, selectedPortfolio, deleteInProgress,
                series]) => {

                if (selectedTab === this.portfolioTabs.table) {
                    this.legends.forEach(legend => legend.enabled = true);
                }

                return {
                    ...value.portfolioComparison,
                    colours: this.getColours(),
                    typical,
                    severe,
                    maxCCOR: maxBy(value.portfolioComparison.items, 'ccor')?.ccor ?? 0,
                    isChartSelected: selectedTab === this.portfolioTabs.chart,
                    showPopup,
                    metricAnalysisVm: new MetricAnalysisTable(this.translationKey, this.translateService).getViewModel(),
                    selectedPortfolio,
                    newPortfolio: <PortfolioComparisonItem>{
                        label: '',
                        name: '',
                        isCurrent: false,
                        isUnhedged: false,
                        ccor: 0,
                        retained: 0,
                        premium: 0,
                        costOfVolatility: 0,
                        valueFromInsurance: 0
                    },
                    deleteInProgress: deleteInProgress,
                    portfolioNames: value.portfolioComparison.items.map(item => item.label),
                    toggleSeries: { ...series },
                    legends: this.legends
                };
            }));
    }

    toggleSeries(legend: CCORLegendItem) {
        legend.enabled = !legend.enabled;
        this.seriesChanged.next(legend);
    }

    sortMatricAnalysisTable(event: { columnIndex: number, direction: number }) {
        // empty block
    }

    selectChartTab() {
        this.portfolioTabChanged.next(this.portfolioTabs.chart);
    }

    selectTableTab() {
        this.portfolioTabChanged.next(this.portfolioTabs.table);
    }

    getLabel(structure: PortfolioComparisonItem) {
        return structure.isUnhedged ?
            this.translateService.instant(this.translationKey + '.UNHEDGED')
            : structure.isCurrent ? this.translateService.instant(this.translationKey + '.CURRENT_STRUCTURE')
                : structure.label;
    }

    select(portfolio: PortfolioComparisonItem) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant(`${this.translationKey}.SELECT_MODAL.OK`),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('GLOBAL.BTN.CANCEL'),
            cssClass: 'btn btn-secondary'
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this.translateService.instant(`${this.translationKey}.SELECT_MODAL.TITLE`),
            message: this.translateService.instant(`${this.translationKey}.SELECT_MODAL.MESSAGE`),
            cancelButton: cancelConfig,
            yesButton: yesConfig,
            noButton: { display: false }
        };

        this.modalService.confirm(confirmConfig).then((yesClicked) => {
            if (yesClicked) {
                this.executiveSummaryService
                    .selectPortfolio(portfolio)
                    .pipe(take(1))
                    .subscribe(() => console.log('selected'));
            }
        });
    }

    delete(portfolio: PortfolioComparisonItem) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant(`${this.translationKey}.DELETE_MODAL.REMOVE`),
            cssClass: 'btn btn-danger'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('GLOBAL.BTN.CANCEL'),
            cssClass: 'btn btn-secondary'
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this.translateService.instant(`${this.translationKey}.DELETE_MODAL.TITLE`),
            message: this.translateService.instant(`${this.translationKey}.DELETE_MODAL.MESSAGE`),
            cancelButton: cancelConfig,
            yesButton: yesConfig,
            noButton: { display: false }
        };

        this.modalService.confirm(confirmConfig).then((yesClicked) => {
            if (yesClicked) {
                this.executiveSummaryService
                    .deletePortfolio(portfolio)
                    .pipe(take(1))
                    .subscribe(() => console.log('deleted'));
            }

        });
    }

    view(portfolio: PortfolioComparisonItem) {
        this.showAddEditPopup(portfolio);
    }

    closePopup() {
        this.showPopup.next(false);
    }

    add(newPortfolio: PortfolioComparisonItem) {
        this.showAddEditPopup(newPortfolio);
    }

    addPortfolio(portfolio: PortfolioComparisonItem) {
        this.addEditPortfolio(portfolio, true);
    }

    editPortfolio(portfolio: PortfolioComparisonItem) {
        this.addEditPortfolio(portfolio, false);
    }

    showEdit(portfolio: PortfolioComparisonItem) { return !portfolio.isUnhedged; }

    showSelect(portfolio: PortfolioComparisonItem) { return !portfolio.isSelected; }

    showRemove(portfolio: PortfolioComparisonItem) {
        return !portfolio.isUnhedged && !portfolio.isCurrent;
    }

    private addEditPortfolio(portfolio: PortfolioComparisonItem, isAdd: boolean) {
        this.executiveSummaryService
            .addEditPortfolio(portfolio, isAdd)
            .pipe(take(1))
            .subscribe(isError => this.showPopup.next(isError));
    }

    private showAddEditPopup(newPortfolio: PortfolioComparisonItem) {
        this.selectedPortfolio.next(newPortfolio);
        this.showPopup.next(true);
    }

    private getColours() {
        return {
            retained: '#5C66CC',
            premium: '#B12F9B',
            costOfVolatility: '#F5BA47',
            valueFromInsurance: '#57C08D'
        };
    }
}

function getRandom(value: number = 1000000) {
    return Math.floor((Math.random() * 100) + 1) * value;
}

class BaseTable {
    headers = [];
    columns = [];
    rows;
    sortColumnIndex = 5;
    private generatedData;
    constructor(protected translationKey,
        protected translateService: TranslateService,
        private columnKeys) {
        this.createColumns();
        this.createRows();
    }

    getViewModel() {
        return {
            table: {
                headers: this.headers,
                rows: this.rows,
            },
            sortColumnIndex: this.sortColumnIndex
        };
    }

    sortAssending(columnIndex: number) {
        this.sortColumnIndex = columnIndex;
        this.sort(arr => arr.sort((a, b) => a[`${this.columnKeys[columnIndex]}`] - b[`${this.columnKeys[columnIndex]}`]));
    }

    sortDessending(columnIndex: number) {
        this.sortColumnIndex = columnIndex;
        this.sort(arr => arr.sort((a, b) => b[`${this.columnKeys[columnIndex]}`] - a[`${this.columnKeys[columnIndex]}`]));
    }

    private sort(sortTable) {
        this.setRows([...sortTable(this.generatedData)]);
    }

    private createColumns() {
        const defaultColumn = {
            isPercentage: false,
            isBold: false,
            align: APP_CONSTANTS.align.RIGHT
        };
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
    }

    private createRows() {
        this.generatedData = this.getTopFiveRisks();
        const risks = this.generatedData;
        this.setRows([...risks]);
    }

    private setRows(risks: any) {
        this.rows = risks.map(item => ({
            isBold: item.isBold,
            data: [{
                value: item[`${this.columnKeys[0]}`],
                ...this.columns[0]
            }, {
                value: item[`${this.columnKeys[1]}`],
                ...this.columns[1]
            }, {
                value: item[`${this.columnKeys[2]}`],
                ...this.columns[2]
            }, {
                value: item[`${this.columnKeys[3]}`],
                ...this.columns[3]
            }, {
                value: item[`${this.columnKeys[4]}`],
                ...this.columns[4]
            }, {
                value: item[`${this.columnKeys[5]}`],
                ...this.columns[5]
            }]
        }));
    }

    private getTopFiveRisks() {
        return this.getDummyData();
    }

    private getDummyData() {
        const risks = [
            { [`${this.columnKeys[0]}`]: '1 in 2', [`${this.columnKeys[1]}`]: 'Median' },
            { [`${this.columnKeys[0]}`]: '1 in 4', [`${this.columnKeys[1]}`]: '75th' },
            { [`${this.columnKeys[0]}`]: '1 in 10', [`${this.columnKeys[1]}`]: '90th' },
            { [`${this.columnKeys[0]}`]: '1 in 100', [`${this.columnKeys[1]}`]: '99th' },
            { [`${this.columnKeys[0]}`]: '1 in 200', [`${this.columnKeys[1]}`]: '99.5th' },
            { [`${this.columnKeys[0]}`]: '1 in 1000', [`${this.columnKeys[1]}`]: '99.9th' },
            { [`${this.columnKeys[0]}`]: '', [`${this.columnKeys[1]}`]: 'Mean', isBold: true },
        ];
        for (let index = 0; index < risks.length; index++) {
            risks[index] = <any>{
                ...risks[index],
                [`${this.columnKeys[2]}`]: getRandom(),
                [`${this.columnKeys[3]}`]: getRandom(),
                [`${this.columnKeys[4]}`]: getRandom(),
                [`${this.columnKeys[5]}`]: getRandom()
            };
        }
        return risks;
    }

}
class MetricAnalysisTable extends BaseTable {
    constructor(translationKey: string,
        translateService: TranslateService) {
        const columnKeys = {
            0: 'returnPeriod',
            1: 'percentile',
            2: 'unhedged',
            3: 'currentPortfolio',
            4: 'portfolio1',
            5: 'portfolio2',
        };
        super(translationKey, translateService, columnKeys);
        this.createHeaders();
    }

    private createHeaders() {
        const columnKey = this.translationKey + '.METRIC_ANALYSIS.TABLE.COLUMN';
        this.headers.push(this.translateService.instant(columnKey + '.RETURN_PERIOD'));
        this.headers.push(this.translateService.instant(columnKey + '.PERCENTILE'));
        this.headers.push(this.translateService.instant(columnKey + '.UNHEDGED'));
        this.headers.push(this.translateService.instant(columnKey + '.CURRENT_PORTFOLIO'));
        this.headers.push(this.translateService.instant(columnKey + '.PORTFOLIO', { ['N']: ' 1' }));
        this.headers.push(this.translateService.instant(columnKey + '.PORTFOLIO', { ['N']: ' 2' }));
    }
}


