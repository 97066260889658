import { Inject, Injectable } from '@angular/core';
import { Percentile } from '../api/dtos';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {

  typicalPercentiles: Percentile[];
  severePercentiles: Percentile[];
  _loadingScreen: Observable<null>;

  translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY';
  percentileTranslation: string;

  constructor(@Inject(TranslateService) private translateService: TranslateService) {
    this.percentileTranslation = translateService.instant(`${this.translationKey}.PERCENTILE`);
  }

  public startLoadingScreen(loadingMessage: string): void {

    if (!loadingMessage.length) {
      loadingMessage = this.translateService.instant(`CURRENT_MODEL.LOADING`);
    }

    this._loadingScreen = of(null)
      .uiSignal({
        debugInfo: loadingMessage,
        uiLabel: loadingMessage
      });
  }

  public stopLoadingMessage(): void {
    this._loadingScreen.subscribe(_ => null).unsubscribe();
  }


  createPercentile(id: number, percentile: number, text: string): Percentile {
    const label = percentile !== null ? `${percentile}th` : text;
    const percentileDisplayLabel = percentile !== null ? `${label} ${this.percentileTranslation}` : label;
    return {
      percentileId: id,
      percentileValue: percentile,
      percentileRadioLabel: label,
      percentileDisplayLabel: percentileDisplayLabel
    };
  }
}
