import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import * as Highcharts from 'highcharts';

import HighchartsMore from 'highcharts/highcharts-more.src';
import Variablepie from 'highcharts/modules/variable-pie';
HighchartsMore(Highcharts);
Variablepie(Highcharts);


@Component({
    selector: 'dtcor-chart',
    templateUrl: 'chart.component.html',
    styleUrls: []
})
export class DtcorChartComponent implements OnInit {

    @Input() translation;
    @Input() translationKey: any;
    @Input() options: any;

    @ViewChild('chart', { static: true }) public chartEl: ElementRef;
  
    ngOnInit() {
        this.generateChartOptions();
    }

    generateChartOptions() {
       Highcharts.chart(this.chartEl.nativeElement, this.options);
    }
}
