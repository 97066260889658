<table e2eItem="table-data" class="main-table" summary="chart table">
    <tr>
        <td class="row-header"> {{translationKey + '.PREMIUM_ROI' | translate}}</td>
        <ng-container *ngFor="let structure of portfolios">
            <td [ngClass]="{'selected-portfolio':structure.isSelected}" class="heilighted-text">
                {{structure.roi | percentageFormatter}}</td>
        </ng-container>
    </tr>
    <ng-container *ngIf="rows && rows.length > 0">
        <ng-container *ngFor="let row of rows">
            <tr>
                <td class="row-header detail-row"> {{row['1'].name}}</td>
                <ng-container *ngFor="let portfolioLoss of row['1'].value">
                    <td [ngClass]="{'selected-portfolio':portfolioLoss.isSelected,'heilighted-text': row['1'].bold}" class="detail-row">
                        <span *ngIf="!row['1'].noValue">{{portfolioLoss.value | shortNumberFormat}}</span>
                        <span *ngIf="row['1'].noValue"></span>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td class="row-header description detail-row">{{row['1'].description}}
                </td>
                <ng-container *ngFor="let portfolioLoss of row['1'].value">
                    <td [ngClass]="{'selected-portfolio':portfolioLoss.isSelected}" class="detail-row">
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td class="row-header detail-text"> {{row['2'].name }}</td>
                <ng-container *ngFor="let portfolioLoss of row['2'].value">
                    <td  [ngClass]="{'selected-portfolio':portfolioLoss.isSelected, 'heilighted-text': row['2'].bold}">
                       <span *ngIf="!row['2'].percentage">{{portfolioLoss.value | shortNumberFormat}}</span> 
                       <span *ngIf="row['2'].percentage">{{portfolioLoss.value | percentageFormatter}}</span> 
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td class="row-header detail-text"> {{row['3'].name}}</td>
                <ng-container *ngFor="let portfolioLoss of row['3'].value">
                    <td  [ngClass]="{'selected-portfolio':portfolioLoss.isSelected}">
                        <span *ngIf="!row['3'].percentage">{{portfolioLoss.value | shortNumberFormat}}</span>
                        <span *ngIf="row['3'].percentage">{{portfolioLoss.value | percentageFormatter}}</span>
                    </td>
                </ng-container>
            </tr>
            <tr *ngIf="row['4']">
                <td class="row-header detail-text"> {{row['4'].name}}</td>
                <ng-container *ngFor="let portfolioLoss of row['4'].value">
                    <td  [ngClass]="{'selected-portfolio':portfolioLoss.isSelected}">
                        <span *ngIf="!row['4'].percentage">{{portfolioLoss.value | shortNumberFormat}}</span>
                        <span *ngIf="row['4'].percentage">{{portfolioLoss.value | percentageFormatter}}</span>
                    </td>
                </ng-container>
            </tr>
        </ng-container>
    </ng-container>
    <tr>
        <td class="row-header detail-row"> {{translationKey + '.RETAINED_LOSS' | translate}}</td>
        <ng-container *ngFor="let structure of portfolios">
            <td [ngClass]="{'selected-portfolio':structure.isSelected}" class="detail-row">
           </td>
        </ng-container>
    </tr>
    <tr>
        <td class="row-header description detail-row">{{translationKey + '.RETAINED_LOSS_DESCRIPTION' | translate}}</td>
        <ng-container *ngFor="let structure of portfolios">
            <td [ngClass]="{'selected-portfolio':structure.isSelected}" class="detail-row">
            </td>
        </ng-container>
    </tr>
    <ng-container *ngFor="let loss of losses">
        <tr>
            <td class="row-header detail-text" *ngIf="loss.percentage"> {{getPercentageLabel(loss.percentage)}}</td>
            <td class="row-header detail-text" *ngIf="!loss.percentage"> {{loss.label | titlecase }}</td>
            <ng-container *ngFor="let structure of loss.portfolioLosses">
                <td [ngClass]="{'selected-portfolio':structure.isSelected}">
                    {{structure.retained | shortNumberFormat}}</td>
            </ng-container>
        </tr>
    </ng-container>
</table>