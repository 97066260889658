import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { RiskSummaryService } from '../../risk-summary.service';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';

import * as Highcharts from 'highcharts';
import Variablepie from 'highcharts/modules/variable-pie';
import { BehaviorSubject, combineLatest } from 'rxjs';
Variablepie(Highcharts);

import { AlertnativeStructureVM } from 'app/api/dtos';

@Component({
  selector: 'dtcor-risk-alternative-structure',
  templateUrl: './risk-alternative-structure.component.html',
  styleUrls: ['./risk-alternative-structure.component.scss']
})
export class RiskAlternativeStructureComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  options: Highcharts.Options;

  detailExpanded: BehaviorSubject<any> = new BehaviorSubject<any>({});

  public translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.ALTERNATIVE_STRUCTURE';

  colors: string[] = ['#FFB81C', '#00A0D2', '#6F1F82'];

  viewModel;

  defaultWidth = 275;

  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService) {
    // empty block
  }

  ngOnInit(): void {
    this.viewModel = this.getViewModel();
  }

  getViewModel() {
    return combineLatest([
      this.riskSummaryService.selectedRisk,
      this.detailExpanded
    ]).pipe(map(([risk, structure]) => {

      risk.alertnativeStructure.currentStructure.chart.options = this.getOptions(risk.alertnativeStructure.currentStructure.chart);

      risk.alertnativeStructure.structures.forEach((_) => {
        _.chart.options = this.getOptions(_.chart);
      });

      return {
        risk: risk,
        wrapperWidth: this.getWrapperWidth(risk.alertnativeStructure.structures, risk.alertnativeStructure.currentStructure, structure),
        isAnyExpanded: this.isAnyExpanded(risk.alertnativeStructure)
      };
    }));
  }

  isAnyExpanded(alertnativeStructure: AlertnativeStructureVM) {
    let expanded = this.expanded(alertnativeStructure.currentStructure);
    alertnativeStructure.structures.forEach((_) => {
      if (this.expanded(_)) {
        expanded = true;
      }
    });
    return expanded;
  }

  expanded(structure: any): boolean {
    return structure.retainedExpanded || structure.transferredExpanded;
  }

  bothExpanded(structure: any) {
    return structure.retainedExpanded && structure.transferredExpanded;
  }

  getWidth(structure: any) {
    const subColWidth = 80;
    if (this.expanded(structure)) {
      if (this.bothExpanded(structure)) {
        const subTotalWidth = (structure.retainedColumns.length + structure.transferredColumns.length) * subColWidth;
        return `${(subTotalWidth + this.defaultWidth)}px`;
      } else {
        if (structure.retainedExpanded) {
          return `${((structure.retainedColumns.length * subColWidth) + this.defaultWidth)}px`;
        } else if (structure.transferredExpanded) {
          return `${((structure.transferredColumns.length * subColWidth) + this.defaultWidth)}px`;
        }
      }
    }

    return `${this.defaultWidth}px`;
  }

  getWrapperWidth(structures: any, current: any, structure: any): string {
    let width = ((2) * this.defaultWidth);

    structures = [current, ...structures];

    structures.forEach(s => {
      width += parseInt(this.getWidth(s), 0);
    });

    return `${width}px`;
  }

  toggleRetained(structure: any) {
    structure.retainedExpanded = !structure.retainedExpanded;
    this.detailExpanded.next(structure);
  }

  isRetainedExpanded(structure: any) {
    return structure.retainedExpanded;
  }

  toggleTransferred(structure: any) {
    structure.transferredExpanded = !structure.transferredExpanded;
    this.detailExpanded.next(structure);
  }

  isTransferredExpanded(structure: any) {
    return structure.transferredExpanded;
  }

  private translate(key: string) {
    return this.translateService.instant(`${this.translationKey}.CHART_LEGEND.${key}`);
  }

  private getOptions(series: any) {

    const formatNumber = (value: number) => this.formatNumber(value);

    return {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent'
      },
      credits: {
        text: ''
      },
      title: {
        text: '',
        style: {
          display: 'none'
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: 150,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            distance: -40,
            formatter: function () {
              return formatNumber(this.point.value);
            }
          },
          showInLegend: true,
          minSize: 10,
        }
      },
      tooltip: {
        enabled: false,
        useHTML: true,
        formatter: function () {
          const value = formatNumber(this.y);
          return `<div class="dot" style="background-color:${this.color}"></div> <span class="text-white">${this.key}: <b>${value}</b></span><br/>`;
        }
      },
      series: [{
        type: 'pie',
        data: this.getData(series)
      }]
    };
  }

  private getData(series: any) {
    const data = [];
    if (series.retainedBU > 0) {
      data.push({
        name: this.translate(`RETAINED_BU`),
        y: this.getPercent(series, series.retainedBU),
        color: '#FFB81C',
        value: series.retainedBU
      });
    }
    if (series.retainedCaptive > 0) {
      data.push({
        name: this.translate(`RETAINED_CAPTIVE`),
        y: this.getPercent(series, series.retainedCaptive),
        color: '#00A0D2',
        value: series.retainedCaptive
      });
    }
    if (series.transferred > 0) {
      data.push({
        name: this.translate(`TRANSFERRED`),
        y: this.getPercent(series, series.transferred),
        color: '#6F1F82',
        value: series.transferred
      });
    }
    return data;

  }

  private getPercent(series: any, value: number) {
    const total = series.retainedBU + series.retainedCaptive + series.transferred;
    return value / total * 100;
  }

  private formatNumber(value: number) {
    return new ShortNumberFormatPipe().transform(value);
  }

}
