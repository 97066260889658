import { Inject, Injectable } from '@angular/core';
import { Dto } from '@wtw/platform/api';
import { UserService, ModalService } from '@wtw/platform/services';
import { first } from 'rxjs/operators';
import { FeaturePermissionType } from '../shared/models/FeaturePermissionType';
import { ReadOnlyAccessModalComponent } from 'app/shared/components/read-only-access-modal/read-only-access-modal.component';
import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';

@Injectable()
export class PermissionService {

  user: Dto.UserDetails;
  permissions: any;

  constructor(@Inject(UserService) private _userService: UserService,
              @Inject(ModalService) private _modalService: ModalService) { }

  init() {
    this._userService.userInfo.pipe(first()).subscribe((user: any) => {
      this.user = user.details;
      this.permissions = user.details.accessSettings.toDictionary('featureGuid');
      this.permissions = user.details.accessSettings.groupBy('featureGuid');
      Object.keys(this.permissions).forEach(d => {
          this.permissions[d] = this.permissions[d].toDictionary('featurePermissionType');
      });
    });
  }

  hasReadOnlyOrLimitedAccessToFeature(featureGuid: string) {
    return this.user.subrole ? (this.permissions[featureGuid][FeaturePermissionType.ReadOnly].hasPermission
      || this.permissions[featureGuid][FeaturePermissionType.Limited].hasPermission): false;
  }

  hasFullAccess(featureGuid: string) {
    return this.user.subrole ? this.permissions[featureGuid][FeaturePermissionType.FullAccess].hasPermission
    : true;
  }

  hasAccessToPrecisionRating() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.precisionRating.featureGuid);
  }

  hasAccessToGoToNextSteps() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.goToNextSteps.featureGuid);
  }

  hasFullAccessToViewModelSource() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.viewModelSource.featureGuid);
  }

  hasFullAccessToHistoricalLossesTab() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.historicalLossesTab.featureGuid);
  }

  hasAccessToPremiumExpensesSeeMore() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.premiumExpensesSeeMore.featureGuid);
  }

  hasAccessToPricingTabPerRisk() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.pricingTabPerRisk.featureGuid);
  }

  hasAccessToReport() {
    return this.hasFullAccess(APP_CONSTANTS.subRolePermissions.report.featureGuid);
  }

  showModal(data: any = {}) {
    this._modalService.confirmWithComponent(ReadOnlyAccessModalComponent, { data }).then((res) => {
      // empty block
    });
  }
}
