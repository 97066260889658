<!-- the reason the same chart is added twice is that the bound veriable changes doesnt draw the chart it has to be toggled -->
<ng-container *ngIf="(viewModel | async) as vm">
    <div class="row">
        <div [ngStyle]="{'width':vm.containerWidth +'%'}" class="chart-container width-transition">
            <div class="chart-tooltip chart left"
                [ngStyle]="{'width':vm.left.width + '%','background-color':vm.left.color}">              
                <ng-container
                    *ngIf="vm.isPercentage || showValuesAsPercentage;then content_left else other_content_left">
                </ng-container>
                <ng-template #content_left><span class="tooltiptext"><span>{{vm.left.name}}</span> : {{vm.left.value
                        | percentageFormatter }}</span></ng-template>
                <ng-template #other_content_left><span class="tooltiptext"><span>{{vm.left.name}}</span> :
                        {{vm.left.value |
                        shortNumberFormat }}</span>
                </ng-template>
            </div>
            <div class="chart-tooltip chart right"
                [ngStyle]="{'width':vm.right.width + '%','background-color':vm.right.color}">
                <ng-container *ngIf="vm.isPercentage || showValuesAsPercentage;then content else other_content">
                </ng-container>
                <ng-template #content><span class="tooltiptext"><span>{{vm.right.name}}</span> : {{vm.right.value
                        | percentageFormatter }}</span></ng-template>
                <ng-template #other_content><span class="tooltiptext"><span>{{vm.right.name}}</span> : {{vm.right.value
                        |
                        shortNumberFormat }}</span></ng-template>

            </div>
        </div>
        <div class="total" style="width:13%">
            <div>
                <div *ngIf="showTotalKey">{{ translationKey + '.TOTAL_COST' | translate }}</div>
                <div *ngIf="showTotal">{{total | shortNumberFormat }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="chart-summary-text" [ngStyle]="{'width':vm.containerWidth +'%'}">
            {{vm.summary}}
        </div>
    </div>
</ng-container>