import { Directive, ElementRef, Input, Host, OnInit, Optional } from '@angular/core';

@Directive({
    selector: '[e2eGroup]'
})
export class E2eGroupDirective {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('e2eGroup') e2eGroup: string;
}

@Directive({
    selector: '[e2eItem]'
})
export class E2eAnchorDirective implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('e2eItem') e2eItem: string;

    constructor(private el: ElementRef,
        @Optional() @Host() private e2eGroup: E2eGroupDirective) {
        // empty block
    }

    ngOnInit() {
        this.createAnchor();
    }

    createAnchor() {
        this.el.nativeElement.setAttribute('e2e-anchor', this.anchor());
    }

    anchor() {
        if (this.e2eGroup?.e2eGroup) {
            return this.e2eGroup.e2eGroup + '-' + this.e2eItem;
        }

        return this.e2eItem;
    }
}

