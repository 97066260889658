export interface ApplicationConstants {

    parliamentChartMaxPoints: number,

    analyticEvent: {
        runTrigger: {
            category: string,
            event: {
                runCompleted: string
            }
        }
    };

    colors: {
        totalRetained: string,
        premium: string,
        expense: string,
        costVolatility: string,
        insuranceValue: string
    };

    displayTypes: {
        financial: number;
        percentage: number;
    };

    sortDirection: {
        ASC: number;
        DESC: number;
    };

    align: {
        LEFT: number;
        RIGHT: number;
    };

    subRolePermissions: {
        tcorSummarySection: {
            featureGuid: string,
            featurePermissionName: string
        };

        precisionRating: {
            featureGuid: string,
            featurePermissionName: string
        };

        goToNextSteps: {
            featureGuid: string,
            featurePermissionName: string
        };

        viewModelSource: {
            featureGuid: string,
            featurePermissionName: string
        };

        historicalLossesTab: {
            featureGuid: string,
            featurePermissionName: string
        };

        premiumExpensesSeeMore: {
            featureGuid: string,
            featurePermissionName: string
        };

        pricingTabPerRisk: {
            featureGuid: string,
            featurePermissionName: string
        };

        modelManager: {
            featureGuid: string,
            featurePermissionName: string
        };

        report: {
            featureGuid: string,
            featurePermissionName: string
        };
    };

    urlEnvironmentSegment: string[];
    redirectDelay: number;
}

export const APP_CONSTANTS: ApplicationConstants = {
    parliamentChartMaxPoints: 700,

    analyticEvent: {
        runTrigger: {
            category: 'Client - Runs',
            event: {
                runCompleted: 'RunCompleted'
            }
        }
    },
    colors: {
        totalRetained: '#5D63D3',
        premium: '#C111A0',
        expense: '#FF8500',
        costVolatility: '#FFB81C',
        insuranceValue: '#57C08D'
    },

    displayTypes: {
        financial: 1,
        percentage: 2
    },

    sortDirection: {
        ASC: 1,
        DESC: 2
    },

    align: {
        LEFT: 1,
        RIGHT: 2
    },

    subRolePermissions: {
        tcorSummarySection: {
            featureGuid: '63d65daf-bccc-41a1-9b38-2967a92266c9',
            featurePermissionName: 'TCOR Summary section'
        },
        precisionRating: {
            featureGuid: '466c7006-9057-464b-99a8-66ea5d5549ad',
            featurePermissionName: 'TCOR Summary - Precision Rating'
        },
        goToNextSteps: {
            featureGuid: '6a2f4bce-9790-4064-aeee-a9bb6fd5aec6',
            featurePermissionName: 'TCOR Summary - Go to Next Steps'
        },
        viewModelSource: {
            featureGuid: '49214d01-ff45-46f1-9fc0-ffe1136e3092',
            featurePermissionName: 'TCOR Summary - See More - Model Source link button'
        },
        historicalLossesTab: {
            featureGuid: 'fd0bc3e0-21df-4c1d-85b1-ae1b5ef689b0',
            featurePermissionName: 'Historical Losses tab'
        },
        premiumExpensesSeeMore: {
            featureGuid: '161eed9b-d1a3-4543-9eec-3733bcc11812',
            featurePermissionName: 'Premium & Expenses tab - See More button'
        },
        pricingTabPerRisk: {
            featureGuid: '81d2d223-d027-4713-99a9-7f898ef765e5',
            featurePermissionName: 'Pricing tab (per Risk)'
        },
        modelManager: {
            featureGuid: '06cc0e35-ae99-4c13-be6d-266431976845',
            featurePermissionName: 'Model link'
        },
        report: {
            featureGuid: '35c34ea4-68df-4e0a-a8fd-fd18b2fb34c2',
            featurePermissionName: 'Download Report - SRC Risk Review Report option'
        }
    },

    urlEnvironmentSegment: ['local', 'dev', 'qa', 'rc', 'iat'],
    redirectDelay: 5000
};


export const parliamentChartColorsPremium: Array<string> = [ '#D06FC8',
'#663E88',
'#808080',
'#3ADCC9',
'#F6BB4D',
'#F08834',
'#E05D7E',
'#B82CA7',
'#41146B',
'#4380E7'];

export const parliamentChartColorsLosses: Array<string> = [ '#333674',
'#4A4FA9',
'#5D63D3',
'#9699E2',
'#CED0F2',
'#C7EFF7'];
