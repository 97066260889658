import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import itemSeries from 'highcharts/modules/item-series';
itemSeries(Highcharts);

import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { RiskSummaryService } from '../../risk-summary.service';
import { PricingStructureItem } from 'app/api/dtos';

import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'dtcor-risk-pricing',
  templateUrl: './risk-pricing.component.html',
  styleUrls: ['./risk-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskPricingComponent implements OnInit {

  public translationKey = 'CURRENT_MODEL.RISK_SUMMARY.TABS.PRICING';
  Highcharts: typeof Highcharts = Highcharts;

  tabs = {
    market: 0,
    captive: 1
  };

  selectedTab: BehaviorSubject<number> = new BehaviorSubject<number>(this.tabs.market);
  viewModel;

  @ViewChild('staticTabs') staticTabs?: TabsetComponent;

  private selectedRiskId = -1;

  constructor(private riskSummaryService: RiskSummaryService,
    private translateService: TranslateService) {
    // empty block
  }

  ngOnInit(): void {
    this.viewModel = this.getViewModel();
  }

  getViewModel() {
    return combineLatest([
      this.riskSummaryService.selectedRisk,
      this.selectedTab
    ])
      .pipe(map(([selectedRisk, selectedTab]) => {
        const isSameRiskSeleted = this.selectedRiskId === selectedRisk.id;
        this.selectedRiskId = selectedRisk.id;
        selectedTab = !isSameRiskSeleted ? this.tabs.market : selectedTab;
        const pricingModel = selectedRisk.pricing;
        let categories = selectedTab === this.tabs.market ? pricingModel.market.structures : pricingModel.captive.structures;

        categories = [{
          ...categories.find(x => x.isCurrent),
          name: this.translateService.instant(this.translationKey + '.TECH_CHART.CURRENT_STRUCTURE')
        }, ...categories.filter(x => !x.isCurrent)];

        const statements = categories.map((c) => {
          return {
            text: this.translateService.instant(this.translationKey + '.TECH_CHART.STATEMENT', {
              structure: c.name,
              N: `${this.formatNumber(c.premiumMin)} - ${this.formatNumber(c.premiumMax)}`,
            })
          };
        });

        return {
          selected: selectedRisk.name,
          statements,
          isCaptiveVisisble: pricingModel.captive.isVisible,
          tab: !isSameRiskSeleted ? this.tabs.market : selectedTab,
          options: this.getOptions(selectedTab, categories)
        };
      }));
  }

  getOptions(selectedTab: number, categories: PricingStructureItem[]) {
    const numberFormatter = (value) => this.formatNumber(value);

    const series = categories.reduce((pre, curr: PricingStructureItem) => {
      return [
        [...pre[0], [curr.premiumMin, curr.premiumMax]],
        [...pre[1], [curr.expiringPremium]],
        [...pre[2], [curr.renewalPremium]]
      ];
    }, [[], [], []]);

    return {
      chart: {
        type: 'columnrange',
        backgroundColor: 'transparent'
      },
      credits: {
        text: ''
      },
      title: {
        text: selectedTab === this.tabs.market ? this.translateService.instant(this.translationKey + '.TECH_CHART.TITLE.MARKET')
          : this.translateService.instant(this.translationKey + '.TECH_CHART.TITLE.CAPTIVE'),
        style: {
          fontSize: '24px',
          color: '#707070'
        }
      },
      subtitle: {
        text: selectedTab === this.tabs.market ? this.translateService.instant(this.translationKey + '.TECH_CHART.TITLE.MARKET_SUB_TITLE')
        : this.translateService.instant(this.translationKey + '.TECH_CHART.TITLE.CAPTIVE_SUB_TITLE'),
        style: {
            fontSize: '13.5px',
        },
        align: 'left' 
     },
      xAxis: {
        categories: categories.map(risk => `${risk.name} <br> <span style='font-size: 12px'>${ risk.label ? risk.label : ''}</span>`),
        labels: {
          style: {
            fontSize: '15px',
            color: '#000'
          }
        },
        title: {
          text: this.translateService.instant(this.translationKey + '.TECH_CHART.XAXIS'),
          style: {
            fontWeight: 'bold',
            fontSize: '12px'
          }
        },
      },
      yAxis: {
        min: 0,
        lineWidth: 1,
        gridLineWidth: 0,
        title: {
          text: this.translateService.instant(this.translationKey + '.TECH_CHART.YAXIS'),
          style: {
            fontWeight: 'bold',
            fontSize: '12px'
          }
        },
        labels: {
          enabled: true,
          formatter: function () {
            return numberFormatter(this.value);
          }
        }
      },
      tooltip: {
        shared: false,
        formatter: function () {
          const value1 = numberFormatter(this.point.low);
          const value2 = numberFormatter(this.point.high);
          return ((!value1 && !value2) || (value1 === 'N/A' && value2 === 'N/A')) ?
            `<span>${this.series.name}</span>: <b>${numberFormatter(this.point.y)}</b><br/>`
            : `<span>${this.series.name}</span>: <b>${value1}</b> - <b>${value2}</b><br/>`;
        }
      },
      legend: {
        reversed: false,
        enabled: true,
        verticalAlign: 'top',
        symbolRadius: 0,
        symbolPadding: 20,
        style: {
          fontSize: '16px',
          color: '#707070'
        },
        itemHoverStyle: {
          cursor: 'default'
        }
      },
      plotOptions: {
        columnrange: {
            events: {
                legendItemClick: function () {
                    return false; 
                }
            }
        },
        scatter: {
          events: {
              legendItemClick: function () {
                  return false; 
              }
          }
        } 
      },
      series: [{
        name: this.translateService.instant(this.translationKey + '.TECH_CHART.TECHNICAL_RANGE'),
        color: '#9A0D80',
        data: series[0]
      }, {
        type: 'scatter',
        name: this.translateService.instant(this.translationKey + '.TECH_CHART.EXPIRING_PREMIUM',
          { ['yyyy']: new Date().getFullYear() - 1 }),
        color: '#D764C1',
        data: series[1],
        marker: {
          symbol: 'url(../../../../../../../assets/images/rectangle_expiring.svg)',
          states: {
            hover: {
              enabled: false,
              lineColor: 'rgb(100,100,100)'
            }
          }
        }
      }, {
        type: 'scatter',
        name: this.translateService.instant(this.translationKey + '.TECH_CHART.RENEWAL_PREMIUM',
          { ['yyyy']: new Date().getFullYear() }),
        color: '#ECB7E2',
        data: series[2],
        marker: {
          symbol: 'url(../../../../../../../assets/images/rectangle_renewal.svg)',
          states: {
            hover: {
              enabled: false,
              lineColor: 'rgb(100,100,100)'
            }
          }

        }
      }]
    };
  }

  formatNumber(value: number) {
    return new ShortNumberFormatPipe().transform(value);
  }

  selectTab(selected: number) {
    this.selectedTab.next(selected === this.tabs.market ? this.tabs.captive : this.tabs.market);
  }

}
