<div e2eGroup="risk-summary-ccor" class="row" [ngStyle]="{ width: 'calc(100vw - 110px)' }" *ngIf="viewModel | async as vm">
    <div class=" col-md-12">
        <div class="pt-4 pb-4">
            <div class="row">
                <div class=" col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate"></div>
                </div>
            </div>
            <div class="row mt-4">
                <div class=" col-md-12">
                    <div e2eItem="tab-chart" class="tab ccor-chart" [ngClass]="{'selected': vm.isChartSelected}" (click)="selectChartTab()"
                        [innerHTML]="translationKey + '.TABS.CHART' | translate">
                    </div>
                    <div e2eItem="tab-table" class="tab ccor-table" [ngClass]="{'selected': !vm.isChartSelected}" (click)="selectTableTab()"
                        [innerHTML]="translationKey + '.TABS.TABLE' | translate"></div>
                </div>
            </div>
            <div class="row mt-4 mb-4" *ngIf="!vm.isChartSelected">
                <div class="col-md-12 horizontal-scroll pr-1">
                    <table e2eItem="table-data" class="main-table" summary="structure">
                        <th id="header1"></th>
                        <th *ngFor="let structure of vm.selected.ccor.table" id="header-{{getName(structure)}}">{{getName(structure)}}</th>
                        <tr>
                            <td class="header"></td>
                            <td *ngFor="let structure of vm.selected.ccor.table" class="header">{{structure.label}}</td>
                        </tr>
                        <tr>
                            <td class="row-header">
                                {{translationKey + '.RETAINED_MEAN' | translate}}
                            </td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.retained |
                                shortNumberFormat}}</td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.PREMIUM' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.premium |
                                shortNumberFormat}}</td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.COST_OF_VOLATILITY' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.costOfVolatility |
                                shortNumberFormat}}</td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.CCOR' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.ccor | shortNumberFormat}}
                            </td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.VALUE_FROM_INSURANCE' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.valueFromInsurance |
                                shortNumberFormat}}</td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.PREMIUM_ROI' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table">{{structure.roi | percentageFormatter }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row mt-4 mb-4" *ngIf="vm.isChartSelected">
                <div class="col-md-12 horizontal-scroll pr-1">
                    <table e2eItem="table-chart" class="main-table" summary="structure">
                        <th id="header1"></th>
                        <th *ngFor="let structure of vm.selected.ccor.table" id="header-{{getName(structure)}}">{{getName(structure)}}</th>
                        <tr>
                            <td class="header"></td>
                            <td *ngFor="let structure of vm.selected.ccor.table" class="header">{{structure.label}}</td>
                        </tr>
                        <tr>
                            <td class="row-header">
                                <div class="legend-wrapper">
                                    <div class="mb-2 legend-item" [class.legendDisabled]="!legend.enabled" *ngFor="let legend of vm.legends" (click)="toggleSeries(legend)">
                                        <div class="legend-st" [style.background]="legend.colour"></div>
                                        <div class="fs-14" [innerHTML]="legend.name"></div>
                                    </div>
                                </div>
                            </td>
                            <td *ngFor="let structure of vm.selected.ccor.table">
                                <dtcor-portfolio-chart [item]="structure" [translationKey]="translationKey"
                                    [colours]="vm.colours" [maxCCOR]="vm.maxCCOR" [toggleSeries]="vm.toggleSeries">
                                </dtcor-portfolio-chart>
                            </td>
                        </tr>
                        <tr>
                            <td class="row-header"> {{translationKey + '.PREMIUM_ROI' | translate}}</td>
                            <td *ngFor="let structure of vm.selected.ccor.table" class="roi">{{structure.roi |
                                percentageFormatter }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class=" col-md-12 mb-4 mt-4"></div>
</div>