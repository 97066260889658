<div e2eGroup="risk-summary-pricing" class="row mb-5" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate"></div>
                    <div class="mt-3">
                        <tabset #staticTabs>
                            <tab [active]="vm.tab === tabs.market" (selectTab)="selectTab(vm.tab)">
                                <ng-template tabHeading>
                                    <span e2eItem="tab-market">
                                        {{ translationKey + '.TABS.MARKET.TITLE' | translate }} 
                                    </span>
                                </ng-template>
                            </tab>                         
                            <tab *ngIf="!!vm.isCaptiveVisisble" [active]="vm.tab === tabs.captive" (selectTab)="selectTab(vm.tab)">
                                <ng-template tabHeading>
                                    <span e2eItem="tab-captive">
                                        {{ translationKey + '.TABS.CAPTIVE.TITLE' | translate }} 
                                    </span>
                                </ng-template>
                            </tab>
                        </tabset>
                        <div class="mt-3">
                            <div e2eItem="pricing-chart">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="vm.options"
                                    style="width: 90%; height: 600px; display: block; margin:0px auto;">
                                </highcharts-chart>
                            </div>
                            <div class="mt-3" e2eItem="statements">
                                <ul class="list">
                                    <li *ngFor="let statement of vm.statements" [innerHTML]="statement.text">
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>