<div>
    <div class="text-content">{{name}}</div>
    <div class="menu-button"></div>
    <div id="context-menu-{{name}}" class="dropdown-content">
        <ul>
            <li class="overview-context-menu-select" *ngIf="isSelectVisible" (click)="select()">
                <span>{{(translationKey+'.SELECT' | translate)}}</span>
            </li>
            <li class="overview-context-menu-view" *ngIf="isEditVisible" (click)="edit()">
                <span *ngIf="!isViewVisible">{{(translationKey+'.EDIT' | translate)}}</span>
                <span *ngIf="isViewVisible">{{(translationKey+'.VIEW' | translate)}}</span>
            </li>
            <li class="overview-context-menu-remove" *ngIf="isRemoveVisible"
                (click)="delete()">
                <span>{{(translationKey+'.DELETE' | translate)}}</span>
            </li>
        </ul>
    </div>
</div>