import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RiskSummaryService } from './risk-summary.service';
import { RiskType } from '../../api/dtos';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReportModalComponent } from 'app/shared/components/report-modal/report-modal.component';
import { PermissionService } from 'app/services/permissions.service';

@Component({
  selector: 'dtcor-risk-summary',
  templateUrl: './risk-summary.component.html',
  styleUrls: ['./risk-summary.component.scss']
})
export class RiskSummaryComponent implements OnInit {

  public translationKey = 'CURRENT_MODEL.RISK_SUMMARY';
  viewModel;

  constructor(
    @Inject(Router) private _router: Router,
    private _translateService: TranslateService,
    private riskSummaryService: RiskSummaryService,
    private _modalService: BsModalService,
    private _permissionService: PermissionService
  ) {
    const params = this._router.getCurrentNavigation()?.extras;
    if (params?.state) {
      this.riskSummaryService.selectedRiskChanged(null, params?.state.riskName);
    }
  }

  ngOnInit(): void {
    this._permissionService.init();

    this.viewModel = combineLatest([
      this.riskSummaryService.selectedRisk,
      this.riskSummaryService.risks])
      .pipe(map(([selectedRisk, risks]) => {        
        const selected = this.getSelectedRisk(selectedRisk, risks);
        return {
          riskSummaryViewModel: risks,
          riskTypeOptions: risks,
          topRiskSelected: selected?.name,
          selectedRiskType: selected,
          riskDescription: this._translateService.instant(this.translationKey + '.RISK_DESC'),
          userHasAccess: this._permissionService.hasAccessToPricingTabPerRisk(),
          canAccessHistoricalLosses: this._permissionService.hasFullAccessToHistoricalLossesTab()
        };
      }));
  }

  onViewExecSummary() {
    this.riskSummaryService.gotoSummarySection();
  }

  changeRiskType(riskTypes: RiskType[], selectedId: string) {
    const selected = riskTypes.find(x => x.id.toString() === selectedId);
    this.riskSummaryService.selectedRiskChanged(selected);
  }

  downloadReport() {
    this._modalService.show(ReportModalComponent, {
      class: 'modal-lg'
    });
  }

  onPricingTabSelect() {
    if (!this._permissionService.hasAccessToPricingTabPerRisk()) {
      this._permissionService.showModal();
    }
  }

  onTabAction(tab: string, isSelected: boolean) {
    this.riskSummaryService.selectedTabChanged(tab, isSelected);
  }

  private getSelectedRisk(riskType: RiskType, riskTypes: RiskType[]) {
    return riskTypes.find(x => x.id === riskType.id);
  }
}
