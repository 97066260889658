
/* eslint-disable */
import * as Dto from '../dtos';
import { HttpResponse } from '@angular/common/http';
import { ProxyHttp, ApiResult } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class ReportsProxy {
    constructor(private _phttp: ProxyHttp) { }


    create(runViewModel: Dto.RunViewModel): Observable<any> {
        return this._phttp.post(true, `/proxy/binary/reports/ppt`, runViewModel || {}, { responseType: 'blob' }).pipe(map((ret: HttpResponse<any>) => {
            console.log(ret);
            return { data: ret.body, originalResponse: ret }
        }));
    }

}


