<div class="row" e2eGroup="executive-summary-risk-tolerance">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="translationKey + '.CONTENT' | translate"></div>      
                </div>
            </div>                   
        </div>        
    </div>
</div>