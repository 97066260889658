import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { PortfolioComparisonItem, PortfolioRisk, SelectedPortfolioRiskStartegyGroup } from 'app/api/dtos';
import { ModalWrapperService } from 'app/services/modal-wrapper.service';

@Component({
    selector: 'dtcor-portfolio-add-edit',
    templateUrl: './add-edit-portfolio.component.html',
    styleUrls: ['./add-edit-portfolio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditPortfolioComponent implements OnChanges {

    translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PORTFOLIO_COMPARISON.POPUP';
    @Output() addEvent = new EventEmitter<PortfolioComparisonItem>();
    @Output() editEvent = new EventEmitter<PortfolioComparisonItem>();
    @Output() closeEvent = new EventEmitter();
    @Input() portfolio: PortfolioComparisonItem;
    @Input() risks: PortfolioRisk[];
    @Input() portfolioNames: string[];
    portfolioName: string;
    riskList: DisplayPortfolioRisk[];
    private groupIdHash: { [key: string]: number } = {};
    private isNew = false;

    constructor(private modalService: ModalWrapperService,
        private translateService: TranslateService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['portfolio']?.currentValue) {
            this.portfolioName = changes['portfolio'].currentValue.label;
            this.isNew = !this.portfolioName || this.portfolioName.length === 0;
            this.portfolioNames = this.portfolioNames.filter(x => x !== this.portfolioName);
        }
        if (changes['risks']?.currentValue) {
            const risksCurrentValue = changes['risks'].currentValue as PortfolioRisk[];
            this.riskList = risksCurrentValue.map(risk => ({
                ...risk,
                selected: this.getSelectedStrategyGroupHash(risk.id, this.portfolio.portfolioRiskStartegyGroups),
                isError: false
            }));

            this.groupIdHash = risksCurrentValue
                .map(risk => risk.strategyGroups)
                .reduce((acc, risk) => [...acc, ...risk], [])
                .reduce((acc, group) => {
                    return acc[group.hash] ? acc : { ...acc, [group.hash]: group.id }
                }, {});
        }
    }

    save() {
        const portfolio = {
            ...this.portfolio,
            label: this.portfolioName,
            portfolioRiskStartegyGroups: this.riskList.map(risk => ({
                riskId: risk.id,
                hash: risk.selected,
                strategyIds: risk.strategyGroups.find(x => x.hash === risk.selected)?.strategyIds ?? [],
                strategyGroupId: this.groupIdHash[risk.selected]
            }))
        } as PortfolioComparisonItem;
        if (this.isNew) {
            this.addEvent.emit(portfolio);
        } else if (this.portfolio.isSelected) {
            this.conditionalEdit(portfolio);
        } else {
            this.editEvent.emit(portfolio);
        }
    }

    cancel() {
        this.closeEvent.emit();
    }

    selected(hash: string, riskId: number) {
        this.selectRiskInRiskList(riskId, hash);
    }

    private selectRiskInRiskList(riskId: number, hash: string) {
        this.riskList = this.riskList.map(risk => {
            return risk.id !== riskId ? risk : {
                ...risk,
                selected: hash,
                isError: hash === '-1'
            };
        });
    }

    blur(hash: string, riskId: number){
        this.selectRiskInRiskList(riskId, hash);
    }

    isSaveEnabled() {
        return !this.portfolio.isCurrent &&
            (this.portfolioName.length > 0 && this.riskList.filter(x => x.selected === `-1`).length === 0)
            && this.isUnique();
    }

    isUnique() {
        return this.portfolioNames.filter(x => x && this.portfolioName && x.toLowerCase() === this.portfolioName.toLowerCase()).length === 0
    }

    private getSelectedStrategyGroupHash(riskId: number,
        portfolioRiskStartegyGroups: SelectedPortfolioRiskStartegyGroup[]) {
        const defaultHash = '-1';
        if (portfolioRiskStartegyGroups) {
            const selected = portfolioRiskStartegyGroups.find(x => x.riskId === riskId)?.hash
            return selected ?? defaultHash;
        }
        return defaultHash;
    }

    private conditionalEdit(portfolio: PortfolioComparisonItem) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant(`${this.translationKey}.EDIT_MODAL.OK`),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this.translateService.instant('GLOBAL.BTN.CANCEL'),
            cssClass: 'btn btn-secondary'
        };
        const confirmConfig = <IStandardModalConfig>{
            title: this.translateService.instant(`${this.translationKey}.EDIT_MODAL.TITLE`),
            message: this.translateService.instant(`${this.translationKey}.EDIT_MODAL.MESSAGE`),
            cancelButton: cancelConfig,
            yesButton: yesConfig,
            noButton: { display: false }
        };
        this.modalService.confirm(confirmConfig).then((yesClicked) => {
            if (yesClicked) {
                this.editEvent.emit(portfolio);
            }
        });
    }
}

export interface DisplayPortfolioRisk extends PortfolioRisk {
    selected: string;
    isError: boolean;
}
