/* eslint-disable */

import { RESET_STATE } from './runviewmodel.action';
import { createReducer, on } from '@ngrx/store';
//import { UpdateClientDetails, UpdateClientName } from './runviewmodel.action';
import { RunViewModel } from '../../api/generated/RunViewModel';

export function resetReducer(reducer) {
  return function (state, action) {
    if (action.type === RESET_STATE) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const initialState: RunViewModel = {
  executiveSummary: null,
  riskSummary: null,
  projectId: null,
  clientName: null,
  runValidationGUID: null,
  filePrefix: null
};

const _runviewmodelReducer = createReducer<RunViewModel>(
  initialState
);

export function runviewmodelReducer(state, action) {
  return _runviewmodelReducer(state, action);
}
