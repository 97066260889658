import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ShortNumberFormatPipe } from '../../../../pipes/short-number-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ExecutiveSummaryService } from '../../executive-summary.service';
import { HistoricalLoss, Loss } from 'app/api/dtos';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { clone, filter } from 'lodash';


@Component({
    selector: 'dtcor-historical-losses',
    templateUrl: './historical-losses.component.html',
    styleUrls: ['./historical-losses.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoricalLossesComponent implements OnInit {

    translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.HISTORICAL_LOSSES';
    viewModel;
    isChartSelected: Observable<boolean> = of(true);
    search: string;

    private tabs = {
        chart: 'chart',
        table: 'table'
    };
    private tabChanged = new BehaviorSubject(this.tabs.chart);
    private searchChanged = new BehaviorSubject('');

    constructor(private translateService: TranslateService,
        private executveSummaryService: ExecutiveSummaryService) {
    }

    ngOnInit(): void {
        this.viewModel = combineLatest([this.executveSummaryService.executiveSummaryViewModel, this.searchChanged])
            .pipe(map(([executiveSummaryService, searchTerm]) => {
                let years = [], table = [];
                let max, totalLoss, totalAdjustedLoss, min = 0;
                const historicalLosses = clone(executiveSummaryService.historicalLosses);

                if (historicalLosses && historicalLosses.table) {
                    table = historicalLosses.table;
                    totalLoss = historicalLosses.totalLoss;
                    totalAdjustedLoss = historicalLosses.totalAdjustedLoss;

                    if(searchTerm) {
                        historicalLosses.table = filter(historicalLosses.table, function(_){
                            return _.riskType.toLowerCase().includes(searchTerm.toLowerCase())
                             || _.orderedLosses.some(x => x.year.toString().match(searchTerm));
                        });
                    }
                }

                if (table.length > 0) {
                    max = table[0].loss.reduce((pre, curr) => curr.year > pre ? curr.year : pre, 0);
                    min = table[0].loss.reduce((pre, curr) => curr.year < pre ? curr.year : pre, 30000);
                    years = table[0].loss.map(x => x.year.toString());
                }

                const totalAverageLoss = (totalLoss / years.length);
                const adjustedAnnualLoss = totalAdjustedLoss / years.length;

                return {
                    statements: this.getStatements(table, min, max),
                    chartData: executiveSummaryService.historicalLosses,
                    tableData: historicalLosses,
                    isDataPopulated: this.checkIfHistoricalLossDataExists(table),
                    grossloss: this.getGrossLossSatement('AVERAGE_GROSS_LOSS_STATEMENT', totalAverageLoss, min, max),
                    adjusted: this.getGrossLossSatement('ADJUSTED_AVERAGE_GROSS_LOSS_STATEMENT', adjustedAnnualLoss, min, max),
                };
            }));
        this.isChartSelected = this.tabChanged.pipe(map(tab => this.tabs.chart === tab));
    }

    checkIfHistoricalLossDataExists(data: HistoricalLoss[]): boolean {
        let isHistoricalDataPopulated;

        data.forEach(risk => {
            isHistoricalDataPopulated = risk.loss.length > 0;
        });
        return isHistoricalDataPopulated;
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    selectChartTab() {
        this.tabChanged.next(this.tabs.chart);
    }

    selectTableTab() {
        this.tabChanged.next(this.tabs.table);
    }
    
    onSearch() {
        this.searchChanged.next(this.search);
    }

    reset() {
        this.search = '';
        this.searchChanged.next('');
    }

    riskClicked(risk: string) {
        this.executveSummaryService.selectRisk(risk);
    }

    private getStatements(data: HistoricalLoss[], min: number, max: number) {
        return data.map(risk => ({
            risk: risk.riskType,
            statement: this.getGrossLossSatement('GROSS_LOSS_STATEMENT', this.getAverageAnnualLoss(risk.loss), min, max)
        }));
    }

    private getAverageAnnualLoss(losses: Loss[]) {
        const numberOfYears = losses.map(x => x.year).length;
        const total = losses.reduce((pre, curr) => curr.incurredTotal + pre, 0);
        return total / numberOfYears;
    }

    private getGrossLossSatement(key: string, averageLoss: number, min: number, max: number) {
        return this.translateService.instant(this.translationKey + '.' + key,
            {
                ['gross_loss_value']: this.formatNumber(averageLoss),
                ['from']: min,
                ['to']: max,
            });
    }
}
