import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Percentile } from 'app/api/dtos';
import { catchError, isEmpty, map } from 'rxjs/operators';
import { ExecutiveSummaryVM, PercentileVM, PortfolioComparisonItem } from 'app/api/generated/ExecutiveSummaryViewModel';

import { DtcorDataService } from 'app/services/dtcor-data.service';
import { ReferenceDataService } from 'app/services/reference-data.service';

@Injectable({
    providedIn: 'root'
})
export class ExecutiveSummaryService {

    yearTypeText = {
        severe: '',
        typical: '',
        mean: ''
    };

    isSevereYear: Observable<boolean>;
    typicalPercentileChanged = new Subject<PercentileVM>();
    severePercentileChanged = new Subject<PercentileVM>();
    executiveSummaryViewModel: Observable<ExecutiveSummaryVM>;
    selectedTab: BehaviorSubject<{ tab: string, isSelected: boolean }>;
    typicalPercentiles: Percentile[];
    severePercentiles: Percentile[];
    errors = new Subject<string>();
    private translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY';
    private _isSevereYear = new BehaviorSubject<boolean>(false);
    private _executiveSummaryViewModel: ExecutiveSummaryVM;
    private _selectedSeverePercentileId: number;
    private _selectedTypicalPercentileId: number;

    constructor(private translateService: TranslateService,
        private dtcorDataService: DtcorDataService,
        private referenceDataService: ReferenceDataService) {
        this.setTranslations();
        this.isSevereYear = this._isSevereYear.pipe(map(value => value));
        this.typicalPercentileChanged = new BehaviorSubject(null);
        this.severePercentileChanged = new BehaviorSubject(null);
        this.selectedTab = new BehaviorSubject<{ tab: string, isSelected: boolean }>({ tab: '', isSelected: false });
        this.executiveSummaryViewModel = dtcorDataService.runViewModel
            .pipe(map(value => {
                const executiveSummaryViewModel = value.executiveSummary;
                this.severePercentiles = this.convertToPercentiles(executiveSummaryViewModel.severePercentile);
                this.typicalPercentiles = this.convertToPercentiles(executiveSummaryViewModel.typicalPercentile);
                this._selectedSeverePercentileId = executiveSummaryViewModel.selectedSeverePercentileId;
                this._selectedTypicalPercentileId = executiveSummaryViewModel.selectedTypicalPercentileId;
                this.typicalPercentileChanged.next(executiveSummaryViewModel.typicalPercentile[this._selectedTypicalPercentileId]);
                this.severePercentileChanged.next(executiveSummaryViewModel.severePercentile[this._selectedSeverePercentileId]);
                this._executiveSummaryViewModel = executiveSummaryViewModel;
                return executiveSummaryViewModel;
            }));
    }

    setIsSevereYear(severeYear: boolean) {
        this._isSevereYear.next(severeYear);
    }

    getSelectedPercentileViewModel(isSevereYear: boolean,
        severePercentileId: number,
        typicalPercentiLeId: number): PercentileVM {
        return isSevereYear ?
            this._executiveSummaryViewModel.severePercentile[severePercentileId]
            : this._executiveSummaryViewModel.typicalPercentile[typicalPercentiLeId];
    }

    getPercetile(isSevereYear: boolean,
        severePercentileId: number,
        typicalPercentiLeId: number): Percentile {
        return isSevereYear ? this.severePercentiles.find(x => x.percentileId === severePercentileId)
            : this.typicalPercentiles.find(x => x.percentileId === typicalPercentiLeId);
    }

    getTypicalPercetileDisplayLabel(id: number): string {
        return this.typicalPercentiles.find(x => x.percentileId === id).percentileDisplayLabel;
    }

    getSeverePercetileDisplayLabel(id: number): string {
        return this.severePercentiles.find(x => x.percentileId === id).percentileDisplayLabel;
    }

    percentileChanged(id: number, isSevereYear: boolean) {
        if (isSevereYear) {
            this.severePercentileChanged.next(this._executiveSummaryViewModel.severePercentile[id]);
        } else {
            this.typicalPercentileChanged.next(this._executiveSummaryViewModel.typicalPercentile[id]);
        }
    }

    getArrayFromDictionary(dictionary: object): PercentileVM[] {
        const arr = Object.keys(dictionary).map(x => dictionary[x]);
        return [...arr.filter(x => !x.percentage), ...arr.filter(x => x.percentage)];
    }

    deletePortfolio(portfolio: PortfolioComparisonItem) {
        return this.dtcorDataService
            .deletePortfolio(portfolio, this.getSelectedPercentileIds())
            .pipe(catchError(() => {
                this.errors.next('CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PORTFOLIO_COMPARISON.ERRORS.REMOVE_PORTFOLIO');
                return EMPTY;
            }))
            .pipe(isEmpty(),);
    }

    addEditPortfolio(portfolio: PortfolioComparisonItem, isAdd: boolean) {
        return this.dtcorDataService
            .addEditPortfolio(portfolio, isAdd, this.getSelectedPercentileIds())
            .pipe(catchError(() => {
                const errorKey = isAdd ? 'ADD_PORTFOLIO' : 'EDIT_PORTFOLIO';
                this.errors.next('CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PORTFOLIO_COMPARISON.ERRORS.' + errorKey);
                return EMPTY;
            }))
            .pipe(isEmpty(),);
    }

    selectPortfolio(portfolio: PortfolioComparisonItem) {
        return this.dtcorDataService
            .selectPortfolio(portfolio, this.getSelectedPercentileIds())
            .pipe(catchError(() => {
                this.errors.next('CURRENT_MODEL.EXECUTIVE_SUMMARY.TABS.PORTFOLIO_COMPARISON.ERRORS.SELECT_PORTFOLIO');
                return EMPTY;
            }))
            .pipe(isEmpty(),);
    }

    selectRisk(risk: string) {
        this.dtcorDataService.selectRisk(risk);
    }

    selectedTabChanged(tab: string, isSelected: boolean) {        
        this.selectedTab.next({ tab, isSelected });
    }

    private getSelectedPercentileIds() {
        return { severe: this._selectedSeverePercentileId, typical: this._selectedTypicalPercentileId };
    }

    private setTranslations() {
        this.yearTypeText.severe = this.translateService.instant(this.translationKey + '.YEAR_TYPE.SEVERE');
        this.yearTypeText.typical = this.translateService.instant(this.translationKey + '.YEAR_TYPE.TYPICAL');
        this.yearTypeText.mean = this.translateService.instant(this.translationKey + '.YEAR_TYPE.MEAN');
    }

    private convertToPercentiles(value): Percentile[] {
        return this.getArrayFromDictionary(value)
            .map(x => this.referenceDataService
                .createPercentile(x.id,
                    x.percentage,
                    x.label));
    }
}
