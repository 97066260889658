import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ShortNumberFormatPipe } from '../../../pipes/short-number-formatter.pipe';

import { APP_CONSTANTS } from 'app/shared/helpers/ApplicationConstants';
import { TopDriversVM } from 'app/api/generated/ExecutiveSummaryViewModel';

@Component({
    selector: 'dtcor-top-drivers',
    templateUrl: './top-drivers.component.html',
    styleUrls: ['./top-drivers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopDriversComponent implements OnInit, OnChanges {

    Highcharts: typeof Highcharts = Highcharts;
    topDriversSwitch: any = {
        tcor: 1,
        totalRetained: 2,
        premiumExpense: 3
    };

    @Input() risks: TopDriversVM[];
    @Input() hasExpenseKPI: boolean;
    @Input() isSevere: boolean;
    @Input() topRisk: string;
    @Output() selectRisk = new EventEmitter<string>();

    options: Observable<Highcharts.Options>;
    selectedTab: BehaviorSubject<number>;
    topDriversChart: Highcharts.Chart;
    header: string;
    subHeader1: string;
    subHeader2: string;
    translationKey: string = 'CURRENT_MODEL.EXECUTIVE_SUMMARY.TOP_DRIVERS';

    expensesSeries = {
        name: 'Expenses',
        color: APP_CONSTANTS.colors.expense,
        type: 'column'
    };

    totalRetainedSeries = {
        name: 'Total Retained',
        color: APP_CONSTANTS.colors.totalRetained,
        type: 'column'
    };

    premiumSeries = {
        name: 'Premium',
        color: APP_CONSTANTS.colors.premium,
        type: 'column'
    };

    private risksChanged: BehaviorSubject<TopDriversVM[]>;

    constructor(private translateService: TranslateService) {
        // empty block
    }

    ngOnInit(): void {
        this.totalRetainedSeries.name = this.translateService.instant(this.translationKey + '.TOTAL_RETAINED');
        this.premiumSeries.name = this.translateService.instant(this.translationKey + '.PREMIUM');
        this.expensesSeries.name = this.translateService.instant(this.translationKey + '.EXPENSE');
    }

    ngOnChanges(changes: SimpleChanges): void {
        const selecteType = this.isSevere ? this.translateService.instant(this.translationKey + '.SEVERE')
            : this.translateService.instant(this.translationKey + '.TYPICAL')
        const currentValue: TopDriversVM[] = changes['risks'].currentValue;

        if (currentValue && currentValue !== changes['risks'].previousValue) {
            if (!this.risksChanged) {
                this.risksChanged = new BehaviorSubject(currentValue);
            } else {
                this.risksChanged.next(currentValue);
            }

            if (!this.selectedTab) {
                this.selectedTab = new BehaviorSubject(this.topDriversSwitch.tcor);
            }
            
            this.options = combineLatest([this.selectedTab, this.risksChanged])
                .pipe(map(([selectedTab, risks]) => {
                    const topRisks = this.sortRisks(selectedTab, risks);
                    const categories = topRisks.map(risk => risk.risk);
                    const series = this.getSeries(selectedTab, topRisks);
                    return this.generateTopDriversChart(series, categories, selectedTab);
                }));

            const topRisks = this.sortRisks(this.topDriversSwitch.tcor, currentValue);
            const topRisk = topRisks[0];

            this.header = this.translateService.instant(this.translationKey + '.TITLE', {
                ['selectedType']: selecteType,
                ['count']: topRisks.length
            });

            this.subHeader2 = this.translateService.instant(this.translationKey + '.SUB_TITLE_P2', {
                ['tcor_value']: this.formatNumber(topRisk.tcor)
            });
        }

        this.subHeader1 = this.translateService.instant(this.translationKey + '.SUB_TITLE_P1', {
            ['selectedType']: selecteType.toLowerCase()
        });

    }

    sortRisks(type: number, risks: TopDriversVM[]): TopDriversVM[] {
        let sorted = Array<TopDriversVM>();
        switch (type) {
            case 1:
                sorted = risks.sort((a, b) => b.tcor - a.tcor);
                break;
            case 2:
                sorted = risks.sort((a, b) => b.retained - a.retained);
                break;
            default:
                sorted = risks.sort((a, b) => b.premiumAndExpenses - a.premiumAndExpenses);
                break;
        }
        return sorted.filter((risk, index) => index < 7);
    }

    topDriversChartInstance(chart: Highcharts.Chart) {
        this.topDriversChart = chart;
    }

    getSeries(type: number, risks: TopDriversVM[]): Highcharts.SeriesOptionsType[] {

        const series = risks.reduce((pre, curr) => {
            switch (type) {
                case 1:
                    return [[...this.getPreviousValue(pre[0]), curr.premium],
                    [...this.getPreviousValue(pre[1]), curr.expense],
                    [...this.getPreviousValue(pre[2]), curr.retained]];
                case 2:
                    return [[...this.getPreviousValue(pre[0]), curr.retained]];
                default:
                    return [[...this.getPreviousValue(pre[0]), curr.premium],
                    [...this.getPreviousValue(pre[1]), curr.expense]];
            }
        }, [[]])
            .reduce((pre, curr, index) => {
                switch (type) {
                    case 1:
                        return [...pre, { ...this.getValidSeries(type, index), data: curr }];
                    case 2:
                        return [...pre,
                        { ...this.getValidSeries(type, index), data: curr },
                        { ...this.premiumSeries, data: [] },
                        { ...this.totalRetainedSeries, data: [] }];
                    default:
                        return [...pre,
                        { ...this.getValidSeries(type, index), data: curr },
                        { ...this.totalRetainedSeries, data: [] }];
                }
            }, []);

        return series.length === 1 ? series : this.reverse(series);
    }

    getPreviousValue(value: TopDriversVM[]) {
        return value ?? [];
    }

    getValidSeries(type: number, index: number) {
        switch (type) {
            case 1:
                return index === 0 ? this.premiumSeries : index === 1 ? this.expensesSeries : this.totalRetainedSeries;
            case 2:
                return this.totalRetainedSeries;
            default:
                return index === 0 ? this.premiumSeries : this.expensesSeries;
        }
    }

    generateTopDriversChart(data: Highcharts.SeriesOptionsType[], categories: string[], selectedTab: number): any {
        const numberFormatter = (value) => this.formatNumber(value);

        return {
            chart: {
                type: 'bar',
                backgroundColor: 'transparent'
            },
            credits: {
                text: ''
            },
            title: {
                style: {
                    display: 'none'
                }
            },
            xAxis: {
                categories,
                lineWidth: 0,
                gridLineWidth: 0,
                labels: {
                    style: {
                        fontSize: '15px',
                        color: '#000'
                    }
                }
            },
            yAxis: {
                min: 0,
                lineWidth: 0,
                gridLineWidth: 0,
                title: {
                    text: 'Values',
                    style: {
                        display: 'none'
                    }
                },
                labels: {
                    enabled: false,
                },
                stackLabels: {
                    enabled: true,
                    align: 'right',
                    formatter: function () {
                        return numberFormatter(this.total)
                    }
                }
            },
            legend: {
                reversed: false,
                enabled: false
            },
            plotOptions: {
                series: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                enabled:false
            },
            series: data
        };
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    private reverse(value: TopDriversVM[]) {
        const arr = [];
        const dummyValues = [];
        for (let index = value.length - 1; index >= 0; index--) {
            if (value[index]['data']['length'] === 0) {
                dummyValues.push(value[index]);
            } else {
                arr.push(value[index]);
            }
        }
        return [...arr, ...dummyValues];
    }
}
