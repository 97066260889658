import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { DtcorAnalyticsService } from '../../services/dtcor-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Percentile } from '../../api/dtos';
import * as Highcharts from 'highcharts';
import Variablepie from 'highcharts/modules/variable-pie';
import { map, take } from 'rxjs/operators';
import { PermissionService } from '../../services/permissions.service';
import { ExecutiveSummaryService } from './executive-summary.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { camelCase } from 'lodash';
import { APP_CONSTANTS } from '../../shared/helpers/ApplicationConstants';
import { ShortNumberFormatPipe } from '../../pipes/short-number-formatter.pipe';
Variablepie(Highcharts);

import { BaseTable } from 'app/shared/models/BaseTable';
import { GraphPercentileViewModel, PercentileVM, TotalTcorVM, ValueFromInsuranceVM } from 'app/api/generated/ExecutiveSummaryViewModel';
import { PercentageFormatterPipe } from 'app/pipes/percentage.pipe';

@Component({
  selector: 'dtcor-executive-summary',
  templateUrl: './executive-summary.component.html',
  styleUrls: ['./executive-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExecutiveSummaryComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  @ViewChild('contentEl') contentEl: ElementRef;
  @ViewChild('insuranceValueGraphEl') insuranceValueGraphEl: ElementRef;

  colors: string[] = [APP_CONSTANTS.colors.totalRetained, APP_CONSTANTS.colors.premium,
  APP_CONSTANTS.colors.expense, ...Highcharts.getOptions().colors];

  public translationKey = 'CURRENT_MODEL.EXECUTIVE_SUMMARY';
  public translation: any = {};

  summaryChartOptions: Highcharts.Options;
  tcorChartOptions: Highcharts.Options;

  seeMore: any = {
    isActive: false,
    moreText: '',
    lessText: ''
  };

  tcorValueGraph: any = {
    threshold: {
      enabled: false,
      value: 0
    },
    optionType: {
      sever: 1,
      typical: 2,
      all: 3
    }
  };
  
  viewModelSource = false;

  summaryChart: Highcharts.Chart;
  tcorChart: Highcharts.Chart;
  translations: any = {
    precisionPercentage: '',
    nextSteps: []
  };

  showTypicalOptions: boolean;
  showSevereOptions: boolean;
  @Input() controlName: string;
  @Input() left: number;
  @Input() width: number;
  viewModel;
  isLoaded: Observable<boolean>;

  showTcorValueGraph = false;
  totalTcorTableVm;
  tcoreValueGraphViewModel;
  private _totalTcorTable: TotalTCORTable;
  private _selectedPercentile: Percentile;
  private _selectedTypicalPercentileViewModel: PercentileVM;
  private _selectedTotalTcor: TotalTcorVM[];
  private _selectedTypeChanged = new BehaviorSubject<number>(this.tcorValueGraph.optionType.typical);
  private _thresholdValueChanged = new BehaviorSubject<number>(0);
  private _hasExpenseKPI: boolean;
  private translateKeyPrefix = '.TCOR_GRAPH';

  constructor(private _dtcorAnalyticsService: DtcorAnalyticsService,
    private _translateService: TranslateService,
    @Inject(PermissionService) private _permissionService: PermissionService,    
    private _executiveSummaryService: ExecutiveSummaryService,
    @Inject(LOCALE_ID) private _locale: string) {
  }

  ngOnInit(): void {
    this._dtcorAnalyticsService.sendRunCompleted();
    this._permissionService.init();

    this.viewModel = this.getViewModel();
    this.tcoreValueGraphViewModel = this.getTCoreValueGraphViewModel();
    this.seeMore.moreText = this._translateService.instant(this.translationKey + '.TOP_DRIVERS.SEE_MORE');
    this.seeMore.lessText = this._translateService.instant(this.translationKey + '.TOP_DRIVERS.SEE_LESS');

    this.translations.precisionPercentage = this._translateService.instant(this.translationKey + '.PRECISION_PERCENTAGE');
    this._executiveSummaryService.errors
      .pipe(take(1))
      .subscribe(error => alert(this._translateService.instant(error)));
  }


  getHeaders(percentileDisplayLabel: string) {
    const columnKey = this.translationKey + '.TOTAL_TCOR_TABLE.COLUMNS';
    const headers: Array<any> = [];
    headers.push({
      text: this._translateService.instant(columnKey + '.RISK'),
      align: APP_CONSTANTS.align.LEFT, sortable: true, visible: true
    });
    headers.push({
      text: this._translateService.instant(columnKey + '.TOTAL_PREMIUM'),
      align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
    });
    headers.push({
      text: this._translateService.instant(columnKey + '.TOTAL_RETAINED',
        { percentile: percentileDisplayLabel }), align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
    });
    headers.push({
      text: this._translateService.instant(columnKey + '.OTHER_EXPENSES'),
      align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: this._hasExpenseKPI
    });
    headers.push({
      text: this._translateService.instant(columnKey + '.TCOR_PERCENTILE',
        { percentile: percentileDisplayLabel }), align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
    });
    headers.push({
      text: this._translateService.instant(columnKey + '.TCOR_PERCENTAGE'),
      align: APP_CONSTANTS.align.RIGHT, sortable: true, visible: true
    });
    headers.push({
      text: this.getViewModelSourceHeader(columnKey),
      align: APP_CONSTANTS.align.RIGHT,
      sortable: false,
      customClass: 'text-primary',
      visible: true,
      action: () => { this.toggleViewModelSource(); }
    });
    return headers;
  }

  getViewModelSourceHeader(columnKey: string) {
    let text = this._translateService.instant(columnKey + (!this.viewModelSource ? '.VIEW_MODEL_SOURCE' : '.MODEL_SOURCE'));

    if (!this._permissionService.hasFullAccessToViewModelSource()) {
      text = '<img class="ml-1" src="/assets/images/lock.svg" /> ' + text;
    }

    return text;
  }

  toggleViewModelSource() {
    if (this._permissionService.hasFullAccessToViewModelSource()) {
      this.viewModelSource = !this.viewModelSource;
      this._totalTcorTable.setHeaders(this.getHeaders(this._selectedPercentile.percentileDisplayLabel));
      this._totalTcorTable.setRisks(this.getRisks(this._selectedTotalTcor, this.viewModelSource));
      this._totalTcorTable.sortAscending(4);
      this.totalTcorTableVm = this._totalTcorTable.getViewModel();
    } else {
      this._permissionService.showModal();
    }
  }

  getRisks(risks: TotalTcorVM[], showModelSource: boolean = false) {
    const result = [];
    risks.forEach(risk => {
      result.push({
        [`risk`]: risk.risk,
        [`totalPremium`]: risk.premium,
        [`totalRetained`]: risk.retained,
        [`otherExpenses`]: risk.expense,
        [`tcor`]: risk.tcor,
        [`percentage`]: risk.tcorPercentage,
        [`modelSource`]: showModelSource ? risk.modelSource : '',
        isBold: false
      });
    });
    return result;
  }

  formatNumber(value: number) {
    return new ShortNumberFormatPipe().transform(value);
  }

  formatPercentage(value: number) {
    return new PercentageFormatterPipe(this._locale).transform(value);
  }

  summaryChartInstance(chart: Highcharts.Chart) {
    this.summaryChart = chart;
  }

  tcorChartInstance(chart: Highcharts.Chart) {
    this.tcorChart = chart;
  }

  generateSummaryChart(summary: any[], tcorValue: number) {

    const formatNumber = (value: number) => this.formatNumber(value);

    const data = summary.map(item => {
      return {
        name: this._translateService.instant(`${this.translationKey}.SUMMARY_${item.name}`),
        y: item.value,
        color: APP_CONSTANTS.colors[camelCase(item.name)],
        events: {
          legendItemClick: function (e) {
            e.preventDefault();
          }
        }
      };
    });

    this.summaryChartOptions = {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent'
      },
      credits: {
        text: ''
      },
      title: {
        text: formatNumber(tcorValue),
        verticalAlign: 'middle',
        align: 'center',
        floating: true,
        x: 0,
        style: {
          fontSize: '45px',
          color: '#585858'
        }
      },
      legend: {
        enabled: false,
        borderWidth: 0,
        align: 'left',
        verticalAlign: 'middle',
        layout: 'vertical',
        x: 0,
        y: 0,
        symbolRadius: 0,
        squareSymbol: false,
        itemMarginBottom: 8,
        itemStyle: {
          fontSize: '14px',
          fontWeight: 'normal',
          color: '#585858'
        }
      },
      plotOptions: {
        pie: {
          size: 250,
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          minSize: 10,
          innerSize: '70%',
          dataLabels: {
            enabled: true,
            formatter: function () {
              const value = formatNumber(this.y);
              return `${this.key} - ${value}`;
            },
            distance: 20
          }
        }
      },
      tooltip: {
        enabled: false,
        useHTML: true,
        formatter: function () {
          const value = formatNumber(this.y);
          return `<div class="dot" style="background-color:${this.color}"></div> <span class="text-white">${this.key}: <b>${value}</b></span><br/>`;
        }
      },
      series: [{
        type: 'pie',
        data: data
      }]
    };
  }

  onResize(event) {
    setTimeout(() => {
      this.tcorChart.setSize(this.insuranceValueGraphEl.nativeElement.offsetWidth - 15, this.contentEl.nativeElement.offsetHeight - 170);
    });
  }

  getTcorValueChartOptions(categories: GraphPercentileViewModel[],
    series: any[],
    plotline: Highcharts.AxisPlotLinesOptions) {
    const formatNumber = (value: number) => this.formatNumber(value);
    const translate = (value) => this._translateService.instant(this.translationKey + this.translateKeyPrefix + value);
    const getValue = (label: string, seriesLabel: string) => {
      const dataIndex = categories.findIndex(_ => `${_.percentage.toString()}th` === label);
      const seriesIndex = series.findIndex(_ => _.name === seriesLabel);
      return series[seriesIndex].data[dataIndex];
    };

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent'
      },
      credits: {
        text: ''
      },
      colors: this.colors,
      title: {
        text: '',
        align: 'left'
      },
      xAxis: {
        categories: categories.map(_ => `${_.percentage}th`),
        title: {
          text: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.XAXIS`)
        },
        labels: {
          formatter: function () {
            return `${this.value}`;
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.YAXIS`)
        },
        lineWidth: 1,
        gridLineWidth: 0,
        plotLines: plotline ? [plotline] : []
      },
      legend: {
        enabled: false
      },
      tooltip: {
        useHTML: true,
        backgroundColor: '#ECEDEE',
        borderWidth: 0,
        borderRadius: 0,
        className: 'tooltip-wrapper',
        followPointer: false,
        hideDelay: 0,
        formatter: function () {
          const retaindeLabel = translate('.TOTAL_RETAINED');
          const premiumLabel = translate('.PREMIUM');
          const expensesLabel = translate('.EXPENSES');
          const grossLossLabel = translate('.GROSS_LOSS');
          const tcorLabel = translate('.TCOR');
          return `<div style="background-color:#ECEDEE;border:none">
            <table style="background-color:#ECEDEE">
                <tr>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${retaindeLabel}</td>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${formatNumber(getValue(this.x, retaindeLabel))}</td>
                </tr>
                <tr>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${premiumLabel}</td>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${formatNumber(getValue(this.x, premiumLabel))}</td>
                </tr>
                <tr>
                    <td style="background-color:#ECEDEE;font-size:12px;border-bottom:1px solid #707070;color:#444444">${expensesLabel}</td>
                    <td style="background-color:#ECEDEE;font-size:12px;border-bottom:1px solid #707070;color:#444444">${formatNumber(getValue(this.x, expensesLabel))}</td>
                </tr>
                <tr>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${tcorLabel}</td>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${formatNumber(getValue(this.x, tcorLabel))}</td>
                </tr>
                <tr>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${grossLossLabel}</td>
                    <td style="background-color:#ECEDEE;font-size:12px;color:#444444">${formatNumber(getValue(this.x, grossLossLabel))}</td>
                </tr>
            </table>
        </div>`;
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        },
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        }
      },
      series
    };
  }

  updateRiskThreshold() {
    this.tcorValueGraph.threshold.value = this.tcorValueGraph.threshold.enabled ? this.tcorValueGraph.threshold.value : 0;
    this._thresholdValueChanged.next(this.tcorValueGraph.threshold.value);

    if (!this.tcorValueGraph.threshold.enabled) {
      this.tcorChart.yAxis[0].setExtremes(0, this.tcorChart.yAxis[0].getExtremes().dataMax);
      this.tcorChart.yAxis[0].removePlotLine('threshold');
      return;
    }

    this.updateTcorValueGraphYAxis();
  }

  updateTcorValueGraphYAxis() {
    if (+this.tcorChart.yAxis[0].getExtremes().dataMax <= +this.tcorValueGraph.threshold.value) {
      this.tcorChart.yAxis[0].setExtremes(0, this.tcorValueGraph.threshold.value);
    } else {
      this.tcorChart.yAxis[0].setExtremes(0, this.tcorChart.yAxis[0].getExtremes().dataMax);
    }
  }

  onToggleChange(enable: boolean) {
    this._executiveSummaryService.setIsSevereYear(enable);
  }

  toggleTcorValueGraph(el?: HTMLElement) {
    this.seeMore.isActive = false;
    this.showTcorValueGraph = !this.showTcorValueGraph;
    if (this.showTcorValueGraph) {
      setTimeout(() => {
        this.tcorChart.setSize(this.tcorChart.chartWidth, this.contentEl.nativeElement.offsetHeight - 170);
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    }
  }

  onTcorValueGraphPercentileChange(value: string) {
    this._selectedTypeChanged.next(parseInt(value));
    setTimeout(() => {
      this.updateTcorValueGraphYAxis();
    });
  }

  clickOutSide(isSevere: boolean) {
    if (isSevere) {
      this.showSevereOptions = false;
    } else {
      this.showTypicalOptions = false;
    }
  }

  onTypicalChange(percentile: Percentile) {
    this.clickOutSide(false);
    this._executiveSummaryService.percentileChanged(percentile.percentileId, false);
  }

  onSevereChange(e) {
    this._executiveSummaryService.percentileChanged(e.target.value, true);
  }

  onHistoricalLossesTabSelect() {
    // empty block
  }

  sortTotalTcorTable(event: { columnIndex: number, direction: number }) {
    if (event.direction === APP_CONSTANTS.sortDirection.ASC) {
      this._totalTcorTable.sortAscending(event.columnIndex);
    } else {
      this._totalTcorTable.sortDescending(event.columnIndex);
    }
    this.totalTcorTableVm = this._totalTcorTable.getViewModel();
  }

  selectRisk(risk: string) {
    this._executiveSummaryService.selectRisk(risk);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  onTabAction(tab: string, isSelected: boolean) {
    this._executiveSummaryService.selectedTabChanged(tab, isSelected);
  }

  private getViewModel() {
    return combineLatest([
      this._executiveSummaryService.isSevereYear,
      this._executiveSummaryService.severePercentileChanged,
      this._executiveSummaryService.typicalPercentileChanged,
      this._executiveSummaryService.executiveSummaryViewModel
    ])
      .pipe(map(([isSevereYear, severePercentile, typicalPercentile, executiveSummary]) => {
        if (!severePercentile || !typicalPercentile || !executiveSummary) { return; }

        const selectedPercentileViewModel = this._executiveSummaryService
          .getSelectedPercentileViewModel(isSevereYear, severePercentile.id, typicalPercentile.id);
        const selectedPercentile = this._executiveSummaryService.getPercetile(isSevereYear, severePercentile.id, typicalPercentile.id);

        this.generateSummaryChart(selectedPercentileViewModel.summary, selectedPercentileViewModel.tcorValue);

        this._selectedPercentile = selectedPercentile;
        this._selectedTotalTcor = selectedPercentileViewModel.totalTcor;
        this._hasExpenseKPI = selectedPercentileViewModel.hasExpenseKPI;
        this._totalTcorTable = new TotalTCORTable(this.translationKey, this._translateService,
          this.getHeaders(selectedPercentile.percentileDisplayLabel),
          this.getRisks(this._selectedTotalTcor, this.viewModelSource));
        this.totalTcorTableVm = this._totalTcorTable.getViewModel();

        this._selectedTypicalPercentileViewModel = typicalPercentile;        

        const tcorStatement = this._translateService.instant(this.translationKey + '.TCOR_STATEMENT',
          { ['expenses']: selectedPercentileViewModel.hasExpenseKPI ? this._translateService.instant(this.translationKey + '.TCOR_STATEMENT_EXPENSES') : ''
        });

        let nextSteps = [
          this._translateService.instant(this.translationKey + '.NEXT_STEPS.OPTIMIZE_MODELING')
        ];

        if (selectedPercentileViewModel.nextSteps.industryModelsTCORPercentage > 0) {
          nextSteps = [{
            HEADING: this._translateService.instant(this.translationKey + '.NEXT_STEPS.MODELING_PRECISION.HEADING'),
            CONTENT: this._translateService.instant(this.translationKey + '.NEXT_STEPS.MODELING_PRECISION.CONTENT', {
              N: this.formatNumber(selectedPercentileViewModel.nextSteps.industryModelsTCORPercentage),
              riskType: selectedPercentileViewModel.nextSteps.topRisk
            })
          },
          ...nextSteps];
        }

        if (executiveSummary.hasCaptive) {
          nextSteps = [{
            HEADING: this._translateService.instant(this.translationKey + '.NEXT_STEPS.CAPTIVE_MODELING.HEADING'),
            CONTENT: this._translateService.instant(this.translationKey + '.NEXT_STEPS.CAPTIVE_MODELING.CONTENT')
          },
          ...nextSteps];
        }

        const selectedYearLabel = isSevereYear ? this._translateService.instant(this.translationKey + '.SEVERE_YEAR') : this._translateService.instant(this.translationKey + '.TYPICAL_YEAR');

        this.translations.nextSteps = nextSteps;
        return {
          serverPercentileValue: this._executiveSummaryService.getSeverePercetileDisplayLabel(severePercentile.id),
          typicalPercentileValue: this._executiveSummaryService.getTypicalPercetileDisplayLabel(typicalPercentile.id),
          percentileStatementPart1: this._translateService.instant(this.translationKey + '.STATEMENT_PART_1', {
            ['portfolio']: executiveSummary.portfolio
          }),
          percentileStatementPart2: this._translateService.instant(this.translationKey + '.STATEMENT_PART_2', {
            tcorValue: this.formatNumber(typicalPercentile.tcorValue)
          }),
          isSevereYear,
          typicalPercentiles: this._executiveSummaryService.typicalPercentiles,
          severePercentiles: this._executiveSummaryService.severePercentiles,
          riskStatement: this._translateService.instant(this.translationKey + '.STATEMENT_RISK'),
          topRisk: selectedPercentileViewModel.topRiskName,
          tcorStatement: tcorStatement,
          percentileDisplayLabel: selectedPercentile.percentileDisplayLabel,
          risks: selectedPercentileViewModel.topDrivers,
          precisionRating: executiveSummary.precisionRating * 10,
          percentage: executiveSummary.portfolioLessThanUnhedgedPercentage,
          precisionRatingText: this.getPrecisionRatingText(selectedPercentileViewModel.unhedgedTcorPercentage),
          hasAccessToPrecisionRating: this._permissionService.hasAccessToPrecisionRating(),
          hasAccessToGoToNextSteps: this._permissionService.hasAccessToGoToNextSteps(),
          nextSteps: selectedPercentileViewModel.nextSteps,
          selectedSevereYearPercentileId: severePercentile.id,
          selectedTypicalYearPercentileId: typicalPercentile.id,
          hasExpenseKPI: selectedPercentileViewModel.hasExpenseKPI,
          canAccessHistoricalLosses: this._permissionService.hasFullAccessToHistoricalLossesTab(),
          topDriverStatement: this._translateService.instant(this.translationKey + '.TOP_DRIVERS.MODELED_RISKS_STATEMENT', { risks: selectedPercentileViewModel.topDrivers.length }),
          selectedYearLabel,
          donut: {
            heading: this._translateService.instant(`${this.translationKey}.DONUT_CHART.HEADING`, {
              year: selectedYearLabel
            }),
            description: this._translateService.instant(`${this.translationKey}.DONUT_CHART.DESCRIPTION`, {
              year: selectedYearLabel.toLowerCase(),
              tcor: this.formatNumber(selectedPercentileViewModel.tcorValue),
            }),
            summaryChartDetails: this._translateService.instant(`${this.translationKey}.DONUT_CHART.SUMMARY`, {
              value: this.formatNumber(selectedPercentileViewModel.transferred),
              expenses: selectedPercentileViewModel.hasExpenseKPI ? this._translateService.instant(this.translationKey + '.DONUT_CHART.SUMMARY_EXPENSE') : ''
            })
          }
        };
      }));
  }

  private getPrecisionRatingText(unhedgedPercentage: number) {
    const sanitizedValue = unhedgedPercentage < 0 ? unhedgedPercentage * -1 : unhedgedPercentage;
    const value = this.formatNumber(sanitizedValue);
    const key = unhedgedPercentage < 0 ? '.PRECISION_RATING_DECRESES' : `.PRECISION_RATING_INCRESES`;

    const precisionRatingText = this._permissionService.hasAccessToPrecisionRating() ?
      this._translateService.instant(this.translationKey + '.PRECISION_RATING', {
        [`decreses_increases`]: this._translateService.instant(this.translationKey + key),
        ['X']: value,
      })
      : this._translateService.instant(this.translationKey + '.PRECISION_RATING_LIMITED_ACCESS');

    return {
      text: precisionRatingText,
      hasAccessToPrecisionRating: this._permissionService.hasAccessToPrecisionRating(),
      improveText: this._translateService.instant(this.translationKey + '.PRECISION_RATING_GOTO_NEXT_STEPS_TEXT', {
        ['X']: value,
      })
    };
  }

  private getTCoreValueGraphViewModel() {
    return combineLatest([
      this._executiveSummaryService.executiveSummaryViewModel,
      this._selectedTypeChanged,
      this._thresholdValueChanged
    ]).pipe(map(([executiveSummary, selectedValue, threshold]) => {
      const plotline: Highcharts.AxisPlotLinesOptions = threshold > 0 ? {
        color: '#FF0000',
        dashStyle: 'Solid',
        value: threshold,
        width: 2,
        id: 'threshold',
        zIndex: 9
      } : null;
      const selectedPercentiles = this.getSelectedPercentiles(selectedValue, executiveSummary.valueFromInsurance);
      const key = this.translationKey + '.VALUE_FROM_INSURANCE_GRAPH.DROPDOWN_OPTIONS';
      return {
        options: [{
          value: this.tcorValueGraph.optionType.typical,
          text: this._translateService.instant(`${key}.TYPICAL`),
          selected: selectedValue === this.tcorValueGraph.optionType.typical
        }, {
          value: this.tcorValueGraph.optionType.sever,
          text: this._translateService.instant(`${key}.SEVERE`),
          selected: selectedValue === this.tcorValueGraph.optionType.sever
        },
        {
          value: this.tcorValueGraph.optionType.all,
          text: this._translateService.instant(`${key}.ALL`),
          selected: selectedValue === this.tcorValueGraph.optionType.all
        }],
        chartOptions: this.getTcorValueChartOptions(selectedPercentiles.filter(x => x.percentage),
          this.getTcorValueChartSeries(selectedPercentiles), plotline)
      };
    }));
  }

  private getSelectedPercentiles(selected: number, valueFromInsurance: ValueFromInsuranceVM): GraphPercentileViewModel[] {
    switch (selected) {
      case this.tcorValueGraph.optionType.typical:
        return valueFromInsurance.typicalYear;
      case this.tcorValueGraph.optionType.sever:
        return valueFromInsurance.severeYear;
      default:
        return this.getUniqueGraphPercentile([
          ...valueFromInsurance.typicalYear,
          ...valueFromInsurance.severeYear
        ]);
    }
  }

  private getSelectedCategories(selected: number) {
    switch (selected) {
      case this.tcorValueGraph.optionType.typical:
        return this._executiveSummaryService.typicalPercentiles;
      case this.tcorValueGraph.optionType.sever:
        return this._executiveSummaryService.severePercentiles;
      default:
        return this.getUniqueCategories([
          ...this._executiveSummaryService.typicalPercentiles,
          ...this._executiveSummaryService.severePercentiles
        ]);
    }
  }

  private getUniqueCategories(categories: Percentile[]) {
    return categories.filter((category, index, self) =>
      index === self.findIndex((t) => (
        t.percentileValue === category.percentileValue
      ))
    );
  }

  private getUniqueGraphPercentile(categories: GraphPercentileViewModel[]) {
    return categories.filter((percentile, index, self) =>
      index === self.findIndex((t) => (
        t.id === percentile.id
      ))
    );
  }

  private getTcorValueChartSeries(percentiles: GraphPercentileViewModel[]): any {
    const series = percentiles.reduce((pre, curr) => {
      return [
        [...this.getPreviousValue(pre[0]), curr.retained],
        [...this.getPreviousValue(pre[1]), curr.premium],
        [...this.getPreviousValue(pre[2]), curr.expense],
        [...this.getPreviousValue(pre[3]), curr.grossLoss],
        [...this.getPreviousValue(pre[4]), curr.tcor ? curr.tcor : 0]
      ];
    }, [[]]);
    return [{
      name: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.TOTAL_RETAINED`),
      type: 'column',
      data: series[0],
      showInLegend: false,
      color: APP_CONSTANTS.colors.totalRetained
    }, {
      name: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.EXPENSES`),
      type: 'column',
      data: series[2],
      showInLegend: false,
      color: APP_CONSTANTS.colors.expense
    }, {
      name: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.PREMIUM`),
      type: 'column',
      data: series[1],
      showInLegend: false,
      color: APP_CONSTANTS.colors.premium
    }, {
      name: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.GROSS_LOSS`),
      type: 'line',
      data: series[3],
      marker: { enabled: false },
      showInLegend: false,
      color: '#00A0D2'
    },
    {
      name: this._translateService.instant(`${this.translationKey}${this.translateKeyPrefix}.TCOR`),
      type: 'column',
      data: series[4],
      marker: { enabled: false, },
      showInLegend: false,
      visible: false,
      color: '#00A0D2'
    }];
  }

  private getPreviousValue(value: PercentileVM[]) {
    return value ?? [];
  }
}


class TotalTCORTable extends BaseTable {
  constructor(translationKey,
    translateService: TranslateService,
    headers: any[],
    risks: any[]) {
    const columnKeys = {
      0: 'risk',
      1: 'totalPremium',
      2: 'totalRetained',
      3: 'otherExpenses',
      4: 'tcor',
      5: 'percentage',
      6: 'modelSource'
    };
    super(translationKey, translateService, 'TOTAL_TCOR_TABLE', columnKeys);
    this.setHeaders(headers);
    this.sortColumnIndex = 4;
    this.setRisks(risks);
  }

  setHeaders(headers: any[]) {
    this.headers = headers;
  }
}
