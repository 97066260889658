import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortNumberFormat' })
export class ShortNumberFormatPipe implements PipeTransform {
    transform(number: any, currency = ''): any {

        // always have 2 decimalPlaces
        const decimals = 2;

        if (number === null) {
            return 'N/A';
        }
        number = parseFloat(number);
        if (isNaN(number)) { return 'N/A'; }
        if (!isNaN(+currency)) { currency = ''; }
        const signPrefix = number < 0 ? '-' : '';
        number = Math.abs(number); if (number <= 999) {
            // hundreds
            number = number.toFixed(decimals);
            number = number < 100 ? number.substring(0, 4) : number.substring(0, 3);
        } else if (number >= 1000 && number <= 999999) {
            // thousands
            number = (number / 1000).toFixed(decimals);
            number = number < 100 ? number.substring(0, 4) + 'K' : number.substring(0, 3) + 'K';
        } else if (number >= 1000000 && number <= 999999999) {
            // millions
            number = (number / 1000000).toFixed(decimals);
            number = number < 100 ? number.substring(0, 4) + 'M' : number.substring(0, 3) + 'M';
        } else if (number >= 1000000000 && number <= 999999999999) {
            // billions
            number = (number / 1000000000).toFixed(decimals);
            number = number < 100 ? number.substring(0, 4) + 'B' : number.substring(0, 3) + 'B';
        } else {
            // trilions
            number = (number / 1000000000000).toFixed(decimals);
            number = number < 100 ? number.substring(0, 4) + 'T' : number.substring(0, 3) + 'T';
        }
        return currency + signPrefix + number;
    }
}
