import { TranslateService } from '@ngx-translate/core';

export class BaseTable {
    headers = [];
    columns = [];
    rows;
    sortColumnIndex = 5;
    risks = [];
    private generatedData;
    constructor(protected translationKey,
        protected translateService: TranslateService,
        private tableKey: string,
        private columnKeys,
        private calc: (columnValues: number[]) => {} = null) {
        this.createColumns();
        this.createRows();
    }

    getViewModel() {
        return {
            table: {
                headers: this.headers,
                rows: this.rows,
            },
            sortColumnIndex: this.sortColumnIndex,
            risks: this.risks
        };
    }

    sortAscending(columnIndex: number) {
        this.sortColumnIndex = columnIndex;
        this.sort(arr => arr.sort((a, b) => {
            if (a[`${this.columnKeys[columnIndex]}`] < b[`${this.columnKeys[columnIndex]}`]) { return -1; }
            if (a[`${this.columnKeys[columnIndex]}`] > b[`${this.columnKeys[columnIndex]}`]) { return 1; }
            return 0;
        }));
    }

    sortDescending(columnIndex: number) {
        this.sortColumnIndex = columnIndex;
        this.sort(arr => arr.sort((a, b) => {
            if (a[`${this.columnKeys[columnIndex]}`] > b[`${this.columnKeys[columnIndex]}`]) { return -1; }
            if (a[`${this.columnKeys[columnIndex]}`] < b[`${this.columnKeys[columnIndex]}`]) { return 1; }
            return 0;
        }));
    }

    setRisks(risks: any[]) {
        this.risks = risks;
        this.createRows();
    }

    private sort(sortTable) {
        const { risks, allRisks } = this.generatedData;
        this.setRows([...sortTable(risks)], allRisks);
    }

    private createColumns() {
        const defaultColumn = {
            isPercentage: false,
            isBold: false
        };
        const percentageColumn = {
            isPercentage: true
        };
        const boldColumn = {
            isBold: true
        };
        this.columns.push({ ...boldColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...defaultColumn });
        this.columns.push({ ...percentageColumn });
    }

    private createRows() {
        this.generatedData = this.getData();
        const { risks, allRisks } = this.generatedData;
        this.setRows([...risks], allRisks);
    }

    private setRows(risks: any, allRisks: any) {
        risks.push(allRisks);
        this.rows = risks.map(item => ({
            isBold: item.isBold,
            data: this.headers.map((col, index) => ({
                value: item[`${this.columnKeys[index]}`],
                align: col.align,
                isRiskName: item.enableLink === false ? false : index === 0,
                enableLink: item.enableLink,
                visible: col.visible,
                width: col.width,
                ...this.columns[index]
            }))
        }));
    }

    private getData() {
        let risks = this.risks;

        const sortedRisks = risks
            .filter(x => x[`${this.columnKeys[0]}`] !== 'All')
            .sort((a, b) => b[`${this.columnKeys[this.sortColumnIndex]}`] - a[`${this.columnKeys[this.sortColumnIndex]}`]);

        const allOtherRisks = {
            [`${this.columnKeys[1]}`]: 0,
            [`${this.columnKeys[2]}`]: 0,
            [`${this.columnKeys[3]}`]: 0,
            [`${this.columnKeys[4]}`]: 0,
            [`${this.columnKeys[5]}`]: 0,
            isBold: false,
            enableLink: false
        };

        const all = risks.find(x => x[`${this.columnKeys[0]}`] === 'All');

        const allRisks = {
            [`${this.columnKeys[0]}`]: this.translateService.instant(this.translationKey + `.${this.tableKey}.ALL_RISKS`),
            [`${this.columnKeys[1]}`]: all ? all[`${this.columnKeys[1]}`] : 0,
            [`${this.columnKeys[2]}`]: all ? all[`${this.columnKeys[2]}`] : 0,
            [`${this.columnKeys[3]}`]: all ? all[`${this.columnKeys[3]}`] : 0,
            [`${this.columnKeys[4]}`]: all ? all[`${this.columnKeys[4]}`] : 0,
            [`${this.columnKeys[5]}`]: all ? all[`${this.columnKeys[5]}`] : 0,
            isBold: true,
            enableLink: false
        };

        const otherRisksCount = 0;

        risks = sortedRisks;

        if (otherRisksCount > 0) {
            risks.push({
                ...allOtherRisks,
                [`${this.columnKeys[0]}`]: this.translateService.instant(this.translationKey + `.${this.tableKey}.ALL_OTHER_RISKS`,
                    { [`N`]: otherRisksCount })
            });
        }

        return { risks, allRisks };
    }
}
