import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { ShortNumberFormatPipe } from '../../../pipes/short-number-formatter.pipe';
import { TranslateService } from '@ngx-translate/core';
import { HistoricalLoss, HistoricalLossesVM } from 'app/api/dtos';


@Component({
    selector: 'dtcor-historical-losses-chart',
    templateUrl: './historical-losses-chart.component.html',
    styleUrls: ['./historical-losses-chart.component.scss']
})
export class HistoricalLossesChartComponent implements OnChanges {
    @Input() losses: HistoricalLossesVM;
    @Input() translationKey: string;

    Highcharts: typeof Highcharts = Highcharts;
    riskColors = [
        '#D06FC8',
        '#663E88',
        '#808080',
        '#4380E7',
        '#3ADCC9',
        '#F6BB4D',
        '#F08834',
        '#E05D7E',
        '#B82CA7',
        '#41146B'
    ];

    options;
    chart: Highcharts.Chart;
    @ViewChild('lossesChartEl') lossesChartEl: ElementRef;

    constructor(private translateService: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const lossesChange = changes['losses'];
        const lossesValue: HistoricalLossesVM = lossesChange.currentValue;
        if (lossesValue !== lossesChange.previousValue) {
            let years: string[], table: HistoricalLoss[] = [];
            let max, min, totalLoss, totalAdjustedLoss = 0;
            if (lossesValue && lossesValue.table) {
                table = lossesValue.table;
                totalLoss = lossesValue.totalLoss;
                totalAdjustedLoss = lossesValue.totalAdjustedLoss;
            }
            if (table.length > 0) {
                max = table[0].maxYear;
                min = table[0].minYear;
                years = table[0].orderedLosses.map(x => x.year.toString());
            }
            const series = table.map((x, index) => ({
                name: x.riskType,
                type: 'column',
                data: x.orderedLosses.map(item => item.incurredTotal),
                color: this.riskColors[index]
            }));
            const totalAverageLoss = (totalLoss / years.length);
            const totalAdjustedAverageLoss = (totalAdjustedLoss / years.length);
            this.options = this.getOptions(years, series, totalAverageLoss, totalAdjustedAverageLoss);
        }
    }

    formatNumber(value: number) {
        return new ShortNumberFormatPipe().transform(value);
    }

    chartInstance(chart: Highcharts.Chart) {
        this.chart = chart;
    }

    onResize(event) {
        setTimeout(() => {
          this.chart.setSize(this.lossesChartEl.nativeElement.offsetWidth - 15, this.lossesChartEl.nativeElement.offsetHeight);      
        });
    }

    private getOptions(categories: string[], series, totalAverageLoss: number, totalAdjustedAverageLoss: number = 0): Highcharts.Options {
        const averageAnnualLoss = {
            id: 'totalAverageLoss',
            color: '#EB3729',
            width: 5,
            zIndex: 5,
            data: categories.map(x => totalAverageLoss)
        };

        const adjustedAnnualAverageLoss = {
            id: 'totalAdjustedAverageLoss',
            color: '#357ef2',
            width: 5,
            zIndex: 5,
            data: categories.map(x => totalAdjustedAverageLoss)
        };

        const format = (value: number) => this.formatNumber(value);
        const chartSeries = [{
            ...adjustedAnnualAverageLoss,
            type: 'line',
            name: this.translateService.instant(this.translationKey + '.CHART.AGGREGATED_ANNUAL_AVERAGE_ADJUSTED_LOSSES'),
            marker: { enabled: true },
            allowPointSelect: true,
            events: {
                legendItemClick: function (e) {
                    e.preventDefault();
                }
            }
        }, {
            ...averageAnnualLoss,
            type: 'line',
            name: this.translateService.instant(this.translationKey + '.CHART.AGGREGATED_ANNUAL_AVERAGE_LOSSES'),
            marker: { enabled: true },
            allowPointSelect: true,
            events: {
                legendItemClick: function (e) {
                    e.preventDefault();
                }
            }
        }, ...series.reverse()];


        return {
            chart: {
                backgroundColor: 'transparent',
                width: window.innerWidth - 150
            },
            credits: {
                text: ''
            },
            title: {
                style: {
                    display: 'none'
                }
            },
            xAxis: {
                categories: categories,
                title: {
                    text: this.translateService.instant(this.translationKey + '.CHART.XAXIS'),
                    style: {
                        fontWeight: 'bold',
                        fontSize: '12px'
                    }
                },
                endOnTick: true
            },
            yAxis: {
                visible: true,
                reversed: false,
                min: 0,
                title: {
                    text: this.translateService.instant(this.translationKey + '.CHART.YAXIS'),
                    style: {
                        fontWeight: 'bold',
                        fontSize: '12px'
                    }
                },
                endOnTick: true,
                labels: {
                    formatter: function () {
                        return format(this.value);
                    }
                }
            },
            legend: {
                enabled: true,
                reversed: true,
                verticalAlign: 'top',
                symbolRadius: 0,
                itemHoverStyle: {
                    cursor: 'default'
                }
            },
            tooltip: {
                shared: false,
                formatter: function () {
                    const value = format(this.y);
                    return `<span>${this.series.name}</span>: <b>${value}</b><br/>`;
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    events: {
                        legendItemClick: function () {
                            return false; 
                        }
                    }
                },
                line: {
                    lineWidth: 5,
                }         
            },
            series: chartSeries
        };
    }
}
