import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { PermissionService } from '../../../services/permissions.service';

@Component({
    selector: 'app-report-modal',
    templateUrl: './report-modal.component.html',
    styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent {
    onClose: Subject<boolean> = new Subject<boolean>();
    isClientRole = false;

    @Input()
    translation = {};

    @Input()
    translationKey = 'CURRENT_MODEL.REPORT_DIALOG';

    data: any;

    model: any;
    active: boolean;
    headerText: string[];
    bodyText: string[];   

    constructor(public _permissionService: PermissionService,
        private _bsModalRef: BsModalRef) {
        // empty block
    }

    ok() {
        if (this._permissionService.hasAccessToReport()) {
            this.hide(true);
        } else {
            this._permissionService.showModal();
            this.cancel();
        }
    }

    cancel() {
        this.hide(false);
    }

    close() {
        this.hide(null);
    }

    show() {
        this.active = true;
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }
}
