<ng-container e2eGroup="executive-summary-program-structure" *ngIf="viewModel | async as vm">
    <div class="row">
        <div class="col-md-12">
            <div class="pt-4">
              <div [ngStyle]="{ width: 'calc(100vw - 128px)', overflow: 'hidden' }">
                <div class="row">
                    <div class="col-md-12">
                        <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                        <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="vm.content">
                        </div>
                        <div e2eItem="table" [ngStyle]="{ 'overflow-x': enableScrollbar() }">
                            <div class="row">
                                <div class="pr-4">
                                    <div
                                        [ngStyle]="{ 'width': expanded ? getWrapperWidth(vm.rows) : 'auto' }">
                                        <div class="border font-weight-bold mt-3">
                                            <div class="wtw-col w-150">
                                                <div
                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.RETURN_PERIOD' | translate">
                                                </div>
                                                <div>&nbsp;</div>
                                            </div>
                                            <div class="wtw-col">
                                                <div
                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.PERCENTILE' | translate">
                                                </div>
                                                <div>&nbsp;</div>
                                            </div>
                                            <div class="wtw-col">
                                                <div [innerHTML]="translationKey + '.TABLE.COLUMNS.COUNT' | translate">
                                                </div>
                                                <div>&nbsp;</div>
                                            </div>
                                            <div class="wtw-col">
                                                <div
                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.GROSS_LOSS' | translate">
                                                </div>
                                                <div>&nbsp;</div>
                                            </div>
                                            <div class="wtw-col w-150">
                                                <div
                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_RETAINED' | translate">
                                                </div>
                                                <div *ngIf="!showExpandTotalRetainedBreakdown(vm.rows)">&nbsp;</div>
                                                <a class="cursor-pointer detail-view" *ngIf="showExpandTotalRetainedBreakdown(vm.rows)"
                                                    (click)="toggleTotalRetainedDetailView()">
                                                    <span
                                                        [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                                    <span class="arrow"
                                                        [ngClass]="{ 'down-arr': !totalRetainedDetailViewActive, 'up-arr': totalRetainedDetailViewActive }"></span>
                                                </a>
                                            </div>
                                            <ng-container *ngIf="totalRetainedDetailViewActive">
                                                <div class="wtw-col" *ngFor="let subCol of vm.rows[0].retainedBreakDown">
                                                    &nbsp;
                                                </div>
                                            </ng-container>
                                            <div class="wtw-col w-160">
                                                <div
                                                    [innerHTML]="translationKey + '.TABLE.COLUMNS.TOTAL_TRANSFERRED' | translate">
                                                </div>
                                                <div *ngIf="!showExpandTotalTrasferredBreakdown(vm.rows)">&nbsp;</div>
                                                <a class="cursor-pointer detail-view" *ngIf="showExpandTotalTrasferredBreakdown(vm.rows)"
                                                    (click)="toggleTotalTransferredDetailView()">
                                                    <span
                                                        [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                                    <span class="arrow"
                                                        [ngClass]="{ 'down-arr': !totalTransferredDetailViewActive, 'up-arr': totalTransferredDetailViewActive }"></span>
                                                </a>
                                            </div>
                                            <ng-container *ngIf="totalTransferredDetailViewActive">
                                                <div class="wtw-col" *ngFor="let subCol of vm.rows[0].transferredBreakDown">
                                                    &nbsp;
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="border" *ngIf="expanded && vm.rows.length > 0">
                                            <div class="wtw-col w-150"></div>
                                            <div class="wtw-col"></div>
                                            <div class="wtw-col"></div>
                                            <div class="wtw-col"></div>
                                            <div class="wtw-col w-150"></div>
                                            <ng-container *ngIf="totalRetainedDetailViewActive">
                                                <div class="wtw-col col-border" *ngFor="let subCol of vm.rows[0].retainedBreakDown">
                                                    {{ subCol.name }}
                                                </div>
                                            </ng-container>
                                            <div class="wtw-col w-160">&nbsp;</div>
                                            <ng-container *ngIf="totalTransferredDetailViewActive">
                                                <div class="wtw-col col-border" *ngFor="let subCol of vm.rows[0].transferredBreakDown">
                                                    {{ subCol.name }}
                                                </div>
                                            </ng-container>
                                        </div>

                                        <div class="border" *ngFor="let row of vm.rows;let last = last;" [ngClass]="{ 'font-weight-bold border-bottom': last }">
                                            <div class="wtw-col w-150">{{ row.returnPeriod }}</div>
                                            <div class="wtw-col" *ngIf="!!row.percentage">{{ suffix(row.percentage) }}</div>
                                            <div class="wtw-col" *ngIf="!row.percentage">{{ row.label | titlecase }}</div>
                                            <div class="wtw-col">{{ row.count | wtwNumber }}</div>
                                            <div class="wtw-col">{{ row.grossLoss |  shortNumberFormat }}</div>
                                            <div class="wtw-col w-150">{{ row.retained |  shortNumberFormat }}</div>
                                            
                                            <ng-container *ngIf="totalRetainedDetailViewActive && row.retainedBreakDown.length > 0">
                                                <div class="wtw-col col-border" *ngFor="let subCol of row.retainedBreakDown">
                                                    <span [ngClass]="{ 'font-weight-bold': last }">{{ subCol.value |  shortNumberFormat}}</span>
                                                </div>
                                            </ng-container>

                                            <div class="wtw-col w-160">{{ row.transferred |  shortNumberFormat }}</div>                                            
                                            
                                            <ng-container *ngIf="totalTransferredDetailViewActive && row.transferredBreakDown.length > 0">
                                                <div class="wtw-col col-border" *ngFor="let subCol of row.transferredBreakDown">
                                                    <span [ngClass]="{ 'font-weight-bold': last }">{{ subCol.value |  shortNumberFormat}}</span>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</ng-container>