<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{ translationKey + '.TITLE' | translate }}</h4>
        <button type="button" class="close"> <span (click)="close()">&times;</span> </button>
    </div>
    <div class="modal-body p-5">
        <div class="row mb-3">
            <div class="col-md-12">
                {{ translationKey + '.DESCRIPTION' | translate }}
            </div>
        </div>

        <div class="row">
            <div class="col-md-1 text-right pr-0">
                <input type="radio" name="reportType" checked />
            </div>
            <div class="col-md-1">                
                <img src="/assets/images/pic_analysis_sheet_01.svg" alt="sales sheet pdf" />
            </div>
            <div class="col-md-10">
                <div class="fs-18 mb-2">
                    {{ translationKey + '.REPORT.TITLE' | translate }}
                </div>
                <div class="fs-16">
                    {{ translationKey + '.REPORT.DESCRIPTION' | translate }}
                </div>
            </div>
        </div>    
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ok()">
            {{ translationKey + '.DOWNLOAD_BUTTON' | translate }}
            <img *ngIf="!_permissionService.hasAccessToReport()" class="icon-lock icon ml-1" [src]="'/assets/images/lock.svg'" alt="lock"/>
            <img *ngIf="_permissionService.hasAccessToReport()" alt="icon pdf" src="/assets/images/icons8-export-pdf-transparent.svg" class="icon-pdf icon" />
        </button>
        <button type="button" class="btn btn-secondary" (click)="cancel()">
            {{ translationKey + '.CANCEL_BUTTON' | translate }}
        </button>
    </div>    
  </div>
  