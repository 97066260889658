<div class="row" e2eGroup="risk-summary-current-structure" *ngIf="viewModel | async as vm">
    <div class="col-md-12">
        <div class="pt-4">
            <div class="row">
                <div class="col-md-12">
                    <h3 e2eItem="page-heading" class="fs-24" [innerHTML]="translationKey + '.HEADING' | translate"></h3>
                    <div e2eItem="page-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="vm.currentStructure.label">
                    </div>
                    <div e2eItem="page-sub-text" class="text-wtw-gray-8 tabs-text" [innerHTML]="vm.descriptionText">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 pt-4">
        <div class="row pb-4">
            <div class="col-8">
                <div e2eItem="avg-annual-loss" class="annual-loss fs-24 float-left">{{vm.currentStructure.averageAnnualLoss |
                    shortNumberFormat }}
                </div>
                <div class="float-left pt-4 pl-4">
                    <span class="text-wtw-gray-8 tabs-text pt-1"
                        [innerHTML]="translationKey + '.ANNUAL_AVERAGE_LOSS' | translate">
                    </span>
                </div>
            </div>
        </div>
        <hr class="my-1 mb-2" />
    </div>
    <div class="col-md-12 mt-2 mb-4">
        <div class="row pb-4">
            <div class="col-md-2">
                <div e2eItem="retained-deductible" class="annual-loss-split p-2 mt-4">
                    <p class="split-by-group-name"
                        [innerHTML]="translationKey + '.RETAINED_WITHIN_DEDUCTIBLE' | translate"></p>
                    <p class="split-by-group-value text-right mt-2">{{vm.currentStructure.retainedWithDeductible |
                        shortNumberFormat}}
                    </p>
                    <p class="split-by-group-percent text-right">{{vm.currentStructure.retainedWithDeductiblePercentage
                        | percentageFormatter}}</p>
                </div>
                <div e2eItem="retained-captive" class="annual-loss-split p-2 mt-4">
                    <p class="split-by-group-name"
                        [innerHTML]="translationKey + '.RETAINED_WITHIN_CAPTIVE' | translate"></p>
                    <p class="split-by-group-value text-right mt-2">{{vm.currentStructure.retainedWithCaptive |
                        shortNumberFormat}}
                    </p>
                    <p class="split-by-group-percent text-right">{{vm.currentStructure.retainedWithCaptivePercentage |
                        percentageFormatter}}</p>
                </div>
                <div e2eItem="ceded-insurers" class="annual-loss-split p-2 mt-4">
                    <p class="split-by-group-name" [innerHTML]="translationKey + '.CEDED_TO_INSURERS' | translate"></p>
                    <p class="split-by-group-value text-right mt-2">{{vm.currentStructure.cededToInsures |
                        shortNumberFormat}}
                    </p>
                    <p class="split-by-group-percent text-right">{{vm.currentStructure.cededToInsuresPercentage |
                        percentageFormatter}}</p>
                </div>
                <div e2eItem="exceeding-cover" class="annual-loss-split p-2 mt-4">
                    <p class="split-by-group-name" [innerHTML]="translationKey + '.EXCEEDING_COVER' | translate"></p>
                    <p class="split-by-group-value text-right mt-4">{{vm.currentStructure.exceedingCover |
                        shortNumberFormat}}
                    </p>
                    <p class="split-by-group-percent text-right">{{vm.currentStructure.exceedingCoverPercentage |
                        percentageFormatter}}</p>
                </div>
            </div>
            <div class="col-10 pt-4 pb-4 scrollabel">
                <table e2eItem="table" *ngIf="vm.currentStructure.table.length > 0" class="gross-loss-table" summary="structure">                    
                    <tr>
                        <th id="header1">
                            <table role="presentation">
                                <tr>
                                    <td>{{translationKey + '.TABLE.RETURN_PERIOD' | translate}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                            </table>
                        </th>
                        <th>
                            <table role="presentation">
                                <tr>
                                    <td>{{translationKey + '.TABLE.PERCENTILE' | translate}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                            </table>
                        </th>
                        <th>
                            <table role="presentation">                               
                                <tr>
                                    <td>{{translationKey + '.TABLE.COUNT' | translate}}</td>

                                </tr>
                                <tr>
                                    <td></td>

                                </tr>
                            </table>
                        </th>
                        <th>
                            <table role="presentation">
                               
                                <tr>
                                    <td>{{translationKey + '.TABLE.GROSS_LOSS' | translate}}</td>
                                </tr>
                                <tr>
                                    <td></td>
                                </tr>
                            </table>
                        </th>
                        <th>
                            <table role="presentation">
                               
                                <tr>{{translationKey + '.TABLE.TOTAL_RETAINED' | translate}}</tr>
                                <tr>
                                    <div *ngIf="vm.currentStructure.table.length > 0 && vm.currentStructure.table[0].retainedBreakDown.length > 0"
                                        class="cursor-pointer detail-view" (click)="toggleRetained(vm.expandRetained)"
                                        style="width: 100%;display: inline-block;">
                                        <span [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                        <span class="arrow"
                                            [ngClass]="{ 'down-arr': !vm.expandRetained, 'up-arr': vm.expandRetained }"></span>
                                    </div>
                                    <td
                                        *ngIf="!(vm.currentStructure.table.length > 0 && vm.currentStructure.table[0].retainedBreakDown.length > 0)">
                                    </td>
                                </tr>
                            </table>
                        </th>
                        <th *ngIf="vm.expandRetained" class="no-padding sub-header">
                            <table role="presentation">
                               
                                <tr class="bottom-seperator">
                                    <td colspan="{vm.currentStructure.table[0].retainedBreakDown.length}">&nbsp;</td>
                                </tr>
                                <tr id="retained">
                                    <td *ngFor="let breakDown of vm.currentStructure.table[0].retainedBreakDown; let i = index;"
                                        class="seperator">{{breakDown.name}}</td>
                                </tr>
                            </table>
                        </th>
                        <th>
                            <table role="presentation">
                               
                                <tr>{{translationKey + '.TABLE.TOTAL_TRANSFERRED' | translate}}</tr>
                                <tr>
                                    <div *ngIf="vm.currentStructure.table.length > 0 && vm.currentStructure.table[0].transferredBreakDown.length > 0"
                                        class="cursor-pointer detail-view"
                                        (click)="toggleTransferred(vm.expandTransferred)"
                                        style="width: 100%;display: inline-block;">
                                        <span [innerHTML]="translationKey + '.TABLE.DETAIL_VIEW' | translate"></span>
                                        <span class="arrow"
                                            [ngClass]="{ 'down-arr': !vm.expandTransferred, 'up-arr': vm.expandTransferred }"></span>
                                    </div>
                                    <td
                                        *ngIf="!(vm.currentStructure.table.length > 0 && vm.currentStructure.table[0].transferredBreakDown.length > 0)">
                                    </td>
                                </tr>
                            </table>
                        </th>
                        <th *ngIf="vm.expandTransferred" class="no-padding sub-header">
                            <table role="presentation">
                               
                                <tr class="bottom-seperator">
                                    <td colspan="{vm.currentStructure.table[0].transferredBreakDown.length}">&nbsp;</td>
                                </tr>
                                <tr id="transferred">
                                    <td *ngFor="let breakDown of vm.currentStructure.table[0].transferredBreakDown"
                                        class="seperator">{{breakDown.name}}</td>
                                </tr>
                            </table>
                        </th>
                    </tr>
                    <tr *ngFor="let row of vm.currentStructure.table; let i = index;let last=last"
                        class="gross-loss-table-body" [ngClass]="{ 'font-weight-bold': last }">
                        <td>{{row.returnPeriod}}</td>
                        <td *ngIf="!!row.percentage">{{suffix(row.percentage)}}</td>
                        <td *ngIf="!row.percentage">{{row.label | titlecase}}</td>
                        <td>{{row.count | wtwNumber}}</td>
                        <td>{{row.grossLoss | shortNumberFormat}}</td>
                        <td>{{row.retained | shortNumberFormat}}</td>
                        <td *ngIf="vm.expandRetained" class="no-padding">
                            <table class="gross-loss-body-inner-table" role="presentation">                               
                                <tr>
                                    <td *ngFor="let breakDown of vm.currentStructure.table[i].retainedBreakDown; let j=index"
                                        [ngStyle]="{width:getWidth(j,'retained')}" class="seperator">{{breakDown.value |
                                        shortNumberFormat}}</td>
                                </tr>
                            </table>
                        </td>
                        <td>{{row.transferred | shortNumberFormat}}</td>
                        <td *ngIf="vm.expandTransferred" class="no-padding">
                            <table class="gross-loss-body-inner-table" role="presentation">
                               
                                <tr>
                                    <td *ngFor="let breakDown of vm.currentStructure.table[i].transferredBreakDown; let j=index"
                                        [ngStyle]="{width:getWidth(j,'transferred')}" class="seperator">
                                        {{breakDown.value | shortNumberFormat}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>