/* eslint-disable */
//typings install --global --save dt~arcgis-js-api@3.19.0 - doesnt work
//typings install --global --save dt~arcgis-js-api - works but imports version 4.20 of the api
import { Injectable, Inject, ElementRef, LOCALE_ID } from '@angular/core';
import { loadModules } from 'esri-loader';
import { MapDefinitions } from '../shared/models/index';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { DecimalPipe } from '@angular/common';

enum LiveEventLevel {
    Country = 'Event_CORONAVIRUS',
    State = 'Event_COVIDPOINT',
    County = 'Event_COVIDCOUNTY'
}

@Injectable()
export class MapService {
    esriModules$: Observable<{}>;
    esriMapReady$: Observable<{}>;
    esriPinDropped$: Observable<{}>;
    esriBasemapToggled$: Observable<string>;
    relationshipSelected$: Observable<{}>;
    _selectedBasemap: string;
    isEsriModulesLoaded: boolean;
    _isPdf: boolean;
    currentMapView: any;
    _pdfSelectedLayers: any[];
    _geoJSONLayer: any;
    basemaps: any[] = [];

    private _mapConstructor: any;
    private _mapViewConstructor: any;
    private _graphic: any;
    private _esriUtil: any;
    private _featureLayer: any;
    private _home: any;
    private _zoom: any;
    private _domConstruct: any;
    private _arcGISDynamicMapServiceLayer: any;
    private _color: any;
    private _simpleLineSymbol: any;
    private _point: any;
    private _webMercatorUtils: any;
    private _simpleMarkerSymbol: any;
    private _textSymbol: any;
    private _spatialReference: any;
    private _graphicsLayer: any;
    private _expand: any;
    private _geometryEngine: any;

    private mapExtent: any;
    private _esriModules = new Subject();
    private _esriMapReady = new Subject();
    private _esriPinDropped = new Subject();
    private _esriBasemapToggled = new Subject<string>();
    private _relationshipSelected = new Subject();
    private currentMap: any;
    private _showPopup: boolean;
    private _extent: any;
    private _tileLayer: any;
    private _viewpoint: any;
    private _homeWidget: any;
    private _search: any;
    private _legend: any;
    private _polyline: any;
    private _basemapToggle: any;

    private _isTivToggled: boolean = true;
    private _isIntExtToggled: boolean = false;
    private _clusterNodes: any;
    private _clusterNodes2: any;
    private _intExtNodes: any;
    private _intExtNodes2: any;
    private _top5RelationshipLayer: any;
    private _allRelationshipLayer: any;
    private _supplyChainClusterConfig: any;
    private _clusterNodeLayerTemplate: any;
    private _intExtLayerTemplate: any;
    private _lineIds = [];
    private _hasAnyRelationshipLink: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _isPopupRelationshipLinkActive: Subject<boolean> = new Subject();
    private _currentSelectedNode: number;
    private _covid19CountryQueryCompleted: Subject<boolean> = new Subject();

    constructor(private _translate: TranslateService, @Inject(LOCALE_ID) private _locale: string) {
        this.esriModules$ = this._esriModules.asObservable();
        this.esriMapReady$ = this._esriMapReady.asObservable();
        this.esriPinDropped$ = this._esriPinDropped.asObservable();
        this.esriBasemapToggled$ = this._esriBasemapToggled.asObservable();
        this.relationshipSelected$ = this._relationshipSelected.asObservable();
    }

    public loadEsriModules() {
        loadModules([
            'esri/Map',
            'esri/views/MapView',
            'esri/Graphic',
            'esri/widgets/Home',
            'esri/widgets/Zoom',
            'esri/widgets/Search',
            'esri/widgets/Expand',
            'esri/core/urlUtils',
            'esri/layers/FeatureLayer',
            'dojo/dom-construct',
            'esri/layers/MapImageLayer',
            'esri/geometry/support/webMercatorUtils',
            'esri/geometry/Point',
            'esri/symbols/SimpleLineSymbol',
            'esri/Color',
            'esri/symbols/SimpleMarkerSymbol',
            'esri/symbols/TextSymbol',
            'esri/geometry/SpatialReference',
            'esri/layers/GraphicsLayer',
            'esri/geometry/Extent',
            'esri/layers/TileLayer',
            'esri/layers/GeoJSONLayer',
            'esri/renderers/HeatmapRenderer',
            'esri/Viewpoint',
            'esri/widgets/Legend',
            'esri/widgets/LayerList',
            'esri/widgets/Popup',
            'esri/renderers/UniqueValueRenderer',
            'esri/geometry/geometryEngine',
            'esri/tasks/support/Query',
            'esri/geometry/Polyline',
            'esri/widgets/BasemapToggle'
        ], { version: '4.16', css: false }).then(([
            Map,
            MapView,
            Graphic,
            Home,
            Zoom,
            Search,
            Expand,
            esriUtil,
            FeatureLayer,
            domConstruct,
            ArcGISDynamicMapServiceLayer,
            WebMercatorUtils,
            Point,
            SimpleLineSymbol,
            Color,
            SimpleMarkerSymbol,
            TextSymbol,
            SpatialReference,
            GraphicsLayer,
            Extent,
            TileLayer,
            GeoJSONLayer,
            HeatmapRenderer,
            Viewpoint, Legend, LayerList, Popup, UniqueValueRenderer, geometryEngine, Query, Polyline, BasemapToggle
        ]) => {
            this._mapConstructor = Map;
            this._mapViewConstructor = MapView;
            this._graphic = Graphic;
            this._home = Home;
            this._zoom = Zoom;
            this._search = Search;
            this._expand = Expand;
            this._esriUtil = esriUtil;
            this._featureLayer = FeatureLayer;
            this._domConstruct = domConstruct;
            this._arcGISDynamicMapServiceLayer = ArcGISDynamicMapServiceLayer;
            this._point = Point;
            this._webMercatorUtils = WebMercatorUtils;
            this._simpleLineSymbol = SimpleLineSymbol;
            this._color = Color;
            this._simpleMarkerSymbol = SimpleMarkerSymbol;
            this._textSymbol = TextSymbol;
            this._spatialReference = SpatialReference;
            this._graphicsLayer = GraphicsLayer;
            this.basemaps = ['dark-gray'];
            this._extent = Extent;
            this._tileLayer = TileLayer;
            this._geoJSONLayer = GeoJSONLayer;
            this._viewpoint = Viewpoint;
            this._legend = Legend;
            this._geometryEngine = geometryEngine;
            this._polyline = Polyline;
            this.isEsriModulesLoaded = true;
            this._basemapToggle = BasemapToggle;
            this._esriModules.next({});
        });
    }

    public createMap(mapEl: ElementRef, mapOptions: any) {
        this.currentMap = new this._mapConstructor(mapOptions);
        const mapViewProperties: any = {
            container: mapEl,
            center: mapOptions.center,
            zoom: mapOptions.zoom,
            map: this.currentMap,
            constraints: {
                rotationEnabled: false,
            }
        };

        let mapView = new this._mapViewConstructor(mapViewProperties);
        mapView.popup.on('trigger-action', function(event) {
            if (event.action.id === 'zoom-to-feature') {
                mapView.popup.visible = false;
            }
        });
        mapView.watch('zoom', function(value) {
            mapView.popup.visible = false;
        });
        mapView.watch('ready', (isReady) => {
            this._esriMapReady.next(isReady);
        });
        this._selectedBasemap = this.getBasemapName(mapView.map);
        this._showPopup = mapOptions.showPopup;
        mapView.ui.remove('zoom');
        this.currentMapView = mapView;
        return mapView;
    }

    public createSupplyChainMap(mapEl: ElementRef, mapOptions: any) {
        let currentMap = new this._mapConstructor(mapOptions);
        const mapViewProperties: any = {
            container: mapEl,
            center: mapOptions.center,
            zoom: mapOptions.zoom,
            map: currentMap,
            constraints: {
                rotationEnabled: false,
            }
        };

        let mapView = new this._mapViewConstructor(mapViewProperties);
        mapView.popup.on('trigger-action', function(event) {
            if (event.action.id === 'zoom-to-feature') {
                mapView.popup.visible = false;
            }
        });
        mapView.watch('zoom', function(value) {
            mapView.popup.visible = false;
        });
        mapView.watch('ready', (isReady) => {
            this._esriMapReady.next(isReady);
        });
        this.addSupplyChainWidgets(mapView);
        mapView.ui.remove('zoom');
        mapView.ui.remove('attribution');
        this.addLogoSupplyChain(mapView);
        this.addClusterToggleButton(mapView, true);
        this.addSupplyChainBasemapToggleWidget(mapView);
        this._selectedBasemap = this.getBasemapName(mapView.map);
        this.currentMapView = mapView;
        return mapView;
    }

    public createMapBasic(mapEl: ElementRef, points: any[], mapOptions: any) {
        let map = new this._mapConstructor(mapOptions);
        const mapViewProperties: any = {
            container: mapEl,
            center: mapOptions.center,
            zoom: mapOptions.zoom,
            map: map,
            constraints: {
                rotationEnabled: false,
            }
        };
        const mapView = new this._mapViewConstructor(mapViewProperties);

        mapView.on('click', (evt) => {
            this.movePoint(evt, mapView);
        });
        mapView.ui.remove('zoom');
        this.addGlobalPerilDiagnosticWidget(mapView, mapOptions.homeWidget);

        return mapView;
    }

    public createMapPdf(mapEl: ElementRef, mapOptions?: any) {

        let spatialRef = new this._spatialReference({ wkid: 102100 });
        let map = new this._mapConstructor({ basemap: mapOptions.basemap, spatialReference: spatialRef });
        const mapViewProperties: any = {
            container: mapEl,
            map: map
        };
        const mapView = new this._mapViewConstructor(mapViewProperties);
        mapView.popup = null;
        mapView.totalTiv = 0;
        return mapView;
    }

    covid19CountryQueryCompleted$() {
        return this._covid19CountryQueryCompleted.asObservable();
    }

    addRemoveLiveEventLayers(layerInfo: any) {
        if (layerInfo.checked) {
            if (layerInfo.id.includes('Event_CO'))
                layerInfo.layer.opacity = 0.5;
            else
                layerInfo.layer.opacity = 0.8;
            layerInfo.layer.visible = true;
        } else {
            layerInfo.layer.opacity = 0;
            layerInfo.layer.visible = false;
        }
    }

    addRemoveLayer(layerInfo: any, mapView: any) {


        let layerOnMap = mapView.map.findLayerById(layerInfo.id);

        if (layerOnMap !== undefined) {

            mapView.map.layers.remove(layerOnMap);
        } else {
            let clusterLayers = mapView.map.allLayers.filter(function(layer) {
                return layer.id.includes('clusters');
            });
            if (clusterLayers.length > 0)
                mapView.map.removeMany(clusterLayers.items);
            layerInfo.layer.opacity = 0.8;
            mapView.map.layers.add(layerInfo.layer);
            if (clusterLayers.length > 0)
                mapView.map.addMany(clusterLayers.items);
        }
    }

    addRemoveSingleLayer(layerId: any, layerInfos: any[], mapView: any) {
        layerInfos.forEach((lyrId: any) => {

            let removeLayer = mapView.map.findLayerById(lyrId.id);

            if (removeLayer !== undefined) {
                mapView.map.layers.remove(removeLayer);
            }
        }
        );

        let lyr = layerInfos.filter(x => x.id === layerId);

        if (lyr && lyr.length > 0) {
            let clusterLayers = mapView.map.allLayers.filter(function(layer) {
                return layer.id.includes('clusters');
            });
            if (clusterLayers.length > 0)
                mapView.map.removeMany(clusterLayers.items);
            lyr[0].layer.opacity = 0.7;
            mapView.map.layers.add(lyr[0].layer);
            if (clusterLayers.length > 0)
                mapView.map.addMany(clusterLayers.items);
        }
    }

    getMapOptions(mapView: any): any {

        let mapOptions = { zoom: {}, centre: {} };

        let geographic = this._webMercatorUtils.webMercatorToGeographic(mapView.center);

        mapOptions.zoom = mapView.zoom;

        mapOptions.centre = [geographic.x, geographic.y];
        return mapOptions;
    }

    addAllLiveEventLayersToMap(layerInfos: any[], mapView: any) {
        layerInfos.forEach((layerInfo: any) => {
            if (layerInfo.id.includes('Event_CO'))
                layerInfo.layer.opacity = 0.5;
            else
                layerInfo.layer.opacity = 0.8;
            mapView.map.layers.add(layerInfo.layer);
        });
    }

    setBasemap(mapView: any, basemapName: string) {
        mapView.map.set('basemap', basemapName);
        this.reorderBasemapReferenceLayer(mapView);
    }

    reorderBasemapReferenceLayer(mapView: any) {
        //wait for basemap to load
        setTimeout(() => {
            let addedReflayer = mapView.map.layers.find(layer => layer.id.includes('reference'));
            if (addedReflayer)
                mapView.map.layers.remove(addedReflayer);
            let referenceLayer = mapView.map.allLayers.find(layer => layer.id.includes('reference'));
            if (referenceLayer) {
                mapView.map.layers.add(new this._tileLayer({ id: referenceLayer.id, url: referenceLayer.url }), 0);
                //delaying to avoid flicker on map
                setTimeout(() => referenceLayer.visible = false, 2000);
            }
        }, 2000);
    }

    getBasemaps() {
        return this.basemaps;
    }

    zoomToLocation(lat: number, lon: number, level: number, mapView: any) {
        if (mapView.popup)
            mapView.popup.visible = false;

        if (level === undefined)
            level = 9;
        mapView.zoom = level;
        mapView.center = [lon, lat];
    }

    zoomToExtent(showLocations: boolean, mapView: any) {
        mapView.popup.visible = false;

        if (showLocations) {
            mapView.extent = this.mapExtent;
        } else {
            let continentLayer = mapView.map.allLayers.find(function(layer) { return layer.title === 'continents'; });

            if (continentLayer !== undefined) {
                let ext = this.calcGraphicsExtent(continentLayer.graphics);
                mapView.extent = ext;
                let point = ext.center;
                point.x = point.x + 5;
                mapView.center = point;
            }
        }
    }

    /* graphics */

   

    public removeGraphics(mapView: any) {
        mapView.graphics.removeAll();
    }

    getClusterConfig() {
        return {
            type: 'cluster',
            clusterRadius: 80,
            popupTemplate: {
                outFields: ['*'],
                content: this.getGpdClusterPopupInfo.bind(this)
            },
            labelingInfo: [MapDefinitions.clusterLabelInfo]
        };
    }


    getGpdSingleLocationPopupInfo(feature: any) {
        let popupDiv = document.createElement('div');
        popupDiv.classList.add('pin-pop-up');
        let decimalPipe = new DecimalPipe(this._locale);

        const popupFooter = document.getElementsByClassName('esri-popup__footer')[0];
        popupFooter.setAttribute('style', 'display: none !important');

        feature.graphic.attributes.name = feature.graphic.attributes.name.length > 25 ? feature.graphic.attributes.name.substring(0, 23) + '...' : feature.graphic.attributes.name;
        feature.graphic.attributes.facility = feature.graphic.attributes.name.length > 25 ? feature.graphic.attributes.name.substring(0, 23) + '...' : feature.graphic.attributes.name;
        feature.graphic.attributes.facilityFull = feature.graphic.attributes.name;
        feature.graphic.attributes.tiv = decimalPipe.transform(feature.graphic.attributes.tiv, '.0-0');
        feature.graphic.attributes.latitude = decimalPipe.transform(feature.graphic.attributes.latitude, '1.2-2');
        feature.graphic.attributes.longitude = decimalPipe.transform(feature.graphic.attributes.longitude, '1.2-2');
        feature.graphic.attributes.mpr = decimalPipe.transform(feature.graphic.attributes.mpr, '1.2-2');
        feature.graphic.attributes.tmpr = decimalPipe.transform(feature.graphic.attributes.tmpr, '1.2-2');
        feature.graphic.attributes.page1Header = this._translate.instant(feature.graphic.attributes.page1Header);
        feature.graphic.attributes.hazard_LIGHTNING_Label = this._translate.instant(feature.graphic.attributes.hazard_LIGHTNING_Label);
        feature.graphic.attributes.hazard_HAILSTORM_Label = this._translate.instant(feature.graphic.attributes.hazard_HAILSTORM_Label);
        feature.graphic.attributes.hazard_FLASHFLOOD_Label = this._translate.instant(feature.graphic.attributes.hazard_FLASHFLOOD_Label);
        feature.graphic.attributes.hazard_COASTALFLOOD_Label = this._translate.instant(feature.graphic.attributes.hazard_COASTALFLOOD_Label);
        feature.graphic.attributes.hazard_EARTHQUAKE_Label = this._translate.instant(feature.graphic.attributes.hazard_EARTHQUAKE_Label);
        feature.graphic.attributes.hazard_EXTRATROPICALSTORM_Label = this._translate.instant(feature.graphic.attributes.hazard_EXTRATROPICALSTORM_Label);
        feature.graphic.attributes.hazard_TROPICALCYCLONE_Label = this._translate.instant(feature.graphic.attributes.hazard_TROPICALCYCLONE_Label);
        feature.graphic.attributes.hazard_TSUNAMI_Label = this._translate.instant(feature.graphic.attributes.hazard_TSUNAMI_Label);
        feature.graphic.attributes.hazard_WILDFIRE_Label = this._translate.instant(feature.graphic.attributes.hazard_WILDFIRE_Label);
        feature.graphic.attributes.hazard_TORNADO_Label = this._translate.instant(feature.graphic.attributes.hazard_TORNADO_Label);
        feature.graphic.attributes.hazard_RIVERFLOOD_Label = this._translate.instant(feature.graphic.attributes.hazard_RIVERFLOOD_Label);
        feature.graphic.attributes.hazard_TERRORISM_Label = this._translate.instant(feature.graphic.attributes.hazard_TERRORISM_Label);
        feature.graphic.attributes.addressLabel = this._translate.instant(feature.graphic.attributes.addressLabel);
        feature.graphic.attributes.tivLabel = this._translate.instant(feature.graphic.attributes.tivLabel);
        feature.graphic.attributes.latitudeLabel = this._translate.instant(feature.graphic.attributes.latitudeLabel);
        feature.graphic.attributes.longitudeLabel = this._translate.instant(feature.graphic.attributes.longitudeLabel);
        feature.graphic.attributes.resolutionLabel = this._translate.instant(feature.graphic.attributes.resolutionLabel);
        feature.graphic.attributes.natcatScoreLabel = this._translate.instant(feature.graphic.attributes.natcatScoreLabel);
        feature.graphic.attributes.terrorismScoreLabel = this._translate.instant(feature.graphic.attributes.terrorismScoreLabel);
        popupDiv.innerHTML = MapDefinitions.GetGpdSingleLocationPopup(feature);
        return popupDiv;
    }

    getGpdClusterPopupInfo(feature: any) {

        const popupFooter = document.getElementsByClassName('esri-popup__footer')[0];
        if (popupFooter) {
            popupFooter.setAttribute('style', 'display: block !important');
        }

        let decimalPipe = new DecimalPipe(this._locale);
        feature.graphic.attributes.header = this._translate.instant('GPD.MAP_LABELS.CLUSTERPOPUP.HEADER');
        feature.graphic.attributes.countLabel = this._translate.instant('GPD.MAP_LABELS.CLUSTERPOPUP.COUNT_MESSAGE', { cluster_count: feature.graphic.attributes.cluster_count });
        feature.graphic.attributes.tivAverageLabel = this._translate.instant('GPD.MAP_LABELS.CLUSTERPOPUP.AVERAGE_TIV_MESSAGE',
            { cluster_avg_TIV: decimalPipe.transform(feature.graphic.attributes.cluster_avg_tiv, '.0-0') });
        let popupDiv = document.createElement('div');
        popupDiv.classList.add('pin-pop-up');
        popupDiv.innerHTML = MapDefinitions.GetGpdClusterPopup(feature);
        return popupDiv;
    }

    repositionSupplyChainMapWidgets(mapView: any, isOriginalPosition: boolean) {
        let homeWidget = mapView.ui.find(MapDefinitions.SupplyChainHomeWidgetId);
        let zoomWidget = mapView.ui.find(MapDefinitions.SupplyChainZoomWidgetId);
        let basemapWidget = mapView.ui.find(MapDefinitions.SupplyChainBasemapContainer);
        let clusterToggleButton = document.getElementById(MapDefinitions.SupplyChainClutserToggleButtonId);
        if (isOriginalPosition) {
            homeWidget.container.style.marginRight = '';
            clusterToggleButton.style.marginRight = '';
            homeWidget.container.style.animation = '';
            zoomWidget.container.style.animation = '';
            clusterToggleButton.style.animation = '';
            basemapWidget.container.style.animation = '';
        } else {
            let animation = MapDefinitions.SupplyChainWidgetsAnimation;
            homeWidget.container.style.marginRight = MapDefinitions.SupplyChainWidgetsMarginRight;
            clusterToggleButton.style.marginRight = MapDefinitions.SupplyChainWidgetsMarginRight;
            homeWidget.container.style.animation = animation;
            zoomWidget.container.style.animation = animation;
            clusterToggleButton.style.animation = animation;
            basemapWidget.container.style.animation = animation;
        }
    }

    setDynamicClusterSizing(clusterLayer, sizeVariable, renderer) {
        /* //Dynamically create max node size variables// */
        let fieldName = sizeVariable.field;
        let outStatisticAvgFieldName = `avg${fieldName}`;
        let averageValue = {
            onStatisticField: fieldName,
            outStatisticFieldName: outStatisticAvgFieldName,
            statisticType: 'avg'
        };
        let outStatisticSdFieldName = `sd${fieldName}`;
        let standardDeviationValue = {
            onStatisticField: fieldName,
            outStatisticFieldName: `sd${fieldName}`,
            statisticType: 'stddev'
        };
        let query = clusterLayer.createQuery();
        query.outStatistics = [averageValue, standardDeviationValue];
        clusterLayer.queryFeatures(query)
            .then(function(response) {
                let avgValue = response.features[0].attributes[outStatisticAvgFieldName];
                let sdValue = response.features[0].attributes[outStatisticSdFieldName];
                sizeVariable['minDataValue'] = avgValue + sdValue * 0.2;
                sizeVariable['maxDataValue'] = avgValue + sdValue;
                clusterLayer.renderer = renderer;
                renderer.visualVariables = [sizeVariable];
                clusterLayer.labelingInfo = [MapDefinitions.nodeLabelInfo, MapDefinitions.clusterLabelInfo];
            });
    }

    toggleTiv() {
        this._isTivToggled = true;
        this._isIntExtToggled = false;
        this._clusterNodes.visible = true;
        this._clusterNodes.listMode = 'show';
        this._clusterNodes2.visible = true;
        this._clusterNodes2.listMode = 'show';
        this._intExtNodes.visible = false;
        this._intExtNodes.listMode = 'hide';
        this._intExtNodes2.visible = false;
        this._intExtNodes2.listMode = 'hide';
    }

    toggleIntExt() {
        this._isTivToggled = false;
        this._isIntExtToggled = true;
        this._intExtNodes.visible = true;
        this._intExtNodes.listMode = 'show';
        this._intExtNodes2.visible = true;
        this._intExtNodes2.listMode = 'show';
        this._clusterNodes.visible = false;
        this._clusterNodes.listMode = 'hide';
        this._clusterNodes2.visible = false;
        this._clusterNodes2.listMode = 'hide';
    }

    toggleAllRelationshipLinks(): void {
        if (this._currentSelectedNode === undefined) {
            this._allRelationshipLayer.visible = !this._allRelationshipLayer.visible;
        } else {
            this._currentSelectedNode = undefined;
        }

        this.toggleRelationshipsLinksLayerProperties();
    }

    toggleTop5RelationshipLinks(): void {
        this._top5RelationshipLayer.visible = !this._top5RelationshipLayer.visible;
        this.toggleRelationshipsLinksLayerProperties();
    }

    get hasAnyRelationshipLink$(): Observable<boolean> {
        return this._hasAnyRelationshipLink.asObservable();
    }

    get isPopupRelationshipLinkActive$(): Observable<boolean> {
        return this._isPopupRelationshipLinkActive.asObservable();
    }

    toggleHideRelationshipLinks(): void {
        this._top5RelationshipLayer.visible = false;
        this._allRelationshipLayer.visible = false;
        this._currentSelectedNode = undefined;
    }

    toggleSupplyChainClusters() {
        if (this._isTivToggled) {
            if (this._clusterNodes.featureReduction === null) { //Cluster circle
                this._clusterNodes2.visible = true;
                this._clusterNodes2.listMode = 'show';
                this._clusterNodes.maxScale = 72223;
                this._clusterNodes.featureReduction = this._supplyChainClusterConfig;
                this._clusterNodes.popupTemplate = this._clusterNodeLayerTemplate;
                this._clusterNodes.labelingInfo = [MapDefinitions.clusterLabelInfo];
                this._clusterNodes.renderer = MapDefinitions.ClusterNodeRenderer;
                this._clusterNodes.title = this._translate.instant('GPD.MAP_LABELS.SUPPLYCHAIN.LEGEND.TIV');
                this._clusterNodes.renderer.visualVariables = [MapDefinitions.SupplyChainSizeVariable];
            } else { //Firefly
                this._clusterNodes2.visible = false;
                this._clusterNodes2.listMode = 'hide';
                this._clusterNodes.maxScale = 0;
                this._clusterNodes.featureReduction = null;
                this._clusterNodes.popupTemplate = this._intExtLayerTemplate;
                this._clusterNodes.renderer = MapDefinitions.fireflySymbol;
                this._clusterNodes.labelingInfo = undefined;
                this._clusterNodes.title = this._translate.instant('GPD.MAP_LABELS.SUPPLYCHAIN.LEGEND.TIV');
                this.setSizeAndOpacityOfFirefly(this._clusterNodes);
            }
        }
        if (this._isIntExtToggled) {
            if (this._intExtNodes.featureReduction === null) {
                this._intExtNodes2.visible = true;
                this._intExtNodes2.listMode = 'show';
                this._intExtNodes.maxScale = 72223;
                this._intExtNodes.featureReduction = this._supplyChainClusterConfig;
                this._intExtNodes.popupTemplate = this._clusterNodeLayerTemplate;
                this._intExtNodes.labelingInfo = [MapDefinitions.clusterLabelInfo];
                this._intExtNodes.renderer = MapDefinitions.IntExtRenderer;
            } else {
                this._intExtNodes2.visible = false;
                this._intExtNodes2.listMode = 'hide';
                this._intExtNodes.maxScale = 0;
                this._intExtNodes.featureReduction = null;
                this._intExtNodes.popupTemplate = this._intExtLayerTemplate;
                this._intExtNodes.renderer = MapDefinitions.fireflySymbolIntExt;
                this._intExtNodes.labelingInfo = undefined;
                this._intExtNodes.renderer.visualVariables = undefined;
            }
        }

    }

    setNextBasemapForToggle(mapView: any, previousBasemap: string) {
        //in case if nextBasemap and active basemap(if selected from basemap panel on map-options) both are same then set nextBasemap to previously selected basemap
        let basemapToggleWidget = mapView.ui.find(MapDefinitions.SupplyChainBasemapContainer);
        if (basemapToggleWidget.nextBasemap.id === basemapToggleWidget.activeBasemap.id)
            basemapToggleWidget.nextBasemap = previousBasemap;
    }

    private setSizeAndOpacityOfFirefly(layer): void {
        const queryStatistics = {
            onStatisticField: 'TIV',
            outStatisticFieldName: 'tivMax',
            statisticType: 'max'
        };

        //Query which adds stops and also sets minTIV value//
        const query = layer.createQuery();
        query.outStatistics = [queryStatistics];
        layer.queryFeatures(query)
            .then((response) => {
                let PctCont_25 = response.features[0].attributes.tivMax * 0.25;
                let PctCont_75 = response.features[0].attributes.tivMax * 0.75;

                MapDefinitions.TIVOpacityVar['stops'] = [
                    { value: PctCont_25, opacity: 0.75 }, { value: PctCont_75, opacity: 1 }
                ];
                MapDefinitions.ClusterOpacityVar['stops'] = [{ value: PctCont_25, opacity: 1 }, { value: PctCont_75, opacity: 1 }];
                MapDefinitions.locSizeVar['minDataValue'] = PctCont_25;
                MapDefinitions.locSizeVar['maxDataValue'] = PctCont_75;
                layer.renderer.visualVariables = [MapDefinitions.FiresizeVar, MapDefinitions.TIVOpacityVar];
            });
    }

    private toggleRelationshipsLinksLayerProperties(): void {
        const definitionExpression = `LineID NOT IN (${this._lineIds})`;
        const hasBothLayersVisible = (this._top5RelationshipLayer.visible && this._allRelationshipLayer.visible);
        this._allRelationshipLayer.definitionExpression = hasBothLayersVisible ? definitionExpression : undefined;
    }

    private calcGraphicsExtent(graphicsArray) {
        let g = graphicsArray[0].geometry,
            fullExt = g.extent,
            ext, i, il = graphicsArray.length;

        if (fullExt === null) {
            fullExt = new this._extent({ xmin: g.x, ymin: g.y, xmax: g.x, ymax: g.y, spatialReference: g.spatialReference });
        }
        for (i = 1; i < il; i++) {
            ext = (g = graphicsArray[i].geometry).extent;
            if (ext === null) {
                ext = new this._extent({ xmin: g.x, ymin: g.y, xmax: g.x, ymax: g.y, spatialReference: g.spatialReference });
            }
            fullExt = fullExt.union(ext);
        }
        return fullExt;
    }

    private addLogo(mapView: any) {
        const logo = this._domConstruct.create('img', {
            src: '../../../assets/munich-re.svg',
            id: 'logo',
        });
        mapView.root.appendChild(logo);

        const kineticLogo = this._domConstruct.create('img', {
            src: '../../../assets/kineticlogo.png',
            id: 'kineticLogo',
        });
        mapView.root.appendChild(kineticLogo);

        const johnhopkinsLogo = this._domConstruct.create('img', {
            src: '../../../assets/JohnHopkins.png',
            id: 'JohnhopkinsLogo',
        });
        mapView.root.appendChild(johnhopkinsLogo);
    }

    private addClusterToggleButton(mapView: any, isSupplyChainButton: boolean) {
        let disableClusterTitle = 'Disable clusters';
        let enableClusterTitle = 'Enable clusters';
        let enabledColor = 'rgba(214, 214, 214, 0.941)';
        let disabledColor = 'rgba(255, 255, 255, 0.6)';
        const clusterToggleButton = this._domConstruct.create('div', {
            role: 'button',
            class: 'esri-component esri-widget--button esri-widget',
            id: isSupplyChainButton ? MapDefinitions.SupplyChainClutserToggleButtonId : MapDefinitions.GpdClutserToggleButtonId,
            innerHTML: '<img id="toggle-cluster-icon" src="../../../assets/img/icons8-group-objects.svg">',
            title: disableClusterTitle
        });
        clusterToggleButton.addEventListener('click', (event) => {
            mapView.popup.visible = false;
            if (clusterToggleButton.style.backgroundColor === disabledColor) {
                clusterToggleButton.style.backgroundColor = enabledColor;
                clusterToggleButton.title = disableClusterTitle;
            } else {
                clusterToggleButton.style.backgroundColor = disabledColor;
                clusterToggleButton.title = enableClusterTitle;
            }
        });
        mapView.ui.add(clusterToggleButton, 'bottom-right');
    }

    private addSupplyChainBasemapToggleWidget(mapView: any) {
        let that = this;
        let basemapToggleWidget = new this._basemapToggle({
            view: mapView,
            nextBasemap: MapDefinitions.SupplyChainNextToggleBasemap,
            id: MapDefinitions.SupplyChainBasemapContainer,
            container: MapDefinitions.SupplyChainBasemapContainer
        });
        basemapToggleWidget.on('toggle', function(event) {
            that._esriBasemapToggled.next(event.current.id);
            that.reorderBasemapReferenceLayer(mapView);
        });
        mapView.ui.add(basemapToggleWidget, 'bottom-right');
    }

    private addLogoSupplyChain(mapUi: any) {
        const logo = this._domConstruct.create('img', {
            src: '../../../assets/munich-re.svg',
            id: 'munichreLogoSupplyChain',
        });
        mapUi.root.appendChild(logo);

        const kineticLogo = this._domConstruct.create('img', {
            src: '../../../assets/esri-logo.png',
            id: 'esriLogoSupplyChain',
        });
        mapUi.root.appendChild(kineticLogo);
    }

    private addGlobalPerilDiagnosticWidget(mapView: any, position: any) {
        this._homeWidget = new this._home({
            view: mapView,
            container: MapDefinitions.GpdHomeWidgetContainer,
            label: this._translate.instant('GPD.RESULTS.DEFAULTMAPVIEW'),
            id: MapDefinitions.GpdHomeWidgetId
        });

        mapView.ui.add(this._homeWidget, position);

        let zoomWidget = new this._zoom({
            view: mapView,
            container: MapDefinitions.GpdZoomWidgetContainer,
            id: MapDefinitions.GpdZoomWidgetId
        });

        mapView.ui.add(zoomWidget, position);
    }

    private addSupplyChainWidgets(mapView: any) {
        let homeWidget = new this._home({
            view: mapView,
            container: MapDefinitions.SupplyChainHomeWidgetContainer,
            label: this._translate.instant('GPD.RESULTS.DEFAULTMAPVIEW'),
            id: MapDefinitions.SupplyChainHomeWidgetId
        });

        mapView.ui.add(homeWidget, 'bottom-right');

        let zoomWidget = new this._zoom({
            view: mapView,
            container: MapDefinitions.SupplyChainZoomWidgetContainer,
            id: MapDefinitions.SupplyChainZoomWidgetId
        });

        mapView.ui.add(zoomWidget, 'bottom-right');

        let searchWidget = new this._search({
            view: mapView
        });
        searchWidget.watch('activeSource', function(evt) {
            evt.placeholder = '';
        });

        let expandWidget = new this._expand({
            expandIconClass: 'esri-icon-search',
            view: mapView,
            container: MapDefinitions.SupplyChainSearchWidgetContainer,
            content: searchWidget,
            expandTooltip: this._translate.instant('GPD.SUPPLYCHAIN.SEARCH.HOVERTEXT'),
            collapseTooltip: this._translate.instant('GPD.SUPPLYCHAIN.SEARCH.COLLAPSETEXT'),
        });

        mapView.ui.add({
            component: expandWidget,
            position: 'top-left'
        });
    }

    private addLayerLegendElement(mapUi: any) {
        const layerLegendContainer = this._domConstruct.create('div', {
            id: 'layerLegend'
        });
        mapUi.root.appendChild(layerLegendContainer);
    }

    private getBasemapName(map: any) {
        let basemapName = map.basemap.id;

        if (basemapName) {
            return basemapName;
        }
    }

    private movePoint(evt: any, mapView: any) {

        let pt = this._webMercatorUtils.webMercatorToGeographic(evt.mapPoint);

        //raise map click
        this._esriPinDropped.next(pt);
    }

    private setHomeViewPoint(extent: any) {
        let vp = new this._viewpoint({
            targetGeometry: extent
        });
        this._homeWidget.viewpoint = vp;
    }

    private addAliasToNodeLayerFields() {
        const fields = [...MapDefinitions.NodeLayerFields];
        fields.find(x => x.name === 'TIV')['alias'] = this._translate.instant('GPD.MAP_LABELS.SUPPLYCHAIN.LEGEND.TIV');
        fields.find(x => x.name === 'InEx')['alias'] = this._translate.instant('GPD.MAP_LABELS.SUPPLYCHAIN.LEGEND.INEX');
        return fields;
    }

    private getCovidPopupInfo(feature: any) {

        const popupFooter = document.getElementsByClassName('esri-popup__footer')[0];
        popupFooter.setAttribute('style', 'display: block !important');
        let popupDiv = document.createElement('div');
        popupDiv.classList.add('pin-pop-up');
        const decimalPipe = new DecimalPipe(this._locale);

        const labels: { location: string, activeCases: string, incidentRate: string } = {
            location: this._translate.instant('GPD.MAP_LABELS.COVIDPOPUP.LOCATION'),
            activeCases: this._translate.instant('GPD.MAP_LABELS.COVIDPOPUP.ACTIVECASES'),
            incidentRate: this._translate.instant('GPD.MAP_LABELS.COVIDPOPUP.INCIDENTRATE')
        };

        if (feature.graphic.layer.id === LiveEventLevel.Country) {

            popupDiv.innerHTML = MapDefinitions.Covid19PopupHtml(labels,
                [feature.graphic.attributes.NAME],
                decimalPipe.transform(feature.graphic.attributes.actCases, '.0-0'),
                decimalPipe.transform(feature.graphic.attributes.Case100K, '.0-2'));
        } else if (feature.graphic.layer.id === LiveEventLevel.County) {
            popupDiv.innerHTML = MapDefinitions.Covid19PopupHtml(labels,
                [feature.graphic.attributes.Cty_NAME, feature.graphic.attributes.ST_ABBREV, this._translate.instant('GPD.MAP_LABELS.COVIDPOPUP.UNITEDSTATES')],
                decimalPipe.transform(feature.graphic.attributes.actCases, '.0-0'),
                decimalPipe.transform(feature.graphic.attributes.Case100K, '.0-2'));
        }

        return popupDiv;
    }

    private createPolygonSymbol(color: number[]): any {
        return {
            type: 'simple-fill',
            color: color,
            outline: {
                color: 'lightgray',
                width: 0.5
            }
        };
    }

    private createCBreaksRenderer(field: string, ranges: number[]): any {
        //Create the class BreaksRenderer - Polygon Renderer
        const symbolList = [
            this.createPolygonSymbol([50, 136, 189, 0.5]),
            this.createPolygonSymbol([50, 136, 189, 1]),
            this.createPolygonSymbol([102, 194, 165, 1]),
            this.createPolygonSymbol([171, 221, 164, 1]),
            this.createPolygonSymbol([230, 245, 152, 1]),
            this.createPolygonSymbol([254, 224, 139, 1]),
            this.createPolygonSymbol([253, 174, 97, 1]),
            this.createPolygonSymbol([244, 109, 67, 1]),
            this.createPolygonSymbol([213, 62, 79, 1])
        ];

        return {
            type: 'class-breaks',
            field: field,
            classBreakInfos: ranges.map((value, index, array) => {
                return { minValue: value, maxValue: index === array.length - 1 ? 'Infinity' : array[index + 1], symbol: symbolList[index] };
            })
        };
    }
}
