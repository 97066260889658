
/* eslint-disable */
import * as Dto from '../dtos';
import { HttpResponse } from '@angular/common/http';
import { ProxyHttp, ApiResult } from '@wtw/platform/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export { ApiResult };

@Injectable()
export class RunsProxy {
    constructor(private _phttp: ProxyHttp) { }


    update(updateRun: Dto.UpdateRun): Observable<ApiResult<Dto.RunViewModel>> {
        return this._phttp.post(false, `/runs/update`, updateRun || {}).pipe(map((ret: HttpResponse<Dto.RunViewModel>) => <ApiResult<Dto.RunViewModel>>({ data: ret.body, originalResponse: ret })));
    }
}


